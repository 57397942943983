import styled from "@emotion/styled";
import appConfig from "../../../config/config";
import { ISelectProps, Positioning } from "./Select";

const OPTION_HEIGHT = 38;

export const Wrapper = styled.div(({ width, height }: Partial<ISelectProps>) => ({
    label: "SelectWrapper",
    width: width || "100%",
    minWidth: "10rem",
    // marginBottom: "2rem",
    minHeight: height || "2.7641rem",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    ".label": {
        marginBottom: 0,
        width: "100%",
        fontSize: "1.3rem",
        "@media (-webkit-device-pixel-ratio: 1.5)": {
            fontSize: "1.4rem",
        },
        ".text": {
            // marginLeft: "0.2rem",
            marginBottom: "0.4rem",
            display: "flex",
            justifyContent: "space-between",
            color: appConfig.style.colors.text2,
            // fontFamily: appConfig.style.fonts.assistant.regular, // Daniel - temp comment until all inputs will be replaced
            alignItems: "flex-end",
            width: "100%",
            span: {
                flex: 1,
            },
        },
    },
    "&.flat": {
        marginBottom: 0,
    },
}));

const Select = styled.div(
    {
        label: "Select",
        outline: 0,
        display: "flex",
        fontSize: "1.4rem",
        alignItems: "center",
        flexWrap: "wrap",
        position: "relative",
        transition: "all .1s",
        paddingLeft: "1.2rem",
        paddingRight: "1.2rem",
        border: `1px solid ${appConfig.style.colors.field}`,
        borderRadius: appConfig.style.borderRadius.medium,
        flex: 1,
        height: "3.6rem",
        "*": {
            userSelect: "none",
        },
        ".wrapper": {
            display: "table",
            // justifyContent: "space-between",
            width: "100%",
            maxHeight: "100%",
            // alignItems: "center",
            gap: 4,
            "&:before": {
                display: "table-column",
                width: "100%",
                content: "''",
            },
            ".value": {
                maxWidth: 1,
                display: "table-cell",
                userSelect: "none",
            },
            ".arrow": {
                border: "solid black",
                borderWidth: "0 0.2rem 0.2rem 0",
                borderColor: appConfig.style.colors.text1,
                display: "inline-block",
                padding: "0.2rem",
                height: "0.2rem",
                whiteSpace: "nowrap",
            },
            ".down": {
                transform: "rotate(45deg)",
                marginBottom: "0.3rem",
            },
            ".up": {
                transform: "rotate(-135deg)",
            },
        },
        "&.flat": {
            outline: "none",
            padding: 0,
            margin: 0,
            border: "none",
            width: "100%",
        },
    },
    ({ isSelected, disabled, isStandalone }: { isOpen?: boolean; disabled?: boolean; isStandalone?: boolean; isSelected?: boolean }) => ({
        "&:hover": {
            // borderColor: isOpen ? appConfig.style.colors.color1 : appConfig.style.colors.gray2,
        },
        "&:focus": disabled
            ? {}
            : {
                  borderColor: appConfig.style.colors.color1,
              },
        color: isSelected ? "inherit" : "#c0c0c0",
        background: disabled ? "#fafafa" : "#ffffff",
        cursor: disabled ? "not-allowed" : "pointer",
        userSelect: disabled ? "none" : "inherit",
        position: isStandalone ? "unset" : "relative",
    })
);

export const Option = styled.div(({ isColumn, isAutocomplete }: { isColumn?: boolean; isAutocomplete?: boolean }) => ({
	label: 'SelectOption',
	width: '100%',
	fontSize: '1.3rem',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: isColumn ? 'start' : 'center',
	padding: '1rem',
	cursor: 'pointer',
	transition: 'all .15s',
	color: appConfig.style.colors.text1,
	userSelect: 'none',
	minHeight: isAutocomplete ? 'unset' : `${OPTION_HEIGHT / 10}rem`,
	flexDirection: isColumn ? 'column' : 'row',
	borderRadius: appConfig.style.borderRadius.medium,
	// "fontFamily": appConfig.style.fonts.base.regular,
	'@media (-webkit-device-pixel-ratio: 1.5)': {
		fontSize: '1.4rem',
	},
	'div:not(.text-line-0)': {
		fontSize: '1.2rem',
		color: appConfig.style.colors.text2,
	},
	'> div': {
		width: '100%',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	// "&:not(:last-child)": {
	// 	borderBottom: `1px solid ${appConfig.style.colors.background1}`,
	// },
	'.option-checkmark': {
		transition: 'all .2s',
		opacity: 0,
		'&.show': {
			opacity: 1,
		},
	},
	'&:not(.disabled):hover, &:focus, &.focus': {
		// "fontFamily": isAutocomplete
		// 	? appConfig.style.fonts.base.regular
		// "background": appConfig.style.colors.background1,
		// "color": appConfig.style.colors.color1,

		background: appConfig.style.colors.background1,
		outline: 'none',
		'div:not(.text-line-0)': {
			color: appConfig.style.colors.color1,
		},
	},
	'&.selected': {
		// background: appConfig.style.colors.background1,
		'.option-checkmark': {
			opacity: 1,
		},
		// color: appConfig.style.colors.color1,
	},
	'&.disabled': {
		color: appConfig.style.colors.gray2,
	},
}));

export const OptionsContainer = styled.div(
    {
        label: "OptionsContainer",
        overflow: "auto",
        boxShadow: appConfig.style.boxShadow.card1,
        background: "white",
        top: "calc(100% + 1rem)",
        left: 0,
        width: "100%",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${appConfig.style.colors.background1}`,
        zIndex: 1300,
        borderRadius: appConfig.style.borderRadius.medium,
        transition: "all .2s",
        padding: "0.8rem",
    },
    ({ scrollAfter = 4, positioning = {} }: { scrollAfter?: number; positioning?: Positioning }) => ({
        maxHeight: `${(scrollAfter * OPTION_HEIGHT + 18) / 10}rem`,
        ...(positioning.top
            ? {
                  top: "unset",
                  bottom: "calc(100% + 1rem)",
              }
            : {}),
    })
);

const StyledSelect = { Select, Wrapper, Option, OptionsContainer };

export default StyledSelect;
