import { css } from '@emotion/css';
import appConfig from '../../../../../../config/config';
import { breakpoints } from '../../../../../../config/style/theme';

const VolatilityStyle = css({
	label: 'Volatility',
	display: 'flex',
	flexDirection: 'column',
	'&__container': {
		'&:not(:last-child)': {
			marginBottom: '1.2rem',
		},
	},
	'&__filters': {
		marginTop: '3rem',
		display: 'flex',
		gap: '20rem',
		transition: 'all .3s',
		[`@media screen and (max-width: ${breakpoints.sm})`]: {
			gap: '6rem',
		},
		'.selectable': {
			flex: '0 0 30rem',
			display: 'flex',
			flexDirection: 'column',
			gap: '2rem',
		},
		'.keywords': {
			flex: 1,
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			transition: 'all .3s',
			'&::before': {
				content: '""',
				width: 1,
				position: 'absolute',
				top: 0,
				left: '-10rem',
				[`@media screen and (max-width: ${breakpoints.sm})`]: {
					left: '-3rem',
				},
				height: '100%',
				background: appConfig.style.colors.table,
			},
			button: {
				marginTop: '1.6rem',
			},
			textarea: {
				height: '11.5rem',
			},
		},
	},
	'&__peers': {
		padding: '2.2rem 0 3rem',
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		display: 'flex',
		flexDirection: 'column',
		'.inputs': {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '1.2rem',
			'.search-input': {
				flex: '0 0 30rem',
			},
			'.btn-show-list, .btn-ask-altshare': {
				cursor: 'pointer',
				userSelect: 'none',
				display: 'flex',
				alignItems: 'center',
				gap: '1.2rem',
				height: '3.6rem',
				alignSelf: 'flex-end',
			},
			'.btn-ask-altshare': {
				color: appConfig.style.colors.color1,
			},
		},
		'.list-card': {
			width: '100%',
			marginTop: '2rem',
			borderRadius: 8,
			background: appConfig.style.colors.lightBg,
			border: `1px solid ${appConfig.style.colors.field}`,
			padding: '1.6rem',
			'.list-title': {
				marginBottom: '1.6rem',
				color: appConfig.style.colors.text2,
			},
			'.list-container': {
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				gridGap: '1.2rem',
				'> div': {
					width: 'fit-content',
				},
			},
		},
	},
});

export default VolatilityStyle;
