import appConfig from '../../../config/config';
import Button from '../../../Shared/Components/Button/Button';
import ProgressBar from '../../../Shared/Components/ProgressBar';
import useMultiStepForm, { MultiStepFormItem } from '../../../Shared/Hooks/useMultiStepForm';
import { ActionsMenuStyle } from '../../Waterfall/Components/WaterfallHeader/WaterfallHeader.style';
import AddDocuments from './Steps/AddDocuments';
import GeneralInfo from './Steps/GeneralInfo';
import ImportCapTable from './Steps/ImportCapTable';

const AddEditDataCollection = () => {
	const { step, onNextStep, onPrevStep, goTo, currentStepIdx, steps, isLastStep, isFirstStep } = useMultiStepForm([
		{
			element: <GeneralInfo />,
			caption: 'General information',
		},
		{
			element: <ImportCapTable />,
			caption: 'Import cap table',
		},
		{
			element: <AddDocuments />,
			caption: 'Add documents',
		},
	]);

	const onStepClickHandler = (idx: number) => {
		goTo(idx);
	};

	return (
		<div>
			<ProgressBar
				pageCaptions={steps.map((step: MultiStepFormItem) => step.caption || '')}
				onStepClickHandler={onStepClickHandler}
				currentStepIdx={currentStepIdx}
				title={
					<>
						<span style={{ color: appConfig.style.colors.text2 }}>Analysis name:</span> Valuation name: N/A
					</>
				}
				lastCompletedIdx={-1}
				actions={
					<div className={ActionsMenuStyle}>
						{/* <Button qaid="ProgressBar.Button.Save" onClick={onSaveHandler} inverse label="Save as" className="button--waterfall" /> */}
						<Button
							qaid="ProgressBar.Button.Quit"
							onClick={() => console.log('onSaveHandler')}
							label="Save & Quit"
							cancel
							className="button--waterfall"
						/>
					</div>
				}
				isLastStepHighlighed
			/>
			{step}
		</div>
	);
};

export default AddEditDataCollection;
