import useModal from '../../../../../Shared/Hooks/useModal';
import CapTableExcelImport from '../../../../CapTable/Components/Import';

const ImportCapTable = () => {
	const { showModal } = useModal();

	const onShowImportCapTable = () => {
		showModal({
			body: <CapTableExcelImport onSubmit={(projectId) => console.log(projectId)} />,
			maximize: true,
		});
	};

	return (
		<div>
			<span>ImportCapTable</span>
			<div onClick={onShowImportCapTable}>Test</div>
		</div>
	);
};

export default ImportCapTable;
