const color1 = "#8b96e9";
const color4 = "#F2AB64";
const color1Secondary = "#bdc3f1";
const boxShadowColor1 = "#c9cad033";
const boxShadowColor2 = "#6565bc33";
const lightGray = "#d9d9d980";

// rgba 201, 202, 208, 0.4 => #c9cad066

const appConfig = {
	style: {
		colors: {
			background1: '#E8EAF5',
			background2: '#4D4D4D',
			background2Opacity: '#4D4D4D46',
			background2Opacity70: '#4D4D4DB3',
			background3: '#F3F4F8',
			border1: '#ACB2CC',
			field: '#BAC0D9',
			lightBg: '#f9f9ff',
			boxShadowColor1,
			boxShadowColor2,
			color1,
			color1Secondary,
			color2: '#F3D569',
			color3: '#f76173',
			color4,
			orange: '#fc9f30',
			danger: '#F35669',
			success: '#68ce61',
			darkBlue: '#1a1a61',
			gradientPurpleHorizontal: `linear-gradient(0deg, #bcc2f1 0%, #a4aced 25%, ${color1} 100%);`,
			gradientPurpleVertical: `linear-gradient(90deg, #bcc2f1 0%, ${color1} 100%);`,
			gradientOrange: `linear-gradient(180deg, ${color4} 0%, #FCD0A5 100%)`,
			gray: '#ececec',
			gray2: '#dee0e7',
			purple: '#F0F1FF',
			text1: '#4D4D4D',
			text2: '#979DB5',
			text3: '#ACB2CC',
			text4: '#828282',
			lightGray,
			table: '#D0D4E4',
			total: '#EEEFFF',
			cancel: '#B7B5B5',
		},
		borderRadius: {
			low: '0.4rem',
			medium: '0.8rem',
			high: '1.2rem',
		},
		padding: {
			low: '0.4rem',
			medium: '0.8rem',
			high: '1.2rem',
			ultra: '1.6rem',
		},
		boxShadow: {
			card1: `0px 4px 8px ${boxShadowColor1}`,
			card2: `0px 4px 4px ${boxShadowColor1}`,
			card3: `0px 4px 8px ${boxShadowColor2}`,
			card4: `0px 4px 4px ${boxShadowColor2}`,
			light: `1px 1px 1px 1px ${lightGray}`,
			dark: '3px 3px 17px 0px #82828266',
			right: `7px 0px 7px -4px ${boxShadowColor1}`,
			left: `0px 0px 7px 2px ${boxShadowColor1}`,
		},
		fonts: {
			assistant: 'Assistant',
			alt: 'allroundgothic',
			unica: 'NeueHaasUnicaW1G',
			textPro: 'NeueHassTextPro',
			fasthand: 'Fasthand',
		},
	},
};

export default appConfig;
