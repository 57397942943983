import { useState } from "react";
import { ModalBodyProps } from "../../../../Shared/Components/Modal/types";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { getMimeType } from "../../../../Shared/Utilities";
import { renderInfo } from "../../helpers/utils";
import { InfoType } from "../Preview";
import SignDocumentStyle from "./index.style";
import TextInput from "../../../../Shared/Components/Input/TextInput";
import CheckBox from "../../../../Shared/Components/CheckBox/CheckBox";
import Button from "../../../../Shared/Components/Button/Button";
import DocumentPreviewInfo from "../Preview/DocumentPreviewInfo";
import CloseIcon from "@mui/icons-material/Close";
import Image from "../../../../Shared/Components/Image";
import { IC_MARK_RIGHT } from "../../../../Assets";
import classNames from "classnames";

export type SignDocumentProps = ModalBodyProps & {
	file: string | undefined;
	info: InfoType[] | undefined;
	docIds: string[];
	onSuccess?: () => void;
};

const SignDocument = ({ file, info, removeModal, docIds, onSuccess }: SignDocumentProps) => {
	const {
		auth: { currentUserInfo },
		documentsStore,
	} = useRootStore();
	const [inputTxt, setInputTxt] = useState<string>();
	const [isAgree, setIsAgree] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSignHandler = async () => {
		if (!isAgree || inputTxt !== fullName) return;
		setIsLoading(true);
		const res = await documentsStore.signDocuments(docIds, fullName);
		setIsLoading(false);

		if (res.isSuccess) {
			await documentsStore.getDocuments();
			onSuccess?.();
			removeModal?.();
		}
	};

	const fullName = `${currentUserInfo.firstName} ${currentUserInfo.lastName}`
		.split(' ')
		.map((t) => `${t.charAt(0).toUpperCase()}${t.slice(1)}`)
		.join(' ');

	return (
		<div className={SignDocumentStyle}>
			<div className={`${SignDocumentStyle}__close-btn`} onClick={() => !isLoading && removeModal?.()}>
				<CloseIcon />
			</div>
			<div className={`${SignDocumentStyle}__preview-data`}>
				{!!file && <embed src={`data:${getMimeType('pdf')};base64,${file}#toolbar=0&navpanes=0&view=FitH`} />}
				<div className={classNames(`${SignDocumentStyle}__info`, { multiple: docIds.length > 1 })}>
					{!!info && <DocumentPreviewInfo info={info} isPreview={false} isTooltip={false} />}
					<div className={classNames(`${SignDocumentStyle}__signature`, { multiple: docIds.length > 1 })}>
						<div className="signature-mark">
							<Image src={IC_MARK_RIGHT} height="100%" />
							<span>Sign here</span>
						</div>
						<TextInput
							disabled={isLoading}
							className="signature-input"
							qaid="SignDocument.Input.Name"
							placeholder={fullName}
							value={inputTxt}
							onChange={(val) => setInputTxt(val)}
						/>
						<span className="ex-text">Sign as "{fullName}"</span>
						<CheckBox
							disabled={isLoading}
							className="tos"
							qaid="SignDocument.CheckBox.Tos"
							label="I agree to electronically sign this document"
							isChecked={isAgree}
							onClick={(val) => setIsAgree(val)}
						/>
						<Button
							qaid="SignDocument.Button.Sign"
							label="Sign"
							disabled={!isAgree || inputTxt !== fullName}
							onClick={onSignHandler}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignDocument;
