import { css } from "@emotion/css";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useMemo } from 'react';
import {
    IC_CHECK_PURPLE,
    IC_DOC_WORD,
    IC_DOWNLOAD_ACTION,
    IC_EDIT_ACTION,
    IC_EYE_ACTION,
    IC_INACTIVE,
    IC_TRASH_ACTION,
} from "../../../Assets";
import { DocumentTemplateInfo } from "../../../Models/API/Document/template-info";
import { templateTypeOptions } from "../../../Models/API/Document/template-type-enum";
import { DocumentTemplatePayload } from "../../../Models/App/Document/template-payload";
import AddButton from "../../../Shared/Components/Button/AddButton";
import Clickable from "../../../Shared/Components/Clickable/Clickable";
import Image from "../../../Shared/Components/Image";
import Spinner from "../../../Shared/Components/Spinner/Spinner";
import Table, { TableColumn } from "../../../Shared/Components/Table/Table";
import useModal from "../../../Shared/Hooks/useModal";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { formatDate, getMimeType, isNumber, isString } from "../../../Shared/Utilities";
import appConfig from "../../../config/config";
import Tabs from "../Components/Tabs";
import useTemplate from "../helpers/hooks/useTemplate";
import AddEditTemplate from "./AddEditTemplate";
import { toJS } from 'mobx';

const TableStye = css({
	'&__actions': {
		marginBottom: '2rem',
	},
	"div[data-cell-type='header']:not(:last-child)": {
		borderRight: `1px solid ${appConfig.style.colors.table}`,
	},
	'&__helpDiv': {
		display: 'flex',
	},
	'&__link': {
		color: appConfig.style.colors.color1Secondary,
	},
});

const Templates = () => {
	const { documentsStore, contactStore } = useRootStore();
	const { showModal } = useModal();
	const { getTemplate, loading, previewTemplate } = useTemplate();

	const onDownloadHandler = async (e: SyntheticEvent, templateId: string, fileName: string) => {
		e.stopPropagation();
		const res = await getTemplate(templateId, false);
		const link = document.createElement('a');
		if (!res.data?.data.fileBase64) return;

		link.href = `data:${getMimeType('docx')};base64,${res.data?.data.fileBase64}`;
		link.download = `${fileName}.docx`;
		link.click();
	};

	const openTemplateModal = (template?: DocumentTemplatePayload, fileName?: string) => {
		// Daniel TODO: remove it after api update:
		if (template) {
			template = {
				...template,
				signers: template.signers.map((s) => ({
					contactId: s?.contactId ?? null,
					isDesignatedCompanySignatory: s.isDesignatedCompanySignatory,
					positionForSignature: s.positionForSignature,
				})),
			};
		}
		showModal({
			body: <AddEditTemplate data={template} fileName={fileName} onSuccess={() => documentsStore.getTemplates()} />,
			width: '100%',
			maxWidth: '75rem',
			isFrameless: true,
		});
	};

	const getTypeName = (value: number) => {
		const option = templateTypeOptions.find((opt) => opt.value === value);
		if (isString(option?.label)) return option.label;
		return '-';
	};

	const tableColumns: TableColumn<DocumentTemplateInfo>[] = [
		{
			name: 'templateName',
			label: 'Template name',
			sortable: true,
			style: { flex: 3.14 },
			render(obj, value) {
				return (
					<>
						<Image width="4rem" src={IC_DOC_WORD} style={{ marginRight: '0.8rem' }} />
						{value}
					</>
				);
			},
		},
		{
			name: 'documentType',
			label: 'Type',
			format: getTypeName,
			sortable: true,
			style: { flex: 1.163 },
		},
		{
			name: 'isUsed', // Daniel: to fix
			label: 'Usage',
			sortable: true,
			render(obj, value) {
				return value ? (
					<>
						<Image width="1.5rem" src={IC_CHECK_PURPLE} /> In use
					</>
				) : (
					<>
						<Image width="1.5rem" src={IC_INACTIVE} /> Not in use
					</>
				);
			},
			style: { flex: 1.163 },
		},
		{
			name: 'lastTimeEdited', // Daniel: to fix
			label: 'Last update',
			sortable: true,
			format: (value) => formatDate(value),
			style: { flex: 1.081 },
		},
		{
			name: 'lastUpdateByContactId', // Daniel: to fix
			label: 'Updated By',
			sortable: true,
			format: (value) => {
				return isNumber(value) ? contactStore.getContactFullNameById(value) : undefined;
			},
			style: { flex: 1.721 },
		},
		{
			name: 'actions',
			label: 'Actions',
			style: { flex: '0 0 20rem', justifyContent: 'space-between' },
			render: (obj, value) => {
				return (
					<>
						<Clickable qaid="" onClick={(e) => onDownloadHandler(e, obj.templateId, obj.templateName)} flex={0}>
							<Image width="2.3rem" src={IC_DOWNLOAD_ACTION} tooltip="Download" />
						</Clickable>
						<Clickable
							qaid=""
							onClick={() => {
								previewTemplate(obj.templateId, obj.templateName);
							}}
							flex={0}
						>
							<Image width="2.5rem" src={IC_EYE_ACTION} tooltip="Preview" />
						</Clickable>
						<Clickable
							qaid=""
							onClick={async () => {
								const res = await getTemplate(obj.templateId, false);
								if (!res.data?.data) return;

								openTemplateModal(
									{
										templateId: obj.templateId,
										templateName: obj.templateName,
										documentType: obj.documentType,
										fileBase64: res.data.data.fileBase64,
										signers: obj.signers,
										signerType: obj.signerType,
										signinRule: obj.signinRule,
										contactId: 0,
									},
									obj.templateName
								);
							}}
							flex={0}
						>
							<Image width="2rem" src={IC_EDIT_ACTION} tooltip="Edit" />
						</Clickable>
						<Clickable
							qaid=""
							onClick={() => {
								showModal({
									type: 'confirm',
									body: <>Are you sure that you want to delete the template "{obj.templateName}"?</>,
									confirmButton: {
										label: 'general.delete',
									},
									title: 'general.warning',
									onConfirm: () => documentsStore.deleteTemplate(obj.templateId),
									isMust: true,
								});
							}}
							flex={0}
						>
							<Image width="2rem" src={IC_TRASH_ACTION} tooltip="Delete" />
						</Clickable>
					</>
				);
			},
		},
	];

	if (!documentsStore.templates) {
		return <Spinner incorporated center />;
	}
	return (
		<>
			{loading && <Spinner />}
			<Table
				columns={tableColumns}
				rows={documentsStore.availableTemplates}
				rowsPerPage={5}
				className={TableStye}
				searchBy={['templateName', 'documentType']}
				filterBy={['templateName', 'documentType']}
				border={{ bottom: true }}
				rowSize={2}
				headerSize={1.5}
				customHeaderRender={<Tabs />}
				renderBottom={
					<div className={`${TableStye}__helpDiv`}>
						<AddButton qaid="Templates.Button.AddTemplate" label="Add new template" onClick={openTemplateModal} />
						<Clickable margin={'0 1rem'} onClick={() => {}} qaid="helpTemplate.Link" className={`${TableStye}__link`}>
							Need help?
						</Clickable>
					</div>
				}
			/>
		</>
	);
};

export default observer(Templates);
