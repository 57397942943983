import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CapTablePermission } from '../../../../Models/API/UsersAndPermissions/permissions-enum';
import { WaterfallProject } from '../../../../Models/API/Waterfall/IForm';
import { Routes } from '../../../../Routes';
import Flex from '../../Layout/Flex';
import CustomSelect from '../../Select/CustomSelect';
import useModal from '../../../Hooks/useModal';
import useRootStore from '../../../Hooks/useRootStore';
import VersionsDropDown from './VersionsDropDown';
import MainMenuStyle from './index.style';
import appConfig from '../../../../config/config';

interface Props {
	onOpen: (waterfall: WaterfallProject, step?: number) => void;
	onDelete: (waterfallId: number) => Promise<void>;
	projects?: WaterfallProject[] | null;
}

const MainMenu = ({ onOpen, onDelete, projects }: Props) => {
	const {
		auth: { permissions },
	} = useRootStore();
	const history = useHistory();
	const { showModal } = useModal();
	const [showVersionsDialog, setShowVersionsDialog] = useState<boolean>(false);

	// const onOpen = async (waterfall: WaterfallProject, step?: number) => {
	// 	if (appState.isLoading2) return;
	// 	appState.isLoading2 = true;
	// 	try {
	// 		waterfallStore.setLastAllowedStep(waterfall.maxStep);
	// 		const path = `${Routes.waterfall.index}/${waterfall.waterfallId}${
	// 			step ? `/${step}` : waterfallStore.lastAllowedStep ? `/${waterfallStore.lastAllowedStep}` : ''
	// 		}`;
	// 		waterfallStore.setProjectName(waterfall.projectName);
	// 		waterfallStore.isNewProject = false;
	// 		// to check if owner
	// 		waterfallStore.setProjectViewMode(!waterfall.isOwner);
	// 		setShowVersionsDialog(false);
	// 		history.push(path);
	// 	} catch (error: any) {
	// 		console.log(error);
	// 	}
	// 	appState.isLoading2 = false;
	// };

	const onDeleteHandler = async (waterfallId: number, name: string) => {
		showModal({
			type: 'confirm',
			body: <div>Are you sure that you want to delete the project "{name}"?</div>,
			confirmButton: {
				label: 'general.delete',
			},
			title: 'general.warning',
			onConfirm: () => onDelete(waterfallId),
		});
	};

	// 	<Clickable
	// 	className="open"
	// 	onClick={(e) => {
	// 		e.stopPropagation();
	// 		onOpen(project);
	// 	}}
	// 	qaid={`Waterfall.Link.Open-${project.waterfallId}`}
	// >
	// 	<img src={project.isOwner ? IC_EDIT2 : IC_EYE_PURPLE} alt="" />
	// </Clickable>
	return (
		<>
			<div className={classNames(`${MainMenuStyle}__backdrop`, { show: showVersionsDialog })}></div>
			<div className={`${MainMenuStyle}__menu`}>
				<Flex className={`${MainMenuStyle}__menu-side-a`} align="center" justify="start" gap="2.6rem">
					<CustomSelect
						qaid="CapTable.Select.Version"
						onClick={() => setShowVersionsDialog((state) => !state)}
						className={`${MainMenuStyle}__version-select`}
						isOpen={showVersionsDialog}
					>
						<Flex flex={0} align="center" justify="start" gap="0.4rem">
							<div style={{ color: appConfig.style.colors.text2 }}>Select analysis project</div>
							{/* <span className="draft">(draft)</span> */}
						</Flex>
					</CustomSelect>

					{showVersionsDialog && (
						<VersionsDropDown
							projects={projects}
							onHide={() => setShowVersionsDialog(false)}
							onDelete={onDeleteHandler}
							onLoad={onOpen}
							isViewMode={permissions?.capTablePermission === CapTablePermission.full_access}
						/>
					)}
				</Flex>
			</div>
		</>
	);
};

export default observer(MainMenu);
