import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { rootStore } from '../../../..';
import { IC_DUPLICATE2, IC_EXCEL_EXPORT } from '../../../../Assets';
import { Routes } from '../../../../Routes';
import ActionsMenu from '../../../../Shared/Components/ActionsMenu/ActionsMenu';
import Button from '../../../../Shared/Components/Button/Button';
import TextInput from '../../../../Shared/Components/Input/TextInput';
import Flex from '../../../../Shared/Components/Layout/Flex';
import Text from '../../../../Shared/Components/Layout/Text';
import useModal from '../../../../Shared/Hooks/useModal';
import useMultiStepForm, { MultiStepFormItem, ForwardedRef } from '../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { isNullOrUndefined } from '../../../../Shared/Utilities';
import { WaterfallFormSteps } from '../../../../Store/WaterfallStore';
import ProgressBar from '../../../../Shared/Components/ProgressBar';
import appConfig from '../../../../config/config';
import { MenuActionType } from '../../../../Models/App/action-menu-type';
import AddEditWaterfallStyle, { WaterfallFooter } from '../../../Waterfall/Components/AddEditWaterfall/index.style';
import { ActionsMenuStyle } from '../../../Waterfall/Components/WaterfallHeader/WaterfallHeader.style';
import { ValuationFormSteps } from '../../../../Store/ValuationStore';
import Summary from './Steps/Summary';
import Volatility from './Steps/Volatility';
import GeneralInfo from './Steps/GeneralInfo';
import CapTable from '../../../../Shared/Components/Waterfall/Steps/CapTable';
import EquityPlansImport from '../../../../Shared/Components/Waterfall/Steps/EquityPlansImport';
import LiquidationPreferences from '../../../../Shared/Components/Waterfall/Steps/LiquidationPreferences';
import PreferencesTerms from '../../../../Shared/Components/Waterfall/Steps/PreferencesTerms';

export type RouteParams = {
	step: string;
	waterfallId: string;
};

const AddEditWaterfall = () => {
	const params = useParams<RouteParams>();
	const {
		valuationStore,
		companyStore: { companyId },
	} = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentStepValid, setCurrentStepValid] = useState<boolean>(false);
	const [isActionsMenuOpen, setIsActionsMenuOpen] = useState<boolean>(false);
	const { showModal } = useModal();
	const history = useHistory();
	const ref = createRef<ForwardedRef>();
	const nextRef = createRef<HTMLDivElement | null>();
	const { t } = useTranslation();

	const onSaveHandler = () => {
		showModal({
			body: <SaveAsModal goTo={goTo} />,
			width: '55%',
		});
	};

	const onSubmit = async (e?: any, isStepClick?: boolean, step?: WaterfallFormSteps) => {
		e?.preventDefault();
		if (isLoading) return;

		const isValid = valuationStore.isViewMode || (await isCurrenetStepValid());

		if (!isValid) return;

		if (+params.step === valuationStore.lastAllowedStep) {
			return;
		}

		if (currentStepIdx === WaterfallFormSteps.capTable && !valuationStore.isOptionsAllowed && !isStepClick) {
			return goTo(Math.min(valuationStore.lastAllowedStep, WaterfallFormSteps.shareClasses));
		}

		if (!isLastStep) {
			return step && isStepAllowed(step) ? goTo(step) : onNextStep(); // If not last step, continue to next step
		}

		// Otherwise submit
		// alert("Saved!");
		history.push(Routes.valuation.index);
		setIsLoading(false);
	};

	const isCurrenetStepValid = async (): Promise<boolean> => {
		setIsLoading(true);
		const isValid = await ref.current?.onValidate();
		setCurrentStepValid(!!isValid);
		setIsLoading(false);
		return !!isValid;
	};

	const { step, onNextStep, onPrevStep, goTo, currentStepIdx, steps, isLastStep, isFirstStep } = useMultiStepForm([
		{
			caption: 'General parameters',
			element: <GeneralInfo ref={ref} />,
		},
		{
			caption: 'Volatility',
			element: <Volatility ref={ref} />,
		},
		{
			caption: 'Cap Table import',
			element: (
				<CapTable
					ref={ref}
					getCapTableBases={valuationStore.getCapTableBases}
					getSavedCapTableBase={valuationStore.getSavedCapTableBase}
					getSingleCapTableData={valuationStore.getSingleCapTableData}
					isViewMode={valuationStore.isViewMode}
					waterfallId={valuationStore.waterfallId}
				/>
			),
		},
		{
			caption: 'Equity Awards import',
			element: (
				<EquityPlansImport
					ref={ref}
					deleteAwardType={valuationStore.deleteAwardType}
					getOptions={valuationStore.getOptions}
					getValuationDate={valuationStore.getValuationDate}
					reimportOptions={valuationStore.reimportOptions}
					updateOptionsMode={valuationStore.updateOptionsMode}
					uploadOptions={valuationStore.uploadOptions}
					waterfallId={valuationStore.waterfallId}
					addAwardType={valuationStore.addAwardType}
					updateAwardType={valuationStore.updateAwardType}
				/>
			),
			isRequired: false,
		},
		{
			caption: 'Liquidation preferences',
			element: (
				<LiquidationPreferences
					ref={ref}
					deleteClassRight={valuationStore.deleteClassRight}
					getShareClassRight={valuationStore.getShareClassRight}
					getShareClassRights={valuationStore.getShareClassRights}
					isViewMode={valuationStore.isViewMode}
					shareClasses={valuationStore.shareClasses}
					timeToExit={valuationStore.generalForm.timeToExit}
					updateClassRight={valuationStore.updateClassRight}
					valuationDate={valuationStore.generalForm.valuationDate}
					waterfallId={valuationStore.waterfallId}
				/>
			),
		},
		{
			caption: 'Preferences terms',
			element: (
				<PreferencesTerms
					ref={ref}
					addPreferenceClassRight={valuationStore.addPreferenceClassRight}
					deletePreferenceClassRight={valuationStore.deletePreferenceClassRight}
					getPreferenceClassRights={valuationStore.getPreferenceClassRights}
					updatePreferenceClassRights={valuationStore.updatePreferenceClassRights}
					waterfallId={valuationStore.waterfallId}
					isViewMode={valuationStore.isViewMode}
				/>
			),
		},
		{
			caption: 'Valuation summary',
			element: <Summary ref={ref} />,
		},
	]);

	const menuActions: MenuActionType[] = [
		{
			label: 'Save as draft',
			icon: IC_DUPLICATE2,
			onClick: onSaveHandler,
			qaid: 'ActionsMenu.Button.Duplicate',
		},
		{
			label: 'Export to Excel',
			icon: IC_EXCEL_EXPORT,
			// separator: project.isPublished,
			qaid: 'ActionsMenu.Button.Export',
			disabled: currentStepIdx !== WaterfallFormSteps.summary,
		},
	];

	useEffect(() => {
		const waterfallId = +params.waterfallId;
		const isDifferent = waterfallId && valuationStore.currentWaterfallId && waterfallId !== valuationStore.currentWaterfallId;
		// if (isNullOrUndefined(valuationStore.isViewMode) || isDifferent) {
		// 	valuationStore.resetForm();
		// 	return history.push(Routes.valuation.index); // If the user didn't come from the waterfall table
		// }
		const step = +params.step;
		valuationStore.currentWaterfallId = waterfallId;

		if (!isNaN(step)) goTo(step);

		return () => {
			// history.push(Routes.waterfall.index);
			valuationStore.clearProjects();
			valuationStore.resetWaterfallProject();
		};
	}, []);

	const isNextStep = (targetStepIdx: number): boolean => {
		return targetStepIdx - 1 === currentStepIdx;
	};

	const isStepAllowed = (targetStepIdx: number): boolean => {
		return targetStepIdx <= valuationStore.lastAllowedStep;
	};

	useEffect(() => {
		if (!valuationStore.waterfallId) return;
		history.replace(`${Routes.valuation.index}/${valuationStore.waterfallId}/${currentStepIdx}`);
	}, [currentStepIdx, valuationStore.waterfallId]);

	const onStepClickHandler = async (targetStepIdx: number) => {
		return goTo(targetStepIdx);
		if (isFirstStep && isStepAllowed(targetStepIdx)) {
			return onSubmit(null, true, targetStepIdx);
		}

		if (isNextStep(targetStepIdx)) {
			return onSubmit(null, true);
		}
		if (isStepAllowed(targetStepIdx)) {
			return goTo(targetStepIdx);
		}
		const stepsBetween = steps.slice(currentStepIdx + 1, targetStepIdx);
		const isSkippable = stepsBetween.every((step) => step.isRequired === false); // If all between steps are not mandatory, skip

		if (isSkippable) {
			await onSubmit();
			return goTo(targetStepIdx);
		}
	};

	const onQuitHandler = async () => {
		if (!valuationStore.isViewMode && valuationStore.currentWaterfallId) {
			await valuationStore.updateLastModified({
				waterfallId: valuationStore.waterfallId,
				companyId,
			});
		}

		history.push(Routes.valuation.index);

		// showModal({
		// 	type: "confirm",
		// 	title: t("waterfall.saveChanges"),
		// 	body: t("waterfall.quitFromProject", { projectName: valuationStore.projectName }),
		// 	isMust: true,
		// 	onCancel: async () => {
		// 		// const isValid = await ref.current?.onValidate();
		// 		// !isValid &&
		// 		await valuationStore.updateLastModified({
		// 			waterfallId: valuationStore.waterfallId,
		// 			companyId,
		// 		});
		// 		history.push(Routes.waterfall.index);
		// 	},
		// });
	};

	return (
		<section className={AddEditWaterfallStyle}>
			<ProgressBar
				pageCaptions={steps.map((step: MultiStepFormItem) => step.caption ?? '')}
				onStepClickHandler={onStepClickHandler}
				currentStepIdx={currentStepIdx}
				title={
					<>
						<span style={{ color: appConfig.style.colors.text2 }}>Analysis name:</span>{' '}
						{valuationStore.projectName || t('waterfall.createNewProject')}
					</>
				}
				lastCompletedIdx={valuationStore.lastAllowedStep - 1}
				actions={
					<div className={ActionsMenuStyle}>
						{/* <Button qaid="ProgressBar.Button.Save" onClick={onSaveHandler} inverse label="Save as" className="button--waterfall" /> */}
						<Button qaid="ProgressBar.Button.Quit" onClick={onQuitHandler} label="Save & Quit" cancel className="button--waterfall" />

						<ActionsMenu actions={menuActions} />
					</div>
				}
				isLastStepHighlighed
			/>

			{step}

			<WaterfallFooter>
				{!isFirstStep && <Button qaid="AddEditWaterfall.Button.Back" type="button" inverse onClick={onPrevStep} label="general.back" />}

				<Button
					style={{ marginLeft: 'auto' }}
					qaid="AddEditWaterfall.Button.Next"
					ref={nextRef}
					isLoading={isLoading}
					onClick={onSubmit}
					label={
						isLastStep ? 'general.finish' : +params.step === ValuationFormSteps.preferencesTerms ? 'waterfall.evaluateAndSummarize' : 'general.next'
					}
				/>
			</WaterfallFooter>
		</section>
	);
};

const SaveAsModal = (props: { goTo: (step: number) => void }) => {
	const [name, setName] = useState<string>('');
	// const [reviewers, setReviewers] = useState<Reviewer[] | undefined>(undefined);
	const [error, setError] = useState<string>();
	const { valuationStore, auth } = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { clearModals } = useModal();
	const history = useHistory();

	useEffect(() => {
		const isMatch = /^[a-zA-Z0-9 \-&:+@()]*$/.test(name);
		setError(isMatch ? '' : 'Only letters, numbers, spaces or "- & : + @ ( )" allowed');
	}, [name]);

	const modalCss = css({
		maxWidth: 500,
	});

	const onSaveHandler = async () => {
		if (!name || error) return;
		setIsLoading(true);
		setError(undefined);
		const res = await valuationStore.duplicateProject({
			companyId: rootStore.companyStore.companyId,
			waterfallId: valuationStore.waterfallId,
			projectName: name,
		});
		if (isNullOrUndefined(res.data)) {
			setIsLoading(false);
			setError(res.errorMessage);
			return;
		}
		const projectRes = await valuationStore.getProject(res.data);
		if (isNullOrUndefined(projectRes.data)) return;

		const path = `${Routes.valuation.index}/${projectRes.data.waterfallId}${valuationStore.lastAllowedStep ? `/${valuationStore.lastAllowedStep}` : ''}`;
		valuationStore.setProjectName(projectRes.data.projectName);
		valuationStore.isNewProject = false;
		// to check if owner
		valuationStore.setProjectViewMode(false);
		history.push(path);
		props.goTo(valuationStore.lastAllowedStep);
	};

	return (
		<Flex direction="column" className={modalCss} margin="auto">
			<Text family="alt" fontWeight={700} color="primary" size={18} className="mb-5">
				Create a copy of analysis project
			</Text>
			<TextInput
				label="New analysis project name"
				required
				value={name}
				onEnter={onSaveHandler}
				error={error}
				onChange={(val) => setName(val)}
				qaid="SaveAsDialog.Input.ProjectName"
			/>
			<span style={{ maxWidth: '19.5rem', fontSize: '1.1rem', color: '#ACB2CC', marginTop: '0.5rem' }}>
				Note: Valuation project name should be unique among your company Waterfall analysis. The new name should differ from the original Waterfall
				project's name.
			</span>

			{/* <Text family="assistant.bold">Valuation Reviewers:</Text>
      {reviewers ? (
        <Flex direction="column" align="start">
          {reviewers.map((reviewer) => (
            <span style={{ color: appConfig.style.colors.text2 }} key={reviewer.userId}>{`${reviewer.name} (${
              reviewer.isOwner ? "Editor" : "Viewer"
            })`}</span>
          ))}
        </Flex>
      ) : (
        <Flex direction="column" width="100%">
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Flex>
      )} */}

			<Flex justify="center" gap={20} margin="40px 0 0 0">
				<Button qaid="SaveAsDialog.Button.Quit" label="general.quit" cancel onClick={clearModals} />
				<Button isLoading={isLoading} qaid="SaveAsDialog.Button.Save" label="general.save" disabled={!!error || !name} onClick={onSaveHandler} />
			</Flex>
		</Flex>
	);
};

export default observer(AddEditWaterfall);
