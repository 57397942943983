export enum IndustryEnum {
	TECNO,
	TELCM,
	HLTHC,
	FINAN,
	RLEST,
	CODIS,
	COSTP,
	INDUS,
	BMATR,
	ENEGY,
	UTILS,
}
