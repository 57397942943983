
import { makeAutoObservable, toJS } from "mobx";
import { GetGrantsFilterBy } from "../Models/API/enums";
import { BenTable, IBeneficiary, INewBeneficiary } from "../Models/App/EquityPlans/Beneficiaries";
import { IOrder, OrderType } from "../Models/App/EquityPlans/Order";
import { CompanyCustomData, ICompanyDetails, IEquityPlan, IEquityPlanTemp, ILog, IManualVestingSchedule, IPool, IPoolStep, IVestingSchedule, }
    from "../Models/App/EquityPlans/Company";
import { IGrantCancelation } from "../Models/App/EquityPlans/GrantCancelations";
import { IModifiedGrant, IBaseGrant } from "../Models/App/EquityPlans/GrantEvents";
import { BenGrant, GrantTable, IGrant } from "../Models/App/EquityPlans/Grants";
import EquityPlansService from "../Services/EquityPlansService";
import { nulldefined } from "../Shared/Interfaces/global";
import { RootStore } from "./RootStore";
import { CustomDataTypes } from "../Models/App/EquityPlans/equityEnums";
import { EntityTrackHistoryType, EntityType, IDashboardData, MultiType } from "../Models/App/EquityPlans/General";
import { EntityHistoryUpdate } from "../Models/App/EquityPlans/reqModel/general";
import { EntitiyHistoryRecord, EntitiyHistoryRes } from "../Models/App/EquityPlans/resModel/general";
import { isNullOrUndefined } from "../Shared/Utilities";
// import { VestStep } from "../Screens/ESOP/Sections/Settings/VestingSchedule/NewVestingSchedule/NewVestingSchedule";
import { InfoTypeEnum, InfoTypes } from "../Screens/ESOP/Sections/ImportFromExcel/helpers/types";
import importFields from "../Screens/ESOP/Sections/ImportFromExcel/helpers/fields";
import { NumberOption } from "../Models/API/All/NumberOption";
import { makePersistable } from "mobx-persist-store";





interface ICompany {
    companyId: number;
}

interface ImportConfig {
    type: InfoTypes;
    originalData: Array<string[]>;
    // customData: Array<string[]> | undefined;
    headlineIndex: number;
    rowIndex: number;
    columnIndex: number;
}

export default class EquityPlansStore {
    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
        makePersistable(this, {
            name: "EquityPlansStore",
            properties: ["selectedPlan", "companyData"],
            storage: window.sessionStorage,
            expireIn: 10800000, // 3 hours
        });
        rootStore.companyStore.onCompanyChange.subscribe(this.initServices);
        this._dataAsOfDate = new Date();
    }

    equityPlansService: EquityPlansService = null as any;

    private initServices = () => {
        if (this.rootStore.companyStore.companyId) {
            this.ResetStore()

            this.equityPlansService = new EquityPlansService(this.rootStore.companyStore.companyId)

            RootStore.subscribeToLoading([], this.rootStore)
        }
    };

    ResetStore() {
        this.companyBeneficiaries = null
        this.dataAsOfDate = new Date()
        this.selectedPlan = undefined
        this.companyData = undefined
        this.companyBeneficiaries = undefined
        this.companyGrants = undefined
        this.companyOrders = undefined
        this.companyCancelations = undefined
        this.beneficiariesData = null
        this.equityDashboard = {} as IDashboardData
        this.isRegistrationCompleted = false
    }



    // Company

    _dataAsOfDate: Date;
    selectedPlan: number | undefined = undefined;
    companyData: ICompanyDetails | nulldefined = null;
    companyBeneficiaries: BenTable[] | nulldefined = null;
    companyGrants: GrantTable[] | nulldefined = null;
    companyOrders: IOrder[] | nulldefined = null;
    companyCancelations: IGrantCancelation[] | nulldefined = null;
    beneficiariesData: IBeneficiary[] | null = null;
    dataLog: ILog[] | nulldefined = null;
    equityDashboard: IDashboardData = {} as IDashboardData;
    isRegistrationCompleted: boolean = false;

    poolSteps: IPoolStep[] = [];

    newPool: Partial<IPool> = {};
    newEquityPlan: Partial<IEquityPlan> = {};
    equityPlan: IEquityPlanTemp | undefined;
    newVestSchedule: Partial<IManualVestingSchedule> = {};

    get tempCompanyId() {
        return this.rootStore.companyStore.companyId;
    }

    get companyActiveVests() {
        return this.companyData?.vestings
            ?.filter((vst) => vst.active === true)
            .map((aVst) => {
                return { label: aVst.vestingName, value: aVst.vestingId };
            });
    }

    get companyPlans(): NumberOption[] {
        const plans =
            this.companyData?.plans?.map((pln) => {
                return { label: pln.name, value: pln.planId };
            }) || [];
        plans?.push({ label: "All", value: 0 });
        return plans;
    }

    get dataAsOfDate() {
        return this._dataAsOfDate;
    }

    set dataAsOfDate(date: Date) {
        console.log("Store Setting", date);
        this._dataAsOfDate = date;
    }


    get showTrustee() {
        return true
    }



    // Beneficiary

    beneficiaryData: IBeneficiary = {} as IBeneficiary;
    // newBeneficiary: Partial<INewBeneficiary> = {};
    beneficiaryGrants: GrantTable[] | null = null;
    beneficiaryGrantData: Partial<IGrant> = {};
    beneficiaryOrders: IOrder[] | nulldefined = null;
    benficiaryGrantCancelations: IGrantCancelation[] | nulldefined = null;
    benGrantCancelation: IGrantCancelation | nulldefined = null;


    //  Grant
    grant: IGrant | null = null;
    newGrant: Partial<IGrant> = {};
    grantOrders: IOrder[] = [{}] as IOrder[];
    grantCancelations: IGrantCancelation[] | nulldefined = null;


    //  Grant Events

    originalGrantEvents: IBaseGrant[] | nulldefined = null;
    modifiedGrantEvents: IModifiedGrant[] | nulldefined = null;


    //  Orders

    newOrder: Partial<IOrder> = {};
    newGrantCancelation: Partial<IGrantCancelation> = {};
    beneficiaryOrder: IOrder = {} as IOrder;


    // History

    historyTrackRecords: EntitiyHistoryRecord[] | undefined = [];

    // Import from excel

    importConfig: ImportConfig = {} as ImportConfig;

    setImportType(type: InfoTypes) {
        this.importConfig.type = type;
    }

    setImportData(data: Array<string[]>) {
        this.importConfig.originalData = data;
    }

    get customImportData() {
        return [
            ...this.importConfig.originalData.slice(0, this.importConfig.headlineIndex),
            ...this.importConfig.originalData.slice(this.importConfig.headlineIndex + 1),
        ];
    }

    get importFields() {
        if (!this.importConfig.type) return [];
        return importFields[InfoTypeEnum[this.importConfig.type]];
    }




    // selectable options

    // chcek
    get beneficiariesList() {
        if (!this.companyBeneficiaries) return [];
        return this.companyBeneficiaries?.map((ben) => {
            return {
                label: `${ben.fullName.firstName} ${ben.fullName.lastName}, Emp. Number: ${ben.employeeNumber}`,
                value: ben.beneficiaryId,
            };
        });
    }

    get beneficiaryGrantList() {
        return (
            this.beneficiaryGrants?.map((grt) => {
                return { label: grt.grantNumber, value: grt.grantId };
            }) ?? []
        );
    }

    get sites() {
        return (
            this.companyData?.sites?.map((sit) => {
                return { value: sit.key, label: sit.value };
            }) ?? []
        );
    }

    get departments() {
        return (
            this.companyData?.departments?.map((dep) => {
                return { value: dep.key, label: dep.value };
            }) ?? []
        );
    }

    get subDepartments() {
        return (
            this.companyData?.subDepartments?.map((dep) => {
                return { value: dep.key, label: dep.value };
            }) ?? []
        );
    }

    get taxTracks() {
        return (
            this.companyData?.taxTracks?.map((dep) => {
                return { value: dep.key, label: dep.value };
            }) ?? []
        );
    }



    //  Company

    async CreateNewCompany() {
        const res = await this.equityPlansService.CreateNewCompany(this.tempCompanyId);
        return !isNullOrUndefined(res);
    }

    async GetBeneficiariesDashboardData() {
        const res = await this.equityPlansService.GetEquityDashboardBeneficiaries(this.tempCompanyId, this.dataAsOfDate, this.selectedPlan);
        // console.log("dashboard data", res);
        if (res.data) {
            this.companyBeneficiaries = res.data;
            this.GetDashboardData();
        }
    }

    async GetEquitySettings() {
        const { data } = await this.equityPlansService.GetEquitySettings(this.tempCompanyId);
        const isFullyRegistered = !!(data?.plans?.length && data.vestings?.length && data.taxTracks?.length);
        this.isRegistrationCompleted = isFullyRegistered;
        this.companyData = data;
        if (isNullOrUndefined(this.selectedPlan)) this.selectedPlan = data?.plans?.[0].planId;
        return data;
    }

    async GetDataLog(date: Date | string) {
        const { data } = await this.equityPlansService.GetCompanyLog(this.tempCompanyId, date);
        this.dataLog = data;
    }

    // async GetBeneficiariesForCompany() {
    // 	this.companyBeneficiaries = await this.equityPlansService.GetCompanyBeneficiaries(this.tempCompanyId);
    // }

    async GetGrantsForCompany(companyId = this.tempCompanyId) {
        const res = await this.equityPlansService.GetGrants(companyId, undefined, undefined, this.dataAsOfDate, this.selectedPlan);
        if (res.data) {
            this.companyGrants = res.data;
            this.GetDashboardData();
        }
    }

    async GetGrantCancelationsForCompany() {
        const res = await this.equityPlansService.GetGrantCancelations(
            this.tempCompanyId,
            GetGrantsFilterBy.Company,
            undefined,
            undefined,
            this.dataAsOfDate,
            this.selectedPlan
        );
        this.companyCancelations = res?.data;
    }

    // async AddLegalEntityToCompany(legalEntityName: string) {
    // 	console.log("Adding Legal entity", legalEntityName);
    // 	const data = await this.equityPlansService.CreateLegalEntity(this.tempCompanyId, 0, legalEntityName);
    // 	console.log("AddDepartmentToCompany", data);
    // }

    // async UpdateCompanyLegalEntity(companyId = this.tempCompanyId, legalEntityId: number, newName: string) {
    // 	await this.equityPlansService.UpdateLegalEntity(companyId, legalEntityId, newName);
    // }




    // Department

    async AddDepartmentToCompany(depName: string) {
        const data = await this.equityPlansService.CreateDepartment(this.tempCompanyId, 0, depName);
        return data;
    }

    async UpdateCompanyDepartment(departmentId: number, newName: string) {
        await this.equityPlansService.UpdateDepartment(this.tempCompanyId, departmentId, newName);
    }

    async AddSubDepartmentToCompany(depName: string) {
        const data = await this.equityPlansService.CreateDepartment(this.tempCompanyId, 1, depName);
        return data;
    }

    async DeleteDepartment(departmentId: number) {
        const res = await this.equityPlansService.DeleteDepartment(departmentId, this.tempCompanyId, false);
        if (res) {
            this.GetEquitySettings();
        }
        return res;
    }

    async DeleteSubDepartment(departmentId: number) {
        const res = await this.equityPlansService.DeleteDepartment(departmentId, this.tempCompanyId, true);
        if (res) {
            this.GetEquitySettings();
        }
        return res;
    }


    // Site

    async AddSiteToCompany(siteName: string) {
        return await this.equityPlansService.CreateSite(this.tempCompanyId, siteName);
    }

    async DeleteSite(siteId: number) {
        const res = await this.equityPlansService.DeleteSite(this.tempCompanyId, siteId);

        if (res) {
            this.GetEquitySettings();
        }
        return res;
    }


    // Tax Track

    async AddTaxTrackToCompany(name: string) {
        const res = await this.equityPlansService.CreateTaxTrack(this.tempCompanyId, name);
        return res;
    }

    async DeleteTaxTrack(taxTrackId: number) {
        const res = await this.equityPlansService.DeleteTaxTrack(taxTrackId, this.tempCompanyId);

        if (res) {
            this.GetEquitySettings();
        }
        return res;
    }

    // async UpdateCompanySubDepartment(companyId = this.tempCompanyId, subDepartmentId: number, newName: string) {
    // 	await this.equityPlansService.UpdateSubDepartment(companyId, subDepartmentId, newName);
    // }

    async GetEquityPlan(planId: number) {
        const res = await this.equityPlansService.GetSinglePlan(planId, this.tempCompanyId);
        this.equityPlan = res.data;
        return res.data;
    }

    async GetPoolSteps(poolId: number) {
        const res = await this.equityPlansService.GetPoolStepsForPlan(this.tempCompanyId, poolId);
        this.poolSteps = res.data;
    }

    async AddEquityPlan() {
        // await create pool
        if (!this.newEquityPlan.poolParams) this.newEquityPlan.poolParams = {};

        this.newEquityPlan.poolParams.stepType = 0;
        const plan = { ...(this.newEquityPlan as IEquityPlan), companyId: this.tempCompanyId };
        if (!plan.poolParams?.amount) return;
        plan.poolParams.boardApprovalDate = plan.planApprovalDateByBoard;
        if (plan.poolParams?.boardApprovalDate) plan.poolParams.date = plan.poolParams.boardApprovalDate;

        const res = await this.equityPlansService.CreateNewPlan(plan);
        if (res.data?.planId) {
            this.GetEquitySettings();
        }

        return res;
    }

    async DeleteEquityPlan(planId: number) {
        const res = await this.equityPlansService.DeletePlan(planId, this.tempCompanyId);
        this.GetEquitySettings();
        return res;
    }

    async UpdateEquityPlan(planId: number) {
        const updtdPlan = { ...this.equityPlan, companyId: this.tempCompanyId };
        await this.equityPlansService.UpdatePlan(planId, updtdPlan);
    }

    async createPoolForPlan(pool: IPool) {
        const fPool = { pool, companyId: this.tempCompanyId };
    }

    AddVestingSchedule() {
        return this.equityPlansService.AddVestingSchedule({ ...this.newVestSchedule, companyId: this.tempCompanyId });
    }

    async AddRemoveVestFromCompany(add: boolean, vestingId: number) {
        if (add) {
            return await this.equityPlansService.ConnectVestToCompany({
                companyId: this.tempCompanyId,
                params: [{ companyId: this.tempCompanyId, vestingId }],
            });
        } else {
            return await this.equityPlansService.DisConnectVestFromCompany(vestingId, this.tempCompanyId);
        }
    }

    // setVestingScheule(vesting: Partial<IVestingSchedule>) {
    // 	this.newVestSchedule = vesting;
    // }




    // dashboard

    async GetDashboardOrders() {
        const data = await this.equityPlansService.GetCompanyOrders(this.tempCompanyId, this.dataAsOfDate, this.selectedPlan);
        this.companyOrders = data.data;
    }

    async GetDashboardData() {
        const res = await this.equityPlansService.GetDashboardData(this.tempCompanyId, this.dataAsOfDate, this.selectedPlan);
        if (res.data) {
            this.equityDashboard = res.data;
        }
    }



    //  Beneficiary

    async GetBeneficiary(benficiaryId: number) {
        const data = await this.equityPlansService.GetBeneficiary(this.tempCompanyId, benficiaryId);
        this.beneficiaryData = data.data;
    }

    async GetGrantsForBeneficiary(beneficiaryId: number) {
        const data = await this.equityPlansService.GetBeneficiaryGrants(beneficiaryId, this.tempCompanyId);
        this.beneficiaryGrants = data.data;
    }

    async GetOrdersForBeneficiary(beneficiaryId: number) {
        const data = await this.equityPlansService.GetOrders(GetGrantsFilterBy.Contact, this.tempCompanyId, beneficiaryId);
        this.beneficiaryOrders = data.data;
    }

    async GetCancelationsForBeneficiary(beneficiaryId: number) {
        const data = await this.equityPlansService.GetGrantCancelations(
            this.tempCompanyId,
            GetGrantsFilterBy.Contact,
            beneficiaryId,
            undefined
        );
        this.benficiaryGrantCancelations = data.data;
    }

    async GetGrantCancelation(cancelId: number) {
        const data = await this.equityPlansService.GetSingleGrantCancelation(this.tempCompanyId, cancelId);
        this.benGrantCancelation = data.data;
    }

    async UpdateBeneficiary(benficiaryId: number, data: IBeneficiary) {
        const benData = { ...data };
        const res = await this.equityPlansService.UpdateBeneficiary(this.tempCompanyId, benficiaryId, benData);
        // this.beneficiaryData = data
        if (res.data?.beneficiaryId) {
            this.GetBeneficiariesDashboardData();
        }
        return res;
    }

    async UpdateBeneficiaryCCD(
        companyId: number,
        beneficiaryId: number,
        data: CompanyCustomData,
        dataType: CustomDataTypes,
        startDate: string | Date,
        endDate?: string | Date
    ) {
        const fData = { ...data, date: startDate };
        this.equityPlansService.SetBeneficiaryCCD(this.tempCompanyId, beneficiaryId, fData, dataType, startDate, endDate);
    }

    async UpdateMultipleBeneficiaries(uType: MultiType, ids: number[]) {
        return await this.equityPlansService.UpdateMultipleBeneficiaries(uType, ids, this.tempCompanyId);
    }

    async UpdateBeneficiaryEntityHistory(
        eType: EntityTrackHistoryType,
        ccData: CompanyCustomData,
        isModify: boolean,
        updateGrants: boolean
    ) {
        const data: EntityHistoryUpdate = {
            entityTrackHistoryType: eType,
            entityTrackParams: ccData,
            isModify,
            updateGrants,
        };
        const res = this.equityPlansService.SetBeneficiaryEntityHistory(this.tempCompanyId, this.beneficiaryData.beneficiaryId, data);
    }

    CheckIfUserExist(employeeNumber?: string) {
        if (isNullOrUndefined(employeeNumber)) return { data: true };
        return this.equityPlansService.CheckIfUserExists(this.tempCompanyId, employeeNumber);
    }

    async AddBeneficiary(beneficiary: INewBeneficiary) {
        const newBen = { ...beneficiary, companyId: this.tempCompanyId };
        const res = await this.equityPlansService.CreateBeneficiary(newBen);

        await this.GetBeneficiariesDashboardData();
        return res;
    }

    async AddGrantToBeneficiary() {
        const benId = this.beneficiaryData.beneficiaryId;
        const companyId = this.beneficiaryData.companyId;

        const fulldata = {
            ...this.newGrant,
            beneficiaryId: benId,
            companyId,
            exersicePrice: this.newGrant.exercisePrice,
        };
        const res = await this.equityPlansService.CreateSingleGrant(fulldata);

        if (res.data?.beneficiaryId) {
            this.GetGrantsForBeneficiary(benId);
            this.GetBeneficiariesDashboardData();
            this.GetGrantsForCompany();
        }
        return res;
    }

    async DeleteBeneficiary() {
        this.equityPlansService.RemoveBeneficiary(this.beneficiaryData.beneficiaryId, this.beneficiaryData.companyId);
        this.GetBeneficiariesDashboardData();
    }

    async DeleteBeneficiary_Qa(beneficiaryId: number) {
        return await this.equityPlansService.RemoveBeneficiary(beneficiaryId, this.tempCompanyId);
    }

    async InviteToEmployeePortal(sourceContactId: number) {
        // if (!this.beneficiaryData.businessEmail) return;
        return await this.equityPlansService.InviteToEmployeeManagment(sourceContactId, this.tempCompanyId);
    }



    //  Grant

    ResetGrant() {
        this.newGrant = {};
    }

    ValidateGrant() {
        return this.equityPlansService.ValidateGrant({
            ...this.beneficiaryGrantData,
            companyId: this.tempCompanyId,
        });
    }

    CheckIfGrantNumberExists(gNumber = this.newGrant.grantNumber!) {
        return this.equityPlansService.CheckIfGrantNumberExists(this.tempCompanyId, gNumber);
    }

    async CheckIso() {
        return await this.equityPlansService.CheckIsoReq({
            ...this.newGrant,
            companyId: this.tempCompanyId,
        });
    }

    async CreateGrant() {
        const res = await this.equityPlansService.CreateGrant({
            ...this.newGrant,
            companyId: this.tempCompanyId,
        });
        return res;
    }

    async UpdateGrant() {
        const data = { ...this.beneficiaryGrantData };
        const res = await this.equityPlansService.UpdateBenGrant(data, this.tempCompanyId);

        if (res.data) this.GetGrantsForCompany();
        return res;
    }

    async DeleteGrant(grantId: number) {
        const res = await this.equityPlansService.DeleteGrant(grantId, this.tempCompanyId);
        if (res.data === true) this.GetGrantsForCompany();
        return res;
    }

    async CreateSingleGrant() {
        const data = this.beneficiaryGrantData as BenGrant;
        const fullData = {
            ...data,
            companyId: this.tempCompanyId,
            contactId: 2759,
            vestingId: 1,
            site: { key: 5, value: "The Lame Site", companyId: this.tempCompanyId },
            expirationDate: new Date(),
        };
        const res = await this.equityPlansService.CreateSingleGrant(fullData);
    }

    async GetSingleGrant(grantId: number) {
        const res = await this.equityPlansService.GetGrant(grantId, this.tempCompanyId);
        if (res.data) {
            this.beneficiaryGrantData = res.data;
        }
    }

    async GetOrdersForSingleGrant(beneficiaryId: number, grantId: number) {
        const data = await this.equityPlansService.GetOrders(2, this.tempCompanyId, beneficiaryId, grantId);
        this.grantOrders = data.data;
    }

    async GetCancelationOrdersForSingleGrant(beneficiaryId: number, grantId: number) {
        const res = await this.equityPlansService.GetGrantCancelations(this.tempCompanyId, GetGrantsFilterBy.Grant, beneficiaryId, grantId);
        this.grantCancelations = res.data;
    }

    async ValidateExpDate(granted: number, grantDate: Date, vestingStartDate: Date, vestingId: number) {
        return await this.equityPlansService.GetLastVestDate(granted, grantDate, vestingStartDate, vestingId, this.tempCompanyId);
    }



    // Order

    async TypesAndAmountsForOrder(grantId: number, date: Date) {
        return await this.equityPlansService.GetAmountsAndOrderTypes(this.tempCompanyId, grantId, date);
    }

    async ValidateOrderAmount(grantId: number, asOfDate: Date, orderType: OrderType) {
        return await this.equityPlansService.GetAmountForOrderType(this.tempCompanyId, grantId, asOfDate, orderType);
    }

    async CreateNewOrderForBeneficiary(grantId: number) {
        const data = {
            ...this.newOrder,
            companyId: this.tempCompanyId,
            grantId: grantId,
            contactId: this.beneficiaryData.beneficiaryId,
        };
        const res = await this.equityPlansService.CreateNewGrantOrder(data);
        if (res.data?.grantId) {
            this.GetOrdersForBeneficiary(data.contactId);
        }
        return res;
    }

    async CreateNewOrder(grantId: number, beneficiaryId: number) {
        const data = {
            ...this.newOrder,
            companyId: this.tempCompanyId,
            grantId: grantId,
            contactId: beneficiaryId,
        };
        return await this.equityPlansService.CreateNewGrantOrder(data);
    }

    async CreateNewQaOrder(grantId: number, beneficiaryId: number, orderType: OrderType) {
        const data = {
            ...this.newOrder,
            companyId: this.tempCompanyId,
            grantId: grantId,
            contactId: beneficiaryId,
            orderType,
        };
        return await this.equityPlansService.CreateNewGrantOrder(data);
    }

    async GetBeneficiaryOrder(orderId: number) {
        const res = await this.equityPlansService.GetOrderById(orderId, this.tempCompanyId);
        this.beneficiaryOrder = res.data;
    }

    ValidateTransaction(data: Partial<IOrder>) {
        return this.equityPlansService.ValidateTransaction(data);
    }



    //  Grant Cancelation

    async CancelSingleGrant(grantId: number, beneficiaryId?: number) {
        const data = {
            ...this.newGrantCancelation,
            companyId: this.tempCompanyId,
            grantId,
            beneficiaryId,
        };

        const res = await this.equityPlansService.CreateGrantCancelation(data);
        // this.GetCancelationsForBeneficiary(data.contactId!);
        return res;
    }

    async CancelBeneficiaryGrant() {
        const data = {
            ...this.newGrantCancelation,
            companyId: this.tempCompanyId,
        };

        const res = await this.equityPlansService.CreateGrantCancelation(data);
        // this.GetCancelationsForBeneficiary(data.contactId!);
        return res;
    }

    async TerminateEmployeeQa(beneficiaryId?: number) {
        const data = { ...this.newGrantCancelation };
        data.contactId = beneficiaryId;
        data.companyId = this.tempCompanyId;

        if (!data.companyId || !data.contactId) return;

        const res = await this.equityPlansService.CreateGrantCancelation(data);
        this.GetGrantsForCompany();
        this.GetGrantsForBeneficiary(data.contactId);
        return res;
    }

    async CancelGrantQA() {
        const data = { ...this.newGrantCancelation };
        data.contactId = this.beneficiaryGrantData.beneficiaryId;
        data.grantId = this.beneficiaryGrantData.grantId;
        data.companyId = this.tempCompanyId;

        if (!data.companyId || !data.contactId || !data.grantId) return;

        const res = await this.equityPlansService.CreateGrantCancelation(data);
        this.GetGrantsForCompany();
        return res;
    }



    //  Grant Events

    async GetOriginalGrantEvents(grantId: number) {
        const res = await this.equityPlansService.GetUnModifiedGrantEvents(this.tempCompanyId, grantId);
        this.originalGrantEvents = res.data;
    }

    // async GetModifiedGrantEvents(grantId: number) {
    // 	const data = await this.equityPlansService.GetModifiedGrantEvents(this.tempCompanyId, grantId);
    // 	this.modifiedGrantEvents = data;
    // 	console.log("Get_MODIFIED_GrantEvents data store", data);
    // 	// data?.forEach(vest => {
    // 	//   if (vest.vestingHistories) {
    // 	//     console.log('HISTORY', vest.vestingHistories)
    // 	//   }
    // 	// })
    // }





    //    General     ***

    async GetGrantTrackHistory(grantId: number, hstryType: EntityTrackHistoryType) {
        return await this.equityPlansService.GetTrackHistory(hstryType, EntityType.Grant, grantId, this.tempCompanyId);
    }

    async GetBeneficiaryTrackHistory(beneficiaryId: number, hstryType: EntityTrackHistoryType) {
        const res = await this.equityPlansService.GetTrackHistory(hstryType, EntityType.Beneficiary, beneficiaryId, this.tempCompanyId);
        this.historyTrackRecords = res.data;
    }

    async GetSaaSMainDashboardData() {
        return await this.equityPlansService.GetSaaSMainDashboardData();
    }

    async GetCfoLogEvents(page: number, size: number = 10) {
        if (!this.tempCompanyId) return { isSuccess: false, data: null };
        return await this.equityPlansService.GetCfoLogEvents(page, size);
    }

    async getBeneficiaryByContactId(contactId: number) {
        return this.equityPlansService.getBeneficiaryByContactId(this.tempCompanyId, contactId);
    }

    createGrantLetter(grantId: number, templateId: string, variablesCollection: string[] = []) {
        return this.equityPlansService.createGrantLetter(grantId, templateId, variablesCollection);
    }
}
