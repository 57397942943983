import { useRef } from 'react';
import { IC_PREVIEW_DATE, IC_PREVIEW_DATE_COMPLETE, IC_PREVIEW_PENDING, IC_PREVIEW_SIGNATURE, IC_PREVIEW_USER } from '../../../../Assets';
import { ServerResponseApi } from '../../../../Models/API/All/server-response';
import { ElectronicSignature } from '../../../../Models/API/Document/eletronics-signature';
import { SignatureStatus } from '../../../../Models/API/Document/signature-status-enum';
import { DocumentTemplateData } from '../../../../Models/API/Document/template-data';
import { useFetchApi } from '../../../../Shared/Hooks/useFetchApi';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { formatDate, isNullOrUndefined } from '../../../../Shared/Utilities';
import { InfoProps, InfoType } from '../../Components/Preview';

const useDocument = () => {
	const { documentsStore, contactStore, appState } = useRootStore();
	const { run, data, loading, clearData } = useFetchApi<ServerResponseApi<DocumentTemplateData>>();
	const { showDocumentModal, showSignDocumentModal } = useGeneralModal();
	const changedDocsRefs = useRef<Record<string, boolean>>({});
	const docRef = useRef<ElectronicSignature>();

	const getDocument = (docId: string, updateState: boolean = true) => {
		docRef.current = documentsStore.docs?.find((doc) => doc.id === docId);
		return run(() => documentsStore.getDocument(docId), {
			cacheKey: `${docId}_${changedDocsRefs.current[docId]}`,
			updateState: updateState,
		});
	};

	const getPreviewInfo = (data?: ElectronicSignature, templateId?: string, isTooltip?: boolean): InfoProps => {
		if (isNullOrUndefined(data) || isNullOrUndefined(templateId)) return {} as InfoProps;

		// const template = documentsStore.getTemplateById(templateId) || ({ signers: [] as SignatoryDetails[] } as DocumentTemplateInfo);

		const signatures = data.signers.reduce(
			(acc, signer) => {
				// const signerData = data.signers.find((s) => s?.contactId === signer.contactId);
				if (signer.status === SignatureStatus.Signed) {
					const contactFullName = contactStore.getContactFullNameById(signer.contactId);
					acc.signed.push(contactFullName);
					const signatureDate = formatDate(new Date());
					if (signatureDate) acc.signTime.push(signatureDate);
				}

				if (signer.status === SignatureStatus.SentForSignature) {
					const contactFullName = contactStore.getContactFullNameById(signer.contactId);
					acc.pending.push(contactFullName);
				}
				return acc;
			},
			{ signed: [], pending: [], signTime: [] } as { signed: string[]; pending: string[]; signTime: string[] }
		);

		if (!signatures.pending.length) signatures.pending.push('-');
		if (!signatures.signed.length) signatures.signed.push('-');
		if (!signatures.signTime.length) signatures.signTime.push('-');

		const info = [
			{
				icon: IC_PREVIEW_USER,
				title: 'Sent by',
				text: [contactStore.getContactFullNameById(data.createdByContactId, '-')],
			},
			{
				icon: IC_PREVIEW_DATE,
				title: 'Sent at',
				text: [formatDate(data.createTimeStamp) || '-'],
			},
			{
				icon: IC_PREVIEW_SIGNATURE,
				title: 'Signed by',
				text: signatures.signed,
			},
			{
				icon: IC_PREVIEW_DATE_COMPLETE,
				title: 'Sign date',
				text: signatures.signTime,
			},
		];

		if (signatures.pending.length) {
			info.push({
				icon: IC_PREVIEW_PENDING,
				title: 'Pending',
				text: signatures.pending,
			});
		}

		return {
			info,
			isPreview: false,
			isTooltip,
		};
	};

	const previewDocument = async (id: string, templateId: string, documentName: string, isTooltip: boolean = false) => {
		appState.isLoading = true;
		const res = await Promise.all([getDocument(id), documentsStore.getDocumentSignatures(id)]);
		appState.isLoading = false;
		if (!res[0].data?.data) return;
		showDocumentModal({ ...res[0].data.data, fileName: documentName }, getPreviewInfo(res[1].data?.data, templateId, isTooltip), clearData);
	};

	const signDocument = async (signatures: ElectronicSignature[]) => {
		if (!signatures.length) return;
		const docIds = signatures.map((signature) => signature.document.id);
		const onSuccess = () => {
			docIds.forEach((docId) => {
				changedDocsRefs.current[docId] = true;
			});
		};
		if (signatures.length > 1) {
			return showSignDocumentModal({
				file: undefined,
				info: undefined,
				docIds,
				onSuccess,
			});
		}

		appState.isLoading = true;
		const res = await getDocument(signatures[0].document.id);
		appState.isLoading = false;
		if (!res.data?.data) return;

		const info: InfoType[] = [
			{
				icon: IC_PREVIEW_USER,
				title: 'Created by',
				text: [contactStore.getContactFullNameById(signatures[0].createdByContactId)],
			},
			{
				icon: IC_PREVIEW_DATE,
				title: 'Date',
				text: [formatDate(signatures[0].createTimeStamp) ?? '-'],
			},
		];
		showSignDocumentModal({ file: res.data.data.fileBase64, info, docIds: [signatures[0].document.id], onSuccess });
	};

	return {
		previewDocument,
		signDocument,
		getDocument,
		getPreviewInfo,
		run,
		data,
		loading,
		clearData,
	};
};

export default useDocument;
