import { useHistory } from 'react-router-dom';
import Button from '../../Shared/Components/Button/Button';
import { Routes } from '../../Routes';
import useRootStore from '../../Shared/Hooks/useRootStore';
import { observer } from 'mobx-react-lite';

const DataCollection = () => {
	const history = useHistory();
	const { dataCollectionStore } = useRootStore();

	const onOrderHandler = async () => {
		const res = await dataCollectionStore.createProject();
		if (!res.data?.data.valuationProjectId) return;

		const dcRes = await dataCollectionStore.createDataCollection({ valuationProjectId: res.data.data.valuationProjectId });
		if (!dcRes.data?.data.valuationProjectId) return;

		history.push(`${Routes.valuation.dataCollection}/${dcRes.data.data.valuationProjectId}`);
	};

	return (
		<div>
			<div>Data Collection</div>
			<Button qaid="DataCollection.Button.Order409A" label="Order new 409A" onClick={onOrderHandler} />
		</div>
	);
};

export default observer(DataCollection);
