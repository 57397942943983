import DatePickerInput from '../../../../../Shared/Components/Input/DatePickerInput';
import { TextAreaInput } from '../../../../../Shared/Components/Input/Input.Style';
import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import Tooltip from '../../../../../Shared/Components/Tooltip';

type Props = {
	title: string;
	info?: string;
	comments?: any[];
	renderActions: JSX.Element;
};

const Question = ({ title, info, comments, renderActions }: Props) => {
	return (
		<div>
			<div>
				{title}

				<Tooltip title={info} />

				{renderActions}
			</div>
		</div>
	);
};

export default Question;
