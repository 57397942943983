import { ServerResponseApi } from '../Models/API/All/server-response';
import { DataCollectionProject } from '../Models/API/DataCollection/data-collection-project';
import { DataCollectionProjectResponse } from '../Models/API/DataCollection/data-collection-project-response';
import { ValuationProject } from '../Models/API/DataCollection/valuation-project';
import { DocumentSigner, ElectronicSignature } from '../Models/API/Document/eletronics-signature';
import { SendForSingaturePayload } from '../Models/API/Document/send-for-signature-payload';
import { SendForSignatureResponse } from '../Models/API/Document/send-for-signature-response';
import { DocumentTemplateData } from '../Models/API/Document/template-data';
import { DocumentTemplateInfo } from '../Models/API/Document/template-info';
import { DocumentTemplatePayload } from '../Models/App/Document/template-payload';
import { documentsApi, dataCollectionApi } from './Axios';

export class DataCollectionService {
	// Valuation Project

	createProject() {
		return dataCollectionApi.post<ServerResponseApi<ValuationProject>>('/valuationProject', { projectName: `Test_${+new Date()}` });
	}

	getProjects() {
		return dataCollectionApi.get<ServerResponseApi<ValuationProject[]>>('/valuationProject/company');
	}

	takeProjectOwnership(valuationProjectId: string) {
		return dataCollectionApi.post<ServerResponseApi<ValuationProject>>(`/valuationProject/owner/${valuationProjectId}`);
	}

	// Data Collection

	getDataCollection(valuationProjectId: string) {
		return dataCollectionApi.get<ServerResponseApi<DataCollectionProjectResponse>>(`/dataCollection/valuationProject/${valuationProjectId}`);
	}

	createDataCollection(data: Partial<DataCollectionProject>) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', data);
	}

	updateDataCollection(data: DataCollectionProject) {
		return dataCollectionApi.put<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', data);
	}

	submitDataCollection(dataCollectionId: string, isCompleted: boolean) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', null, null, {
			dataCollectionId,
			dataCollectionCompleted: isCompleted,
		});
	}

	// Document

	uploadDocument() {}
}
