import styled, { StyledComponent } from '@emotion/styled';
import { theme } from '../../../../../config/style/theme';
import appConfig from '../../../../../config/config';

export const CustomRadioBtn = styled.label`
	display: flex;
	align-items: center;

	input[type='radio'] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		border: 2px solid ${theme.colors.primary};
		outline: none;
		transition: border-color 0.2s;
		background-color: white;
		margin-right: 0.5rem;
	}

	input[type='radio']:checked {
		border-color: ${theme.colors.primary};
		background-color: ${theme.colors.primary};
		box-shadow: inset 0 0 0 2px #ffffff;
	}

	input[type='radio']:focus {
		border-color: ${theme.colors.primary};
	}

	input[type='radio']:disabled {
		opacity: 0.25;
	}
`;

export const ModalContent = styled.div`
	height: 100%;
	& > div:not(:last-child) {
		margin-bottom: ${theme.spacings.md};
	}
`;

// Create the main ImportModalWrapper component
const ImportModalWrapper = styled.div({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
	background: appConfig.style.colors.background2Opacity70,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: 100000,
	transition: 'all .3s',
	animation: 'fadeIn 0.25s linear',
});

// Create the Body component separately
const Body = styled.div({
	// Add your styles here
	padding: '4rem',
	backgroundColor: 'white',
	borderRadius: '8px',
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	position: 'relative',
	// You can add more styles as needed
});

const Close = styled.div({
	// Add your styles here
	position: 'absolute',
	top: '2rem',
	right: '2rem',
	cursor: 'pointer',
	// You can add more styles as needed
});

// Extend the ImportModalWrapper with the Body component
type ImportModalWrapperType = typeof ImportModalWrapper & {
	Body: StyledComponent<'div', any>;
	Close: StyledComponent<'div', any>;
};

// Assign the Body to ImportModalWrapper
(ImportModalWrapper as ImportModalWrapperType).Body = Body;
(ImportModalWrapper as ImportModalWrapperType).Close = Close;

export default ImportModalWrapper as ImportModalWrapperType;
