import { observer } from 'mobx-react-lite';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import Fade from 'react-bootstrap/Fade';
import Button from '../../../../../Shared/Components/Button/Button';
import QuestionCard from '../../../../../Shared/Components/QuestionCard/QuestionCard';
import { RadioButtonOptions } from '../../../../../Shared/Components/RadioButton/RadioButton';
import Table from '../../../../../Shared/Components/Table/Table';
import { InputValidationRef } from '../../../../../Shared/Hooks/useFormValidation';
import { eliminateTimeZone, isBoolean, isNullOrUndefined } from '../../../../../Shared/Utilities';

import Heading from '../../../../../Shared/Components/Heading/Heading';
import Hr from '../../../../../Shared/Components/HorizontalRule/Hr';
import DatePickerInput from '../../../../../Shared/Components/Input/DatePickerInput';
import NumberInput from '../../../../../Shared/Components/Input/NumberInput';
import Flex from '../../../../../Shared/Components/Layout/Flex';
import Select from '../../../../../Shared/Components/Select/Select';
import Spinner from '../../../../../Shared/Components/Spinner/Spinner';
import Tooltip from '../../../../../Shared/Components/Tooltip';
import useModal from '../../../../../Shared/Hooks/useModal';
import { theme } from '../../../../../config/style/theme';

import classNames from 'classnames';
import {
	ShareClassRight,
	ShareClassRightIssueRequest,
	ShareClassRightRequest,
	ShareClassRightResponse,
	WaterfallDefaultParams,
} from '../../../../../Models/API/Waterfall/IForm';
import { WaterfallStep } from '../../../../../Screens/Waterfall/Components/AddEditWaterfall/index.style';
import InputGroup from '../../../../../Screens/Waterfall/Components/InputGroup/InputGroup';
import WaterfallTableHeading from '../../../../../Screens/Waterfall/Components/WaterfallTableHeading/WaterfallTableHeading';
import { COLOR_SANTAS_GRAY } from '../../../../../config/style/lib/colors';
import PariPassuSelection from './PariPassuSelection/PariPassuSelection';
import LiquidationPreferencesStyle from './index.style';
import useShareClass from './useShareClass';
import { HigherLevelResponse, HighLevelErrorResponse } from '../../../../../Services/Axios';
import { ForwardedRef } from '../../../../Hooks/useMultiStepForm';

export type LiquidationPreferencesProps = {
	getShareClassRights: ({ waterfallId }: WaterfallDefaultParams) => Promise<void>;
	getShareClassRight: (payload: WaterfallDefaultParams) => Promise<HighLevelErrorResponse | HigherLevelResponse<ShareClassRightResponse>>;
	waterfallId: number;
	shareClasses: ShareClassRight[] | undefined;
	updateClassRight: (payload: ShareClassRightRequest | ShareClassRightIssueRequest) => Promise<void>;
	deleteClassRight: (payload: WaterfallDefaultParams) => Promise<void>;
	timeToExit: number | undefined;
	valuationDate: Date | undefined;
	isViewMode: boolean | undefined;
};

const options: RadioButtonOptions[] = [
	{
		label: 'general.yes',
		value: true,
		name: 'isLiquidation',
		qaid: 'ShareClassRights.Radio.Yes',
	},
	{
		label: 'general.no',
		value: false,
		name: 'isLiquidation',
		qaid: 'ShareClassRights.Radio.No',
	},
];

const LiquidationPreferences = React.forwardRef<ForwardedRef, LiquidationPreferencesProps>((props, forwardedRef) => {
	const { showModal } = useModal();
	const {
		selectedShareClassRight,
		onResetEditHandler,
		onSelectClassHandler,
		isLoading,
		shareClasses,
		classRightsOptions,
		updateClassRightState,
		setShowPariPassuSelection,
		showPariPassuSelection,
		isPariPassuActive,
		onPariPassuChange,
		pariPassu,
		classRightEditId,
		onUpdateClassRightHandler,
		selectedShareClasses,
		tableColumns,
		isRootShareClass,
		formValidationState,
		inputRefs,
		isIssuePriceEmpty,
		isPariPassuDisabled,
		maxDate,
		isViewMode,
		isPariPassuChild,
		isCommonClass,
		shareClassWithoutLiquidation,
		setShareClassWithoutLiquidation,
	} = useShareClass({ ...props, init: true });

	const pointARef = useRef<HTMLTableElement>(null);
	const pointBRef = useRef<HTMLTableElement>(null);

	const scrollToPointA = () => {
		pointARef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
	};

	const scrollToPointB = () => {
		pointBRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			const areClassesAdded = shareClasses?.every((s) => isBoolean(s.isPreferred));

			if (areClassesAdded) {
				return true;
			}

			showModal({
				type: 'warning',
				title: 'Preference data notice',
				body: (
					<>
						<div>Not all preference data has been added yet.</div>
						<div>Please complete all share classes preference data.</div>
					</>
				),
			});
			// updateClassRightState("entitledToRecieveAnnualInterestRate", undefined);
			// updateClassRightState("isCompoundedInterestRate", undefined);
			return false;
		},
	}));

	const isValidWhenIssuePriceEmpty = isBoolean(selectedShareClassRight.isPreferred) && !showPariPassuSelection && selectedShareClassRight.investmentDate;

	const isValidOne = Boolean(
		((selectedShareClassRight.isPreferred && showPariPassuSelection && !!pariPassu.length) || !showPariPassuSelection) &&
			(selectedShareClassRight.entitledToRecieveAnnualInterestRate === false ||
				(selectedShareClassRight.annualInterestRate && isBoolean(selectedShareClassRight.isCompoundedInterestRate))) &&
			formValidationState?.liquidationPreferenceMultiple?.isValid
	);

	const isValidTwo = Boolean(selectedShareClassRight.isPreferred === false && selectedShareClassRight.investmentDate);

	const isFormValid = isIssuePriceEmpty ? isValidWhenIssuePriceEmpty : isValidOne || isValidTwo;

	const isInterestActive =
		selectedShareClassRight.entitledToRecieveAnnualInterestRate &&
		(!isBoolean(selectedShareClassRight.entitledToRecieveAnnualInterestRate) || !isBoolean(selectedShareClassRight.isCompoundedInterestRate));

	const Content = {
		selectedShareClassCommon: (
			<>
				<Hr
					ap={{
						spacing: 'sm',
					}}
				/>
				<DatePickerInput
					className="input-size--main"
					label="Investment date"
					required
					maxDate={maxDate}
					value={selectedShareClassRight.investmentDate ? eliminateTimeZone(selectedShareClassRight.investmentDate) : undefined}
					name="investmentDate"
					onChange={(value, name) => updateClassRightState(name as keyof ShareClassRight, value?.toISOString())}
					qaid="SharesClassRight.Input.InvestmentDate"
					containerStyle={{ maxWidth: '20rem' }}
				/>
			</>
		),
		selectedShareClassNotCommon: (
			<>
				<QuestionCard
					withHrTop
					withHrBottom
					question="The share class has liquidation preference"
					options={options}
					onChange={(value) => {
						if (value || (!value && isIssuePriceEmpty)) {
							updateClassRightState('isPreferred', value);
						} else {
							showModal({
								type: 'confirm',
								title: 'Share Class Liquidation',
								body: 'Are you sure that this share class has no liquidation preference?',
								onCancel: () => {
									updateClassRightState('isPreferred', value);
									if (!value && !shareClassWithoutLiquidation) {
										setShareClassWithoutLiquidation(selectedShareClassRight);
									} else if (value && selectedShareClassRight.shareClassId === shareClassWithoutLiquidation?.shareClassId) {
										setShareClassWithoutLiquidation(undefined);
									}
								},
								confirmButton: {
									label: 'Cancel',
								},
								cancelButton: {
									label: 'Yes',
								},
							});
						}
					}}
					selectedOption={selectedShareClassRight.isPreferred as any}
					isActive={selectedShareClassRight.isPreferred === true}
					isDisabled={isIssuePriceEmpty || (!isRootShareClass && !!selectedShareClassRight.pariPassuId)}
				/>
				<Flex justify="between" align="center">
					<Flex className="align-to-question-card" justify="start" align="center" gap={theme.spacings.md}>
						<DatePickerInput
							className="input-size--main"
							label="Investment date"
							required
							maxDate={maxDate}
							value={selectedShareClassRight.investmentDate ? eliminateTimeZone(selectedShareClassRight.investmentDate) : undefined}
							name="investmentDate"
							onChange={(value, name) => updateClassRightState(name as keyof ShareClassRight, value?.toISOString())}
							qaid="SharesClassRight.Input.InvestmentDate"
						/>

						{!isIssuePriceEmpty && (
							<NumberInput
								label="Preference multiple (x):"
								required
								disabled={!selectedShareClassRight.isPreferred}
								value={selectedShareClassRight.liquidationPreferenceMultiple}
								name="liquidationPreferenceMultiple"
								onChange={(value, name) => updateClassRightState(name as keyof ShareClassRight, value)}
								number="float"
								info="An investor receives X00% of their investment first (for example, 150% to 300%), plus interest. This is extremely uncommon and highly aggressive. This usually applies to troubled companies or companies that demand investments at unreasonably high valuations, and in short periods of time - not leaving time for the investor to get comfortable with the risk"
								qaid="SharesClassRight.Input.LiquidationPreferenceMultiple"
								error={formValidationState?.liquidationPreferenceMultiple?.message}
								ref={(el: InputValidationRef) => (inputRefs.liquidationPreferenceMultiple = el)}
								className="input-size--main"
							/>
						)}
					</Flex>
				</Flex>

				{selectedShareClassRight.isPreferred && pariPassu.length > 0 && (
					<>
						<QuestionCard
							withHrTop
							tooltip={
								<Tooltip title="Pari Passu, or 'Equal Footing' means that two or more parties to a financial contract or claim are all treated the same." />
							}
							isActive={showPariPassuSelection}
							question="Pari-Passu"
							options={options}
							onChange={(value) => setShowPariPassuSelection(value)}
							selectedOption={showPariPassuSelection}
							isDisabled={isPariPassuDisabled}
							disableTooltip={
								isPariPassuChild
									? "This share class can't have a liquidation preference as an upper senior class was defined with no liquidation preference"
									: ''
							}
						/>

						{showPariPassuSelection && (
							<InputGroup>
								<div className="flex align-center">
									<Heading ap={{ tag: 'h3', size: 'md' }}>Pari-Passu Group</Heading>
									<Tooltip title="To remove this class from the Pari Passu group, edit the properties of the most senior class in the group." />
									<PariPassuSelection onChange={onPariPassuChange} isCreate pariPassu={pariPassu} isRoot={isRootShareClass} />
								</div>
							</InputGroup>
						)}

						<Hr
							ap={{
								spacing: 'sm',
							}}
						/>
					</>
				)}

				{selectedShareClassRight.isPreferred && (
					<>
						<Heading
							ap={{
								tag: 'h2',
								size: 'md',
								isBold: true,
							}}
							style={{ marginTop: '5rem' }}
						>
							Interest entitlement *
						</Heading>

						<QuestionCard
							withHrTop
							isActive={selectedShareClassRight.entitledToRecieveAnnualInterestRate === true}
							question="Annual interest / dividend"
							options={options}
							onChange={(value) => updateClassRightState('entitledToRecieveAnnualInterestRate', value)}
							selectedOption={selectedShareClassRight.entitledToRecieveAnnualInterestRate}
							isDisabled={!selectedShareClassRight.isPreferred}
						/>

						<>
							{selectedShareClassRight.entitledToRecieveAnnualInterestRate && (
								<InputGroup>
									<div className="flex justify-between align-center">
										<QuestionCard
											question={'Compounded interest rate?'}
											options={options}
											isDisabled={!selectedShareClassRight.isPreferred || !selectedShareClassRight.entitledToRecieveAnnualInterestRate}
											onChange={(value) => updateClassRightState('isCompoundedInterestRate', value)}
											selectedOption={selectedShareClassRight.isCompoundedInterestRate}
										/>
										<div
											style={{
												width: '100%',
												marginLeft: '10rem',
												transform: 'translateY(-1rem)',
											}}
										>
											<NumberInput
												required
												label="Annual interest rate (%)"
												value={selectedShareClassRight.annualInterestRate as number}
												qaid="SharesClassRight.Input.AnnualInterestRate"
												name="annualInterestRate"
												onChange={(value, name) => updateClassRightState(name as keyof ShareClassRight, value)}
												percentage
												error={formValidationState?.annualInterestRate?.message}
												ref={(el: InputValidationRef) => (inputRefs.annualInterestRate = el)}
												className="input-size--main"
												disabled={
													!selectedShareClassRight?.isPreferred || !selectedShareClassRight?.entitledToRecieveAnnualInterestRate
												}
											/>
										</div>
									</div>
								</InputGroup>
							)}
						</>
						<Hr
							ap={{
								spacing: 'sm',
							}}
						/>
					</>
				)}
			</>
		),
	};

	useEffect(() => {
		if (isNullOrUndefined(classRightEditId)) return;
		setTimeout(scrollToPointA, 100);
	}, [classRightEditId]);

	useEffect(() => {
		// (selectedShareClasses?.length ?? 0) > 1 && scrollToPointB();
	}, [selectedShareClasses]);

	return (
		<WaterfallStep className={LiquidationPreferencesStyle}>
			{isLoading && <Spinner size={36} incorporated center />}
			{!!(classRightsOptions.length || classRightEditId) && (
				<>
					<div className={classNames(`${LiquidationPreferencesStyle}__add-class`, { common: isCommonClass })}>
						<div className="extra" ref={pointARef}>
							<Heading
								ap={{
									tag: 'h2',
									size: 'md',
									isBold: true,
									spacingBottom: 'xs',
								}}
							>
								Share class name
							</Heading>

							<Select
								required
								qaid="ShareClassRight.Select.ShareClass"
								options={classRightsOptions}
								value={selectedShareClassRight.shareClassId as number}
								onChange={(shareClassId) => onSelectClassHandler(shareClassId as number)}
								disabled={isViewMode || !classRightsOptions.length}
								className="input-size--main"
								defaultValue={classRightEditId ? selectedShareClassRight.shareClass : undefined}
							/>
						</div>

						<div className="main">
							<Fade in={!isNullOrUndefined(selectedShareClassRight.shareClassId)} unmountOnExit timeout={2000}>
								<>
									<p style={{ marginBottom: 0, color: COLOR_SANTAS_GRAY }}>
										Please set the liquidation preference parameters per each share class:
									</p>
									<Hr
										ap={{
											spacing: 'sm',
										}}
									/>
									<Heading
										ap={{
											tag: 'h2',
											size: 'md',
											isBold: true,
										}}
									>
										Preference data *
									</Heading>

									{isCommonClass ? Content.selectedShareClassCommon : Content.selectedShareClassNotCommon}

									<Flex justify="end" style={{ marginTop: '4rem' }} gap="1.2rem">
										{!!classRightEditId && (
											<Button qaid="ShareClassRights.Button.Cancel" cancel onClick={onResetEditHandler} label="Cancel" />
										)}
										<Button
											qaid="ShareClassRights.Button.Add"
											label={classRightEditId ? 'Update' : 'Add'}
											onClick={async () => {
												await onUpdateClassRightHandler(!!classRightEditId);
												setTimeout(scrollToPointB, 300);
											}}
											isLoading={isLoading}
											disabled={!isFormValid}
										/>
									</Flex>
								</>
							</Fade>
						</div>
					</div>

					<Hr
						ap={{
							spacing: 'xl',
						}}
					/>
				</>
			)}

			<div className="layout layout--primary layout--primary--fullscreen">
				<div className="layout__main" ref={pointBRef}>
					<Table
						fullscreen
						isStickyHeader={false}
						rows={selectedShareClasses}
						columns={tableColumns}
						border={{ bottom: true }}
						headerSize={2}
						rowSize={1.25}
						fallbackText="No share class was added yet"
						showTotal
						customHeaderRender={<WaterfallTableHeading text="Liquidation preference summary" />}
						rowClassName={(shareClass) => (shareClass.shareClassId && shareClass.shareClassId === classRightEditId ? 'class-right-edit' : '')}
					/>

					{classRightsOptions.length > 0 && (
						<Button
							style={{ marginLeft: 'auto' }}
							className="mt-2"
							qaid="ShareClassRights.Button.AddAnotherClass"
							type="button"
							label="Add another class"
							onClick={() => {
								scrollToPointA();
							}}
						/>
					)}
				</div>
			</div>
		</WaterfallStep>
	);
});

export default observer(LiquidationPreferences);
