import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { LiquidationPreferencesProps } from '.';
import { IC_EDIT2, IC_TRASH2 } from '../../../../../Assets';
import { EditablePariPassu, ShareClassRight } from '../../../../../Models/API/Waterfall/IForm';
import Clickable from '../../../../../Shared/Components/Clickable/Clickable';
import Image from '../../../../../Shared/Components/Image';
import { RadioButtonOptions } from '../../../../../Shared/Components/RadioButton/RadioButton';
import { TableColumn } from '../../../../../Shared/Components/Table/Table';
import { Cell } from '../../../../../Shared/Components/Table/Table.Style';
import { useFormValidation } from '../../../../../Shared/Hooks/useFormValidation';
import useModal from '../../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { commonValidators } from '../../../../../Shared/ObjectValidator';
import { formatDate, formatDecimal, isBoolean, isNullOrUndefined, isNumber } from '../../../../../Shared/Utilities';

const yesNoOptions = [
	{
		label: 'general.yes',
		value: true,
		qaid: 'ShareClassRights.Radio.Yes',
	},
	{
		label: 'general.no',
		value: false,
		qaid: 'ShareClassRights.Radio.No',
	},
];

const pariPassuOptions: RadioButtonOptions[] = yesNoOptions.map((opt) => ({
	...opt,
	name: 'pariPassu',
}));
const investmentOptions: RadioButtonOptions[] = yesNoOptions.map((opt) => ({
	...opt,
	name: 'entitledToRecieveAnnualInterestRate',
}));
const interestOptions: RadioButtonOptions[] = yesNoOptions.map((opt) => ({
	...opt,
	name: 'isCompoundedInterestRate',
}));

type Props = LiquidationPreferencesProps & {
	init: boolean;
};

const useShareClass = ({
	init = false,
	deleteClassRight,
	getShareClassRight,
	getShareClassRights,
	isViewMode,
	shareClasses,
	timeToExit,
	updateClassRight,
	valuationDate,
	waterfallId,
}: Props) => {
	const {
		companyStore: { companyId },
	} = useRootStore();
	const { showModal } = useModal();

	const [selectedShareClassRight, setSelectedShareClassRight] = useState<ShareClassRight>({} as ShareClassRight);

	const [pariPassu, setPariPassu] = useState<EditablePariPassu[]>([] as EditablePariPassu[]);

	const [showPariPassuSelection, setShowPariPassuSelection] = useState<boolean | undefined>(undefined);

	const [classRightEditId, setClassRightEditId] = useState<number | undefined | null>(null);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [shareClassWithoutLiquidation, setShareClassWithoutLiquidation] = useState<ShareClassRight>();

	const isRootShareClass = pariPassu[0]?.seniorityLevel > (selectedShareClassRight.seniorityLevel ?? 0);

	const isIssuePriceEmpty = !selectedShareClassRight.issuePricePerShare;
	const isPariPassuActive = Boolean(
		isRootShareClass && showPariPassuSelection && selectedShareClassRight.isPreferred && !selectedShareClassRight.liquidationPreferenceMultiple
	);
	const isPariPassuChild = !isRootShareClass && !!pariPassu.length;
	const isPariPassuDisabled = isIssuePriceEmpty || isPariPassuChild;

	const valDate = new Date(valuationDate ?? 0);
	const maxDate = !isNullOrUndefined(valuationDate) ? new Date(valDate.setFullYear(valDate.getFullYear() + (timeToExit ?? 0))) : undefined;

	// const inputRefs = useRef<InputRefs<ShareClassRight>>({} as InputRefs<ShareClassRight>);
	const { formValidationState, clearErrors, inputRefs } = useFormValidation({
		form: selectedShareClassRight,
		schema: {
			pariPassuId: [commonValidators.requiredIf(() => !!showPariPassuSelection && isRootShareClass)],
			investmentDate: [commonValidators.required()],
			liquidationPreferenceMultiple: [
				commonValidators.requiredIf(() => !!selectedShareClassRight.issuePricePerShare),
				commonValidators.range2(0, 1000, { includeMin: false, includeMax: true }),
			],
			annualInterestRate: [
				commonValidators.requiredIf(
					() => !!selectedShareClassRight.entitledToRecieveAnnualInterestRate && !!selectedShareClassRight.issuePricePerShare
				),
				commonValidators.range2(0, 100, { includeMin: false, includeMax: true }),
			],
		},
		deps: [selectedShareClassRight],
	});

	const onSelectClassHandler = async (shareClassId: number, isEdit?: boolean) => {
		if (isViewMode) return;
		setIsLoading(true);
		const res = await getShareClassRight({ waterfallId, shareClassId, companyId });
		setIsLoading(false);
		if (!res.isSuccess) return;

		const { pariPassu, shareClass } = res.data;

		const isIssuePriceEmpty = !shareClass.issuePricePerShare;
		const isRootShareClass = !!pariPassu.length && pariPassu[0].seniorityLevel > shareClass.seniorityLevel;

		const isPreferred =
			shareClassWithoutLiquidation && shareClassWithoutLiquidation.seniorityLevel < shareClass.seniorityLevel
				? false
				: isIssuePriceEmpty
				? false
				: isNullOrUndefined(shareClass.isPreferred)
				? true
				: (!isRootShareClass && !!shareClass.pariPassuId) || shareClass.isPreferred;

		if (isIssuePriceEmpty || (isNullOrUndefined(isPreferred) && !pariPassu.length)) {
			setShowPariPassuSelection(false);
		} else if (isBoolean(isPreferred)) {
			setShowPariPassuSelection((isPreferred && !!shareClass.pariPassuId) ?? isPreferred);
		} else {
			setShowPariPassuSelection(undefined);
		}

		// if (shareClassWithoutLiquidation && shareClassWithoutLiquidation.seniorityLevel < selectedShareClassRight.seniorityLevel) {
		//     updateClassRightState("isPreferred", false);
		// }

		if (pariPassu.length) {
			const lastActiveIdx = pariPassu.map((pp) => pp.isSelected).lastIndexOf(true);

			setPariPassu(
				pariPassu.map((p, idx: number) => ({
					...p,
					isEditable: !!idx && isRootShareClass && (isEdit && lastActiveIdx !== -1 ? lastActiveIdx === idx : idx === 1),
					// isSelected: !idx || (isEdit ? p.isSelected : !isRootShareClass),
					isSelected: idx ? p.isSelected : true,
				}))
			);
		} else setPariPassu([]);

		const isPreferedDeps = {
			liquidationPreference: undefined,
			entitledToRecieveAnnualInterestRate: undefined,
			isCompoundedInterestRate: undefined,
			liquidationPreferenceMultiple: 1,
			annualInterestRate: undefined,
		};

		isNullOrUndefined(isPreferred) && clearErrors();

		setSelectedShareClassRight({
			...shareClass,
			isPreferred,
			pariPassuId: isIssuePriceEmpty ? null : shareClass.pariPassuId,
			...(isNullOrUndefined(isPreferred) ? isPreferedDeps : {}),
			liquidationPreferenceMultiple: isEdit ? shareClass.liquidationPreferenceMultiple : 1,
		});
		if (!isEdit) setClassRightEditId(undefined);
		// if (!isRootShareClass) return setPariPassu(res.pariPassu);
	};

	const onResetEditHandler = () => {
		setSelectedShareClassRight({} as ShareClassRight);
		setClassRightEditId(undefined);
	};

	useEffect(() => {
		if (!init || classRightEditId) return;

		const fetchData = async () => {
			await getShareClassRights({ waterfallId, companyId });
		};

		fetchData();
	}, [classRightEditId]);

	useEffect(() => {
		if (selectedShareClassRight.isPreferred === false && classRightEditId === null) {
			// class right === null means no classright is being selected (undefined state is between selected to render after API)
			setShowPariPassuSelection(undefined);
			setSelectedShareClassRight((prevState) => ({
				...prevState,
				liquidationPreference: 0,
				entitledToRecieveAnnualInterestRate: undefined,
				isCompoundedInterestRate: undefined,
			}));
		}
	}, [selectedShareClassRight.isPreferred, classRightEditId]);

	useEffect(() => {
		if (selectedShareClassRight.entitledToRecieveAnnualInterestRate === false && selectedShareClassRight.isPreferred) {
			setSelectedShareClassRight((prevState) => ({
				...prevState,
				isCompoundedInterestRate: classRightEditId !== null ? false : undefined,
				annualInterestRate: 0,
			}));
			inputRefs.annualInterestRate?.clear(); // clear internal value of annual interest rate (%)
		}
	}, [selectedShareClassRight.entitledToRecieveAnnualInterestRate]);

	// useEffect(() => {
	//   console.log(selectedShareClassRight);
	// }, [selectedShareClassRight]);

	const updateClassRightState = (name: keyof ShareClassRight, value: any) => {
		if (isNullOrUndefined(name)) return;
		setSelectedShareClassRight((state) => ({ ...state, [name]: value }));
	};

	const classRightsOptions = useMemo(() => {
		if (isNullOrUndefined(shareClasses)) return [];

		return shareClasses
			.filter((classRight) => classRight.isPreferred === null)
			.map((classRight) => ({
				label: classRight.shareClass,
				value: classRight.shareClassId as number,
			}));
	}, [shareClasses]);

	const selectedShareClasses = useMemo(() => {
		return shareClasses?.filter((classRight) => classRight.isPreferred !== null);
	}, [shareClasses]);

	useEffect(() => {
		if (!classRightsOptions?.length || !isNullOrUndefined(selectedShareClassRight.shareClassId) || isViewMode) return;
		onSelectClassHandler(classRightsOptions[0].value as number);
	}, [classRightsOptions]);

	const pariPassuIdsArray = Array.from(new Set(selectedShareClasses?.map((i) => i.pariPassuId).filter((i) => isNumber(i))));

	const resetData = async (init?: boolean) => {
		setSelectedShareClassRight({} as ShareClassRight);
		setClassRightEditId(null);
		setShowPariPassuSelection(undefined);
		init && (await getShareClassRights({ waterfallId, companyId }));
	};

	const tableColumns: TableColumn<ShareClassRight>[] = [
		{
			name: 'seniorityLevelDisplay',
			label: 'Preference Level',
		},
		{
			name: 'shareClass',
			label: 'Share Class',
			format(val) {
				return val === 'Total' ? '' : val;
			},
		},
		{
			name: 'pariPassuId',
			label: 'Pari Passu',
			format(val) {
				return val ? '+'.repeat(pariPassuIdsArray.findIndex((id) => id === val) + 1) : '-';
			},
		},
		{
			name: 'investmentDate',
			label: 'Investment Date',
			render(obj: any, value: any) {
				return <Cell>{!!value && formatDate(value)}</Cell>;
			},
		},
		{
			name: 'liquidationPreference',
			label: 'Original Investment ($M)',
			format(val, obj) {
				return formatDecimal(val, { decimalLength: 3 });
			},
		},
		{
			name: 'liquidationPreferenceMultiple',
			label: 'Multiple',
		},
		{
			name: 'isCompoundedInterestRate',
			label: 'Compounded Interest',
			render(obj, value) {
				return <Cell>{value ? 1 : 0}</Cell>;
			},
		},
		{
			name: 'annualInterestRate',
			label: 'Annual Interest Rate (%)',
			render(obj, value) {
				return <Cell>{value || '-'}</Cell>;
			},
		},
		{
			name: 'totalLiquidationPreference',
			label: 'Total Liquidation Preference ($M)',
			format(val, obj) {
				return formatDecimal(val, { decimalLength: 3 });
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			render: (shareClassRight) => (
				<>
					<Clickable
						className={classNames('open', { disabled: isViewMode })}
						onClick={async (e) => {
							e.stopPropagation();
							if (isViewMode || shareClassRight.shareClassId === classRightEditId) return;

							setClassRightEditId(undefined);
							// setSelectedShareClassRight({} as ShareClassRight)
							await onSelectClassHandler(shareClassRight.shareClassId as number, true);
							setClassRightEditId(shareClassRight.shareClassId as number);
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}}
						qaid={`ShareClassRights.Button.Edit-${shareClassRight.shareClassId}`}
						justify="start"
					>
						<Image src={IC_EDIT2} width="2rem" alt="Edit" />
					</Clickable>
					<Clickable
						className={classNames('delete', { disabled: isViewMode })}
						onClick={(e) => {
							e.stopPropagation();
							if (isViewMode || shareClassRight.shareClassId === classRightEditId) return;
							onDelete(shareClassRight.shareClassId as number);
						}}
						qaid={`ShareClassRights.Button.Delete-${shareClassRight.shareClassId}`}
						justify="start"
					>
						<Image src={IC_TRASH2} width="2rem" alt="Delete" />
					</Clickable>
				</>
			),
		},
	];

	const onPariPassuChange = (pariPassuId: number, isSelected: boolean) => {
		setPariPassu((prevState) => {
			const pariPassuIdx = prevState.findIndex((p) => p.shareClassId === pariPassuId);
			const treshholdValue = isSelected ? 1 : -1;
			return prevState.map((p: EditablePariPassu, idx: number) => {
				return {
					...p,
					isEditable: idx > 0 && (idx === pariPassuIdx || idx === pariPassuIdx + treshholdValue),
					isSelected: idx === 0 || idx < pariPassuIdx || (isSelected && idx === pariPassuIdx),
				};
			});
		});
	};

	const onDelete = async (shareClassId: number) => {
		if (isViewMode) return;
		const className = shareClasses?.find((sh) => sh.shareClassId === shareClassId)?.shareClass;
		showModal({
			type: 'confirm',
			body: <>Are you sure that you want to delete the share class right "{className}"?</>,
			confirmButton: {
				label: 'general.delete',
			},
			title: 'general.warning',
			onConfirm: () => deleteClassRight({ shareClassId, waterfallId, companyId }),
			isMust: true,
		});
	};

	const onUpdateClassRightHandler = async (isEdit?: boolean) => {
		const {
			liquidationPreferenceMultiple,
			annualInterestRate,
			entitledToRecieveAnnualInterestRate,
			isCompoundedInterestRate,
			isPreferred,
			investmentDate,
			shareClassId,
			issuePricePerShare,
		} = selectedShareClassRight;

		try {
			setIsLoading(true);
			const receiveAnnual = {
				annualInterestRate,
				isCompoundedInterestRate: !!isCompoundedInterestRate,
			};
			const isEntitledReceiveAnnual = isEdit ? !!annualInterestRate : entitledToRecieveAnnualInterestRate;
			const issuePricePaylod = {
				liquidationPreferenceMultiple,
				parriPassu: showPariPassuSelection && isRootShareClass ? (pariPassu.filter((p) => p.isSelected).map((p) => p.shareClassId) as number[]) : null,
				entitledToRecieveAnnualInterestRate: isEntitledReceiveAnnual,
				...(isEntitledReceiveAnnual ? receiveAnnual : {}),
			};
			await updateClassRight({
				waterfallId,
				companyId,
				shareClassId: shareClassId as number,
				investmentDate: new Date(investmentDate),
				isPreferred: isPreferred as boolean,
				...(issuePricePerShare ? issuePricePaylod : {}),
			});
			await resetData(true);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		selectedShareClassRight,
		setSelectedShareClassRight,
		onResetEditHandler,
		onSelectClassHandler,
		isLoading,
		shareClasses,
		classRightsOptions,
		updateClassRightState,
		setShowPariPassuSelection,
		showPariPassuSelection,
		isPariPassuActive,
		onPariPassuChange,
		pariPassu,
		classRightEditId,
		onUpdateClassRightHandler,
		selectedShareClasses,
		setClassRightEditId,
		tableColumns,
		isRootShareClass,
		formValidationState,
		inputRefs,
		isIssuePriceEmpty,
		isPariPassuDisabled,
		maxDate,
		isViewMode,
		isPariPassuChild,
		isCommonClass: selectedShareClassRight.shareClass === 'Common',
		shareClassWithoutLiquidation,
		setShareClassWithoutLiquidation,
	};
};

export default useShareClass;
