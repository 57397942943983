export enum SectorEnum {
	SFTCS,
	TECHD,
	TELEQ,
	TELSP,
	HLTPR,
	MEDES,
	PHARM,
	BANKS,
	FINCS,
	INVBB,
	RITMG,
	CEINV,
	OMINV,
	LFINS,
	NLINS,
	RLISV,
	REITS,
	AUTMB,
	CNSVS,
	HHOLD,
	LEISG,
	PERSG,
	MEDIA,
	RTAIL,
	TRLES,
	BEVES,
	FOODS,
	TOBAC,
	PCDGS,
	CNSTM,
	AERSP,
	ELTNC,
	GNIND,
	INDEN,
	SUPSV,
	INDTR,
	INMAT,
	INDMT,
	PRCMM,
	CHMCL,
	OILGC,
	ALTEN,
	ELECT,
	GWMUT,
	WASTE,
}
