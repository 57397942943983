import { css } from '@emotion/css';
import { RiskFreeRate } from '../../../../../../Models/API/Waterfall/IForm';
import appConfig from '../../../../../../config/config';
import { formatDate, formatDecimal } from '../../../../../../Shared/Utilities';

type Props = {
	data?: RiskFreeRate[];
};

const Style = css({
	label: 'ReviewRfr',
	background: appConfig.style.colors.lightBg,
	borderRadius: 8,
	border: `1px solid ${appConfig.style.colors.field}`,
	padding: '1.2rem',
	display: 'flex',
	flexDirection: 'column',
	'&__info': {
		display: 'flex',
		flexDirection: 'column',
		'.valuation-date': {
			color: appConfig.style.colors.text2,
			marginBottom: '2rem',
			marginTop: '0.8rem',
		},
	},
	'&__data-container': {
		display: 'flex',
		overflow: 'auto',
	},
	'&__data': {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		whiteSpace: 'nowrap',
		span: {
			'&:first-child': {
				borderBottom: `1px solid ${appConfig.style.colors.field}`,
				paddingBottom: '1.2rem',
				paddingInline: '0.8rem',
			},
			'&:last-child': {
				paddingTop: '1.2rem',
				paddingInline: '0.8rem',
			},
		},
		'&.title': {
			fontWeight: 500,
			flexBasis: '6rem',
		},
	},
});

const ReviewRfr = ({ data }: Props) => {
	return (
		<div className={Style}>
			{data ? (
				<>
					<div className={`${Style}__info`}>
						<span>Market Yield on U.S Treasury Securities:</span>
						<span className="valuation-date">Valuation Date: {formatDate(data[0].valuationDate)}</span>
					</div>
					<div className={`${Style}__data-container`}>
						<div className={`${Style}__data title`}>
							<span>Time Period</span>
							<span>Yield (% annual basis)</span>
						</div>
						<div className={`${Style}__data`}>
							<span>3 - Months</span>
							<span>{formatDecimal(data[0].expectedTerm)}</span>
						</div>
						<div className={`${Style}__data`}>
							<span>6 - Months</span>
							<span>{formatDecimal(data[1].expectedTerm)}</span>
						</div>
						<div className={`${Style}__data`}>
							<span>1 - Year</span>
							<span>{formatDecimal(data[2].expectedTerm)}</span>
						</div>
						<div className={`${Style}__data`}>
							<span>3 - Years</span>
							<span>{formatDecimal(data[3].expectedTerm)}</span>
						</div>
						<div className={`${Style}__data`}>
							<span>5 - Years</span>
							<span>{formatDecimal(data[4].expectedTerm)}</span>
						</div>
						<div className={`${Style}__data`}>
							<span>7 - Years</span>
							<span>{formatDecimal(data[5].expectedTerm)}</span>
						</div>
						<div className={`${Style}__data`}>
							<span>10 - Years</span>
							<span>{formatDecimal(data[6].expectedTerm)}</span>
						</div>
					</div>
				</>
			) : (
				<div>No data</div>
			)}
		</div>
	);
};

export default ReviewRfr;
