import { action, makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { EquityValueEnum } from '../Models/API/Valuation/equity-value-enum';
import { GeneralFormRequest } from '../Models/API/Valuation/general-request';
import {
	CapTableRequest,
	CreateProjectRequest,
	PayoffSelectionOptions,
	PeerSearchParams,
	PreferenceShareClassRequest,
	PreferenceShareClassRight,
	ShareClassRight,
	ShareClassRightIssueRequest,
	ShareClassRightRequest,
	ToggleReviewerRequest,
	UploadOptionsRequest,
	VolatilityTableData,
	VolatilityTablePreviewData,
	WaterfallAwardType,
	WaterfallDefaultParams,
	WaterfallProject,
} from '../Models/API/Waterfall/IForm';
import { GeneralForm } from '../Models/App/Valuation/general-form';
import { ValuationService } from '../Services/ValuationService';
import { isBoolean, isNullOrUndefined } from '../Shared/Utilities';
import { RootStore } from './RootStore';

export enum ValuationFormSteps {
	general = 0,
	volatility = 1,
	capTable = 2,
	optionsPlan = 3,
	shareClasses = 4,
	preferencesTerms = 5,
	summary = 6,
}

const DEFAULT_GENERAL_FORM = { equityType: EquityValueEnum.AccordingToLastInvestmentRound } as GeneralForm;

export default class ValuationStore {
	projectName: string | undefined = undefined;
	protected valuationService: ValuationService;
	waterfallId: number = 0;
	lastAllowedStep: number = 0;
	originalValuationDate: Date | undefined = undefined;
	shareClasses?: ShareClassRight[] = undefined;
	preferenceShareClasses?: PreferenceShareClassRight[] = undefined;
	isViewMode: boolean | undefined = undefined;
	isNewProject: boolean = false;
	isOptionsAllowed: boolean = true;
	projects?: WaterfallProject[] | null = null;
	generalForm: GeneralForm = DEFAULT_GENERAL_FORM;

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this, {
			resetForm: action,
			setGeneralForm: action,
			// getProjects: action,

			// step 6:
			// setSummary: action,
			// setIsShowError: action,
		});
		makePersistable(this, {
			name: 'ValuationStore',
			properties: ['generalForm', 'waterfallId', 'lastAllowedStep', 'projects', 'isViewMode', 'projectName', 'originalValuationDate'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});

		this.valuationService = new ValuationService();
	}

	get waterfallProjects() {
		return this.projects as WaterfallProject[];
	}

	get currentWaterfallId() {
		return this.waterfallId;
	}

	set currentWaterfallId(id: number) {
		this.waterfallId = id;
	}

	setProjectViewMode(isViewMode: boolean | undefined) {
		this.isViewMode = isViewMode;
	}

	setProjectName(name: string | undefined) {
		this.projectName = name;
	}

	setGeneralForm = (form: GeneralForm) => {
		this.generalForm = form;
	};

	resetWaterfallProject() {
		this.waterfallId = 0;
		this.setProjectName(undefined);
		this.lastAllowedStep = 0;
		// this.originalValuationDate = undefined;
		// this.projects = null;
		// this.summaryData = null;
		this.resetForm();
		this.generalForm = DEFAULT_GENERAL_FORM;
		this.shareClasses = undefined;
		this.preferenceShareClasses = undefined;
		// ui related observables:
		this.isViewMode = undefined;
		this.isNewProject = false;
		this.isOptionsAllowed = false;
	}

	resetStoreToDefaultValues = () => {
		this.isNewProject = false;
		clearPersistedStore(this);
	};

	resetForm = () => {
		this.generalForm = DEFAULT_GENERAL_FORM;
		this.waterfallId = 0;
		this.lastAllowedStep = 0;
		this.setProjectViewMode(undefined);
		this.setProjectName(undefined);
	};

	clearProjects = () => (this.projects = null);

	getProjects = async (companyId: number = this.rootStore.companyStore.companyId) => {
		const res = await this.valuationService.getProjects(companyId);
		this.projects = res.data;
		return res;
	};

	deleteProject = async (waterfallId: number) => {
		const res = await this.valuationService.deleteProject(waterfallId);
		if (res.error) return;

		this.projects = this.projects?.filter((project) => project.waterfallId !== waterfallId);
	};

	getProject = async (waterfallId: number) => {
		const res = await this.valuationService.getProject(waterfallId);
		if (isNullOrUndefined(res.data)) return res;

		const { projectName, valuationDate, maxStep } = res.data;
		this.originalValuationDate = valuationDate;
		this.setGeneralForm(res.data);
		// if doesnt need the project return a promise
		// this.updateCurrentStep(step);
		this.setProjectName(projectName);
		this.setLastAllowedStep(maxStep);

		return res;
	};

	setLastAllowedStep = (step: number = ValuationFormSteps.general) => {
		step -= 1;

		// this.lastAllowedStep = step === ValuationFormSteps.summary && this.summaryData ? 7 : step;
		this.lastAllowedStep = step;
	};

	duplicateProject = async (payload: CreateProjectRequest) => {
		return this.valuationService.duplicateProject(payload);
	};

	getSteps = async (waterfallId: number = this.waterfallId, companyId: number = this.rootStore.companyStore.companyId): Promise<void> => {
		const res = await this.valuationService.getSteps({ waterfallId, companyId });
		if (isNullOrUndefined(res.data)) return;

		this.isOptionsAllowed = res.data.isOptionsAllowed;
		this.setLastAllowedStep(res.data.maxStep);
	};

	// step 1

	createProject = async (payload: CreateProjectRequest) => {
		const res = await this.valuationService.createProject(payload);
		if (isNullOrUndefined(res.data)) return res;

		this.setLastAllowedStep(res.data.unfulfillmentStep);
		this.waterfallId = res.data.waterfallId;
		this.setProjectName(payload.projectName);
		this.getSteps();
	};

	getProjectReviewers = (payload: WaterfallDefaultParams) => {
		return this.valuationService.getProjectReviewers(payload);
	};

	toggleProjectReviewer = (payload: ToggleReviewerRequest) => {
		return this.valuationService.toggleProjectReviewer(payload);
	};

	updateScenarioStep = async (payload: GeneralFormRequest) => {
		const res = await this.valuationService.updateScenarioStep(payload);
		if (isNullOrUndefined(res.data)) return res;

		this.setLastAllowedStep(res.data.unfulfillmentStep);
		this.originalValuationDate = payload.valuationDate;
		return res;
	};

	getRiskFreeRate = (valuationDate: Date) => {
		return this.valuationService.getRiskFreeRate(valuationDate);
	};

	// step 2

	gerAvailablePeers(params?: PeerSearchParams) {
		return this.valuationService.gerAvailablePeers(params);
	}

	getPeers(waterfallId: number = this.waterfallId) {
		return this.valuationService.getPeers(waterfallId);
	}

	getAddedPeers(waterfallId: number = this.waterfallId) {
		return this.valuationService.getAddedPeers(waterfallId);
	}

	calculateAddedPeers(data: VolatilityTablePreviewData) {
		return this.valuationService.calculateAddedPeers(data);
	}

	submitAddedPeers(data: VolatilityTablePreviewData) {
		return this.valuationService.submitAddedPeers(data);
	}

	// step 3

	getCapTableBases = async (companyId: number) => {
		const res = await this.valuationService.getCapTableBases(companyId);
		if (!res.data || isNullOrUndefined(res.data?.projectsUserList)) return res;

		return res;
	};

	// get a snapshot of a captable base (result might not be updated with the latest captable):
	getSavedCapTableBase = async (companyId: number, waterfallId: number) => {
		const res = await this.valuationService.getSavedCapTableBase(companyId, waterfallId);
		if (isNullOrUndefined(res.data)) return res;

		const isShowSelect = res.data.captableImportDate === null;
		// this.setShowSelectImportSource(isShowSelect);
		return res;
	};

	// get captable base directly from captable module:
	getSingleCapTableData = async (payload: CapTableRequest) => {
		const res = await this.valuationService.getSingleCapTableData(payload);

		if (res.data) {
			this.getSteps();
		}
		return res;
	};

	// step 4

	uploadOptions = async (payload: UploadOptionsRequest) => {
		const res = await this.valuationService.uploadOptions(payload);
		this.getSteps();
		return res;
	};

	reimportOptions = (companyId: number = this.rootStore.companyStore.companyId, waterfallId: number = this.waterfallId) => {
		return this.valuationService.reimportOptions(companyId, waterfallId);
	};

	getOptions = (waterfallId: number = this.waterfallId) => {
		return this.valuationService.getOptions(waterfallId);
	};

	updateOptionsMode = (payload: UploadOptionsRequest) => {
		return this.valuationService.updateOptionsMode(payload);
	};

	getValuationDate = (waterfallId: number = this.waterfallId) => {
		return this.valuationService.getValuationDate(waterfallId);
	};

	addAwardType = (data: WaterfallAwardType, waterfallId: number = this.waterfallId) => {
		return this.valuationService.addAwardType(data, waterfallId);
	};

	updateAwardType = (data: WaterfallAwardType, waterfallId: number = this.waterfallId) => {
		return this.valuationService.updateAwardType(waterfallId, data);
	};

	deleteAwardType = (shareClassId: number, waterfallId: number = this.waterfallId) => {
		return this.valuationService.deleteAwardType(waterfallId, shareClassId);
	};

	// step 5

	getShareClassRights = async ({ waterfallId }: WaterfallDefaultParams) => {
		const res = await this.valuationService.getShareClassRights(waterfallId);
		this.shareClasses = res.data?.shareClasses;
	};

	getShareClassRight = async (payload: WaterfallDefaultParams) => {
		return this.valuationService.getShareClassRight(payload);
	};

	updateClassRight = async (payload: ShareClassRightRequest | ShareClassRightIssueRequest) => {
		const res = await this.valuationService.updateClassRight(payload);
		if (!res.isSuccess) return;

		this.updateClassRightValue(payload.shareClassId, 'isPreferred', true);
		const areClassesAdded = this.shareClasses?.every((s) => isBoolean(s.isPreferred));
		areClassesAdded && this.getSteps();
	};

	deleteClassRight = async (payload: WaterfallDefaultParams): Promise<void> => {
		const res = await this.valuationService.deleteClassRight(payload);
		if (!res.isSuccess) return;

		const areClassesAdded = this.shareClasses?.every((s) => isBoolean(s.isPreferred));
		areClassesAdded && this.getSteps();
		this.updateClassRightValue(payload.shareClassId as number, 'isPreferred', null);
	};

	updateLastModified(payload: WaterfallDefaultParams) {
		return this.valuationService.updateLastModified(payload);
	}

	private updateClassRightValue = (shareClassId: number, key: string, value: any) => {
		if (isNullOrUndefined(this.shareClasses)) return;
		const shareClassIdx = this.shareClasses.findIndex((sc) => sc.shareClassId === shareClassId);
		this.shareClasses = [
			...this.shareClasses.slice(0, shareClassIdx),
			{
				...this.shareClasses[shareClassIdx],
				[key]: value,
			},
			...this.shareClasses.slice(shareClassIdx + 1),
		];
	};

	// step 6

	getPreferenceClassRights = async ({ waterfallId }: WaterfallDefaultParams) => {
		const res = await this.valuationService.getPreferenceClassRights(waterfallId);
		if (!res.isSuccess) return;

		this.preferenceShareClasses = res.data;
	};

	addPreferenceClassRight = async (payload: PreferenceShareClassRequest) => {
		// await new Promise(res => setTimeout(res, 2000));
		const res = await this.valuationService.addPreferenceClassRight(payload);
		if (!res.isSuccess) return;

		this.getSteps();
		if (isNullOrUndefined(this.preferenceShareClasses)) return;
		const scIdx = this.preferenceShareClasses.findIndex((sc) => sc.shareClassId === payload.shareClassId);
		this.preferenceShareClasses = [
			...this.preferenceShareClasses.slice(0, scIdx),
			{
				...this.preferenceShareClasses[scIdx],
				...res.data,
			},
			...this.preferenceShareClasses.slice(scIdx + 1),
		];
	};

	deletePreferenceClassRight = async (payload: WaterfallDefaultParams) => {
		// await new Promise(res => setTimeout(res, 2000));
		await this.valuationService.deletePreferenceClassRight(payload);
		this.getSteps();
		if (isNullOrUndefined(this.preferenceShareClasses)) return;
		const scIdx = this.preferenceShareClasses.findIndex((sc) => sc.shareClassId === payload.shareClassId);
		this.preferenceShareClasses = [
			...this.preferenceShareClasses.slice(0, scIdx),
			{
				...this.preferenceShareClasses[scIdx],
				shareClassPreferenceTermsTypeId: PayoffSelectionOptions.None,
			},
			...this.preferenceShareClasses.slice(scIdx + 1),
		];
	};

	updatePreferenceClassRights = async (payload: WaterfallDefaultParams) => {
		return this.valuationService.updatePreferenceClassRights(payload);
	};
}
