import styled from "@emotion/styled";
import appConfig from "../../../config/config";
import { CheckBoxProps } from "./CheckBox";
import { css } from "@emotion/css";
import { IC_CHECK_SECONDARY, IC_CHECK_WHITE2 } from "../../../Assets";

export const CheckBoxCss = css({
	label: 'CheckBoxWrapper',
	display: 'flex',
	alignItems: 'center',
	minWidth: '10rem',
	height: 'fit-content',
	'&:not(:last-child)': {
		// marginBottom: 10,
	},
	'&__label': {
		order: 99,
		paddingLeft: '1rem',
		userSelect: 'none',
		alignSelf: 'center',
	},
	'&.disabled': {
		opacity: 0.6,
		cursor: 'not-allowed',
	},
	'&__mt-10': {
		marginTop: '1rem',
	},
});

const StyledCheckBox = styled.div(
	{
		label: 'CheckBox',
		position: 'relative',
		transition: 'all .05s',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	({ isChecked, size = '2.8rem', type = 'primary' }: Partial<CheckBoxProps>) => ({
		width: type === 'secondary' ? '2.4rem' : size,
		height: type === 'secondary' ? '2.4rem' : size,
		minWidth: type === 'secondary' ? '2.4rem' : size,
		minHeight: type === 'secondary' ? '2.4rem' : size,
		borderRadius: type === 'secondary' ? '0.4rem' : '0.8rem',
		background: type === 'secondary' ? '#ffffff' : isChecked ? appConfig.style.colors.gradientPurpleVertical : '#ffffff',
		boxShadow: type === 'secondary' ? '1px 1px 1px 0px rgba(217, 217, 217, 0.50)' : appConfig.style.boxShadow.light,
		border: `${type === 'secondary' && isChecked ? 3 : 1}px solid ${
			type === 'secondary' ? (isChecked ? appConfig.style.colors.text2 : appConfig.style.colors.table) : appConfig.style.colors.color1
		}`,
		'.check': {
			display: 'inline-block',
			transform: 'rotate(45deg)',
			height: type === 'secondary' ? '1.2rem' : '1.6rem',
			width: type === 'secondary' ? '0.6rem' : '0.8rem',
			opacity: isChecked ? 1 : 0,
			borderBottom: `2px solid ${type === 'secondary' ? appConfig.style.colors.background2 : '#ffffff'}`,
			borderRight: `2px solid ${type === 'secondary' ? appConfig.style.colors.background2 : '#ffffff'}`,
			marginBottom: '0.3rem',
		},
	})
);

export default StyledCheckBox;
