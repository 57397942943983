import { Fade } from "@mui/material";
import classNames from "classnames";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { IC_TRASH2 } from "../../../../../Assets";
import { NumberOption } from "../../../../../Models/API/All/NumberOption";
import { AvailableAmountsResultInfo } from "../../../../../Models/API/CapTable/available-amounts-result-info";
import { CapTableFileModel } from "../../../../../Models/API/CapTable/file-model";
import { ShareClassInfo } from "../../../../../Models/API/CapTable/share-class-info";
import { AntiDilitedModeEnum, FileStatusOverrideOptions } from "../../../../../Models/API/enums";
import Button from "../../../../../Shared/Components/Button/Button";
import CheckBox from "../../../../../Shared/Components/CheckBox/CheckBox";
import DatePickerInput from "../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import Title from "../../../../../Shared/Components/Layout/Title";
import Menu from "../../../../../Shared/Components/Menu/index";
import RadioButton from "../../../../../Shared/Components/RadioButton/RadioButton";
import Tooltip from "../../../../../Shared/Components/Tooltip";
import { InputRefs, InputValidationRef } from "../../../../../Shared/Hooks/useFormValidation";
import useModal from "../../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import {
    addDaysToDate,
    fileToBase64,
    formatNumber,
    getFileExtension,
    getFileNameWithoutExtension,
    isNullOrUndefined,
    isNumber,
    multiplyNumbers,
} from "../../../../../Shared/Utilities";
import useShareClassValidation from "../../../Hooks/Validations/useShareClassValidation";
import { AddEditShareClassProps } from "../../../types";
import { AddEditShareClassStyle } from "./AddEditShareClass.style";

const AddEditShareClass = ({
    onAdd,
    shareClassInfo,
    isViewMode,
    removeModal,
    getAvailableAmounts,
    onDelete,
    isImport,
}: AddEditShareClassProps) => {
    const isEdit = !!shareClassInfo?.shareClassID;
    const { currency } = useRootStore();
    const { showAsyncModal } = useModal();
    const [shareClass, setShareClass] = useState<ShareClassInfo>({} as ShareClassInfo);
    const [isAntiDilution, setIsAntiDilution] = useState<boolean>(false);
    const [isConversion, setIsConversion] = useState<boolean>(false);
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [availableAmounts, setAvailableAmounts] = useState<AvailableAmountsResultInfo>({} as AvailableAmountsResultInfo);
    const [warnings, setWarnings] = useState<NumberOption[]>([]);
    const { showModal } = useModal();

    const inputRefs = useRef<InputRefs<ShareClassInfo>>({} as InputRefs<ShareClassInfo>);

    const { formValidationState, validateForm, setFormValidationState, initialValidationState } = useShareClassValidation(
        shareClass,
        availableAmounts,
        isConversion,
        isAntiDilution,
        inputRefs.current,
        false
    );

    useEffect(() => {
        if (isNullOrUndefined(shareClassInfo)) return;

        setShareClass({ ...shareClassInfo, documents: undefined });
        setIsAntiDilution(isNumber(shareClassInfo.antiDilutedModeID));
        setIsConversion(isNumber(shareClassInfo.convertionTypeExID));

        if (isImport) {
            setFormValidationState({ ...formValidationState, roundDate: { isValid: true }, issuePricePerShare: { isValid: true } });
        }

        (async () => {
            // if (isImport) return;
            const res = await getAvailableAmounts(shareClassInfo.shareClassID, new Date());
            setAvailableAmounts(res);
        })();

        return () => {
            setAvailableAmounts({} as AvailableAmountsResultInfo);
            setIsConversion(false);
            setIsAntiDilution(false);
            setIsLoading(false);
            setWarnings([]);
            setShareClass({} as ShareClassInfo);
        };
    }, [shareClassInfo]);

    useEffect(() => {}, [shareClass.shareClassID]);

    const onInputHandler = (value?: string | number, name?: string) => {
        if (isNullOrUndefined(name)) return;
        setShareClass((prevState) => ({ ...prevState, [name]: value }));
    };

    const investmentAmount = formatNumber(multiplyNumbers(shareClass?.numberOfSharesOutstanding, shareClass?.issuePricePerShare), false);

    const onFileUpload = async (file: File) => {
        const data = await fileToBase64(file);
        const document = {
            fileData: data,
            fileName: getFileNameWithoutExtension(file.name),
            folderId: 0,
            fileSignature: getFileExtension(file.name),
            action: FileStatusOverrideOptions.Add,
        };
        setShareClass((prevState) => ({ ...prevState, documents: prevState.documents ? [...prevState.documents, document] : [document] }));
    };

    const onFileDelete = async (file: CapTableFileModel) => {
        setShareClass((prevState) => {
            if (isNullOrUndefined(prevState.documents)) return prevState;

            const docIdx = prevState.documents.findIndex(
                (doc) => doc.fileName === file.fileName || (!isNullOrUndefined(doc.id) && doc.id === file.id)
            );
            if (docIdx === -1) return prevState;

            return {
                ...prevState,
                documents: [
                    ...prevState.documents.slice(0, docIdx),
                    {
                        ...prevState.documents[docIdx],
                        action: FileStatusOverrideOptions.Remove,
                    },
                    ...prevState.documents.slice(docIdx + 1),
                ],
            };
        });
    };

    const onSubmit = async (e: SyntheticEvent) => {
        e.stopPropagation();
        const isValid = validateForm();
        if (!isValid) return;

        let isApproved = true;

        if (warnings.length && !isImport) {
            isApproved = await showAsyncModal({
                type: "confirm",
                title: "Warning",
                body: () => (
                    <Flex
                        direction="column"
                        gap="2rem"
                    >
                        {warnings.map((warning) => (
                            <div key={warning.value}>{warning.label}</div>
                        ))}
                    </Flex>
                ),
                onCancel: () => (isApproved = false),
                confirmButton: {
                    label: "Proceed",
                },
                cancelButton: {
                    label: "Discard changes",
                },
            });
        }

        if (!isApproved) return;

        setIsLoading(true);
        await onAdd(shareClass);
        setIsLoading(false);
        removeModal?.();
        if (!isEdit) {
            showModal({
                type: "success",
                body: "The share class was successfully added",
                qaid: "Shareclass.Modal.Add",
                width: "44.5rem",
                showProgressBar: false,
                timeout: 1500,
            });
        }

        // setIsLoading(true);
        // setIsLoading(false);
    };

    const onSetConversion = (value: number | null) => {
        if (shareClass.convertionTypeExID === value) return;
        setShareClass((prevShareClass) => ({ ...prevShareClass, convertionValueEx: null, convertionTypeExID: value }));
    };

    const onDeleteShareholder = async () => {
        showModal({
            type: "delete",
            title: "Delete this share class",
            body: (
                <>
                    <div style={{ marginBottom: "0.4rem" }}>Are you sure you want to delete this share class?</div>
                    <div className="bold">{shareClass.shareClass}</div>
                </>
            ),
            onConfirm: async () => {
                await onDelete?.(shareClass.shareClassID);
                removeModal?.();
            },
        });
    };

    return (
        <div className={classNames(AddEditShareClassStyle, { import: isImport })}>
            {!isImport && (
                <Flex
                    justify="between"
                    margin="0 0 3.6rem 0"
                    flex={0}
                >
                    <Title className={`${AddEditShareClassStyle}__title`}>
                        {isViewMode ? "Share Class" : isEdit ? "Edit Share Class" : "Add Share Class"}
                    </Title>
                    {isEdit && !isViewMode && (
                        <Menu
                            items={[
                                {
                                    label: "Delete",
                                    icon: IC_TRASH2,
                                    qaid: "AddEditShareClass.Button.DeleteClass",
                                    onClick: onDeleteShareholder,
                                },
                            ]}
                        />
                    )}
                </Flex>
            )}

            <div className={`${AddEditShareClassStyle}__form`}>
                <TextInput
                    value={shareClass.shareClass || ""}
                    name="shareClass"
                    onChange={onInputHandler}
                    qaid="AddEditShareClass.Input.Name"
                    label="Share class name"
                    required
                    error={formValidationState?.shareClass?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.shareClass = el)}
                    errorOnBlur={false}
                    isViewMode={isViewMode || isImport}
                />
                <DatePickerInput
                    value={shareClass.roundDate}
                    maxDate={new Date()}
                    name="roundDate"
                    onChange={(value, name) => {
                        onInputHandler(value?.toISOString(), name);

                        if (!isEdit) return;
                        setWarnings((state) => {
                            if (state.some((warning) => warning.value === 0)) return state;
                            return [
                                ...state,
                                {
                                    value: 0,
                                    label: "Changing Class date will change the issuance date in the issue transactions that take place in the cap table",
                                },
                            ];
                        });
                    }}
                    qaid="AddEditShareClass.Input.RoundDate"
                    label="Round date"
                    required
                    error={formValidationState?.roundDate?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.roundDate = el)}
                    errorOnBlur={false}
                    isViewMode={isViewMode}
                />
                <NumberInput
                    value={shareClass.numberOfRegisteredShares || ""}
                    name="numberOfRegisteredShares"
                    onChange={onInputHandler}
                    qaid="AddEditShareClass.Input.RegisteredShares"
                    label="Number of registered shares"
                    required
                    error={formValidationState?.numberOfRegisteredShares?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.numberOfRegisteredShares = el)}
                    info="The max number of shares that the company is authorized to issue to the share class"
                    isViewMode={isViewMode || isImport}
                />
                <NumberInput
                    value={shareClass.numberOfSharesOutstanding || ""}
                    name="numberOfSharesOutstanding"
                    onChange={onInputHandler}
                    qaid="AddEditShareClass.Input.IssuedShares"
                    label="Number of issued shares"
                    required
                    comment={`Minimum amount: ${formatNumber(availableAmounts?.minAvailableRegisteredShares) || 0}`}
                    error={formValidationState?.numberOfSharesOutstanding?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.numberOfSharesOutstanding = el)}
                    info="The total shares from the class that the company issued to the shareholders"
                    errorOnBlur={false}
                    isViewMode={isViewMode || isImport}
                />
                <NumberInput
                    value={shareClass.issuePricePerShare || ""}
                    name="issuePricePerShare"
                    number="float"
                    onChange={(value, name) => {
                        onInputHandler(value, name);

                        if (!isEdit) return;
                        setWarnings((state) => {
                            if (state.some((warning) => warning.value === 1)) return state;
                            return [
                                ...state,
                                {
                                    value: 1,
                                    label: "Changing Class price per share will change the issuance price in the issue transactions that take place in the cap table.",
                                },
                            ];
                        });
                    }}
                    qaid="AddEditShareClass.Input.PricePerShare"
                    required
                    label={`Issue price per share (${currency?.symbol})`}
                    error={formValidationState?.issuePricePerShare?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.issuePricePerShare = el)}
                    errorOnBlur={false}
                    isViewMode={isViewMode}
                />
                <TextInput
                    value={investmentAmount || ""}
                    qaid="AddEditShareClass.Input.InvestmentAmount"
                    label={`Total investment (${currency?.symbol})`}
                    disabled
                    isTotal
                    isViewMode={isViewMode}
                />
                <NumberInput
                    value={shareClass.numberOfWarrants || ""}
                    name="numberOfWarrants"
                    onChange={(value, name) => {
                        setShareClass((prevState) => ({
                            ...prevState,
                            [name!]: value,
                            warrantsExpirationDate: value ? prevState.warrantsExpirationDate : undefined,
                        }));
                    }}
                    qaid="AddEditShareClass.Input.TotalWarrants"
                    label="Total number of warrants"
                    comment={`Minimum amount: ${formatNumber(availableAmounts?.minAvailableRegisteredWarrants) || 0}`}
                    error={formValidationState?.numberOfWarrants?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.numberOfWarrants = el)}
                    info="If the shareholders which participate in the class round received warrants, you should add the total number here"
                    isViewMode={isViewMode || isImport}
                />
                <DatePickerInput
                    value={shareClass.warrantsExpirationDate || undefined}
                    name="warrantsExpirationDate"
                    onChange={(value, name) => value && onInputHandler(value?.toISOString(), name)}
                    qaid="AddEditShareClass.Input.WarrantsExpirationDate"
                    label="Warrants expiration date"
                    minDate={shareClass.roundDate ? addDaysToDate(shareClass.roundDate, 1) : new Date()}
                    error={formValidationState?.warrantsExpirationDate?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.warrantsExpirationDate = el)}
                    required={!!shareClass.numberOfWarrants}
                    errorOnBlur={false}
                    isViewMode={isViewMode || (isImport && !shareClass.numberOfWarrants)}
                />
                <NumberInput
                    value={shareClass.numberOfBSA || ""}
                    name="numberOfBSA"
                    onChange={onInputHandler}
                    qaid="AddEditShareClass.Input.Bsa"
                    label="Total number of BSA"
                    comment={`Minimum amount: ${formatNumber(availableAmounts?.minAvailableBsa) || 0}`}
                    error={formValidationState?.numberOfBSA?.message}
                    ref={(el: InputValidationRef) => (inputRefs.current.numberOfBSA = el)}
                    isViewMode={isViewMode || isImport}
                />
            </div>
            <div className={`${AddEditShareClassStyle}__selection`}>
                <div className="bold">Select (optional)</div>

                <div className={`${AddEditShareClassStyle}__checkbox-container`}>
                    <Flex
                        justify="start"
                        gap="0.8rem"
                    >
                        <CheckBox
                            label="Anti dilution"
                            isChecked={isAntiDilution}
                            onClick={() => {
                                setShareClass((prevState) => ({
                                    ...prevState,
                                    antiDilutedModeID: isAntiDilution ? null : 100,
                                    convertionValue: undefined,
                                    convertionValueEx: null,
                                }));
                                setIsAntiDilution(!isAntiDilution);
                            }}
                            qaid="AddEditShareClass.CheckBox.AntiDilution"
                            disabled={isViewMode}
                        />
                        <Tooltip title="The anti-dilution will be applied to all shareholders invested in the share class once you will run a simulation on the Funding Scenarios product" />
                    </Flex>
                    <CheckBox
                        label="Conversion"
                        isChecked={isConversion}
                        onClick={() => {
                            setShareClass((prevState) => ({
                                ...prevState,
                                convertionTypeExID: isConversion ? null : 1,
                                convertionValue: undefined,
                                convertionValueEx: null,
                            }));
                            setIsConversion(!isConversion);
                        }}
                        qaid="AddEditShareClass.CheckBox.Conversion"
                        disabled={isViewMode}
                    />
                </div>
                {(isConversion || isAntiDilution) && (
                    <div className={`${AddEditShareClassStyle}__checkbox-container radio-btns`}>
                        <Fade in={isAntiDilution}>
                            <Flex
                                direction="column"
                                align="start"
                                gap="2rem"
                                margin="1rem 0 0 0"
                            >
                                <RadioButton
                                    qaid="AddEditShareClass.Radio.BroadBase"
                                    value={AntiDilitedModeEnum.BroadBase}
                                    name="antiDilutedModeID"
                                    checked={shareClass.antiDilutedModeID === AntiDilitedModeEnum.BroadBase}
                                    onChange={onInputHandler}
                                    label="Broad base"
                                    disabled={isViewMode || !isAntiDilution}
                                />
                                <RadioButton
                                    qaid="AddEditShareClass.Radio.NarrowBase"
                                    value={AntiDilitedModeEnum.NarrowBase}
                                    name="antiDilutedModeID"
                                    checked={shareClass.antiDilutedModeID === AntiDilitedModeEnum.NarrowBase}
                                    onChange={onInputHandler}
                                    label="Narrow base"
                                    disabled={isViewMode || !isAntiDilution}
                                />
                                <RadioButton
                                    qaid="AddEditShareClass.Radio.FullRatchet"
                                    value={AntiDilitedModeEnum.FullRatchet}
                                    name="antiDilutedModeID"
                                    checked={shareClass.antiDilutedModeID === AntiDilitedModeEnum.FullRatchet}
                                    onChange={onInputHandler}
                                    label="Full ratchet"
                                    disabled={isViewMode || !isAntiDilution}
                                />
                            </Flex>
                        </Fade>
                        <Fade in={isConversion}>
                            <Flex
                                direction="column"
                                align="start"
                                gap="2rem"
                                margin="1rem 0 0 0"
                            >
                                <Flex
                                    margin={0}
                                    align="center"
                                    height="fit-content"
                                    justify="start"
                                >
                                    <RadioButton
                                        qaid="AddEditShareClass.Radio.Price"
                                        name="convertionTypeExID"
                                        value={1}
                                        checked={shareClass.convertionTypeExID === 1}
                                        onChange={onSetConversion}
                                        disabled={isViewMode || !isConversion}
                                    />
                                    <NumberInput
                                        qaid="AddEditShareClass.Radio.PriceValue"
                                        name="convertionValueEx"
                                        placeholder={`Price (${currency?.symbol})`}
                                        number="float"
                                        value={shareClass.convertionTypeExID === 1 ? shareClass.convertionValueEx || undefined : undefined}
                                        onChange={onInputHandler}
                                        containerStyle={{ margin: 0 }}
                                        disabled={shareClass.convertionTypeExID !== 1 || isViewMode || !isConversion}
                                        error={
                                            shareClass.convertionTypeExID === 1
                                                ? formValidationState?.convertionValueEx?.message
                                                : undefined
                                        }
                                        ref={(el: InputValidationRef) =>
                                            shareClass.convertionTypeExID === 1 && (inputRefs.current.convertionValueEx = el)
                                        }
                                    />
                                </Flex>
                                <Flex></Flex>
                                <Flex
                                    margin={0}
                                    align="center"
                                    height="fit-content"
                                    justify="start"
                                >
                                    <RadioButton
                                        qaid="AddEditShareClass.Radio.Rate"
                                        name="convertionTypeExID"
                                        value={2}
                                        checked={shareClass.convertionTypeExID === 2}
                                        onChange={onSetConversion}
                                        disabled={isViewMode || !isConversion}
                                    />
                                    <NumberInput
                                        qaid="AddEditShareClass.Radio.PriceValue"
                                        name="convertionValueEx"
                                        placeholder="Rate"
                                        number="float"
                                        value={shareClass.convertionTypeExID === 2 ? shareClass.convertionValueEx || undefined : undefined}
                                        onChange={onInputHandler}
                                        containerStyle={{ margin: 0 }}
                                        disabled={shareClass.convertionTypeExID !== 2 || !isConversion}
                                        error={
                                            shareClass.convertionTypeExID === 2
                                                ? formValidationState?.convertionValueEx?.message
                                                : undefined
                                        }
                                        ref={(el: InputValidationRef) =>
                                            shareClass.convertionTypeExID === 2 && (inputRefs.current.convertionValueEx = el)
                                        }
                                        isViewMode={isViewMode}
                                    />
                                </Flex>
                            </Flex>
                        </Fade>
                    </div>
                )}
                {/* Temporary hidden - related to AN1-2912
				<div className={`${AddEditShareClassStyle}__upload-files-container`}>
					<Clickable qaid="AddEditShareClass.Button" className="bold" align="center" placeContent="start" onClick={() => setShowUpload(!showUpload)}>
						Upload file (optional){showUpload ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</Clickable>
					{showUpload && (
						<div className={`${AddEditShareClassStyle}__upload-section`}>
							<UploadFile accept={["pdf", "word"]} onFileUpload={onFileUpload} />
							<div className={`${AddEditShareClassStyle}__uploaded-files`}>
								{shareClass.documents
									?.filter((doc) => doc.action !== FileStatusOverrideOptions.Remove)
									.map((doc, idx) => (
										<FileInfo file={doc} key={doc.fileName} onDelete={() => onFileDelete(doc)} />
									))}
							</div>
						</div>
					)}
				</div>  */}
                <div className={classNames(`${AddEditShareClassStyle}__actions`, { import: isImport })}>
                    {isViewMode ? (
                        <Button
                            qaid="AddEditShareClass.Button.Close"
                            label={"Close"}
                            onClick={() => removeModal?.()}
                        />
                    ) : (
                        <Button
                            qaid="AddEditShareClass.Button.Add"
                            label={isImport ? "Done" : isEdit ? "Update" : "Add"}
                            onClick={onSubmit}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddEditShareClass;
