import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { WaterfallProject } from '../../../../../Models/API/Waterfall/IForm';
import useRootStore from '../../../../Hooks/useRootStore';
import Version from './Version';
import VersionStyle from './Version/index.style';
import { useMemo, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { isString } from '../../../../Utilities';
import VersionsDropDownStyle from './index.style';

export interface VersionsProps {
	onHide: () => void;
	projects: WaterfallProject[] | undefined | null;
	onDelete: (projectId: number, name: string) => void;
	onLoad: (project: WaterfallProject, step?: number) => void;
	isViewMode?: boolean;
}

type MenuItem = {
	title: string;
	key?: keyof WaterfallProject;
	className: string;
};

const VersionsDropDown = ({ onHide, projects, onDelete, onLoad, isViewMode }: VersionsProps) => {
	const [sortBy, setSortBy] = useState<keyof WaterfallProject | undefined>('dateModified');
	const [isDesc, setIsDesc] = useState<boolean>();
	const { currency } = useRootStore();

	// if (!projects) return <></>;

	const sortedProjects = useMemo(() => {
		if (!sortBy || !projects) return projects || [];
		return (
			[...projects]?.sort((a: WaterfallProject, b: WaterfallProject) => {
				let aVal = a[sortBy];
				let bVal = b[sortBy];

				if (isString(aVal) && isString(bVal)) {
					aVal = aVal.toLowerCase();
					bVal = bVal.toLowerCase();
				}

				if (aVal < bVal) return -1 * (isDesc ? 1 : -1);
				if (aVal > bVal) return 1 * (isDesc ? 1 : -1);
				return 0;
			}) || []
		);
	}, [projects, sortBy, isDesc]);

	const onSortByHandler = (keyName: keyof WaterfallProject) => {
		if (sortBy !== keyName) {
			setIsDesc(false);
			setSortBy(keyName);
			return;
		}

		if (isDesc === true) {
			setIsDesc(undefined);
			setSortBy(undefined);
		} else setIsDesc((state) => !state);
	};

	const menuItems: MenuItem[] = [
		{
			title: 'Analysis name',
			key: 'projectName',
			className: `${VersionStyle}__row-name header clickable`,
		},
		{
			title: `Exit value (${currency.symbol}M)`,
			key: 'exitValue',
			className: `${VersionStyle}__row-exit-value gray clickable`,
		},
		{
			title: 'Date modified',
			key: 'dateModified',
			className: `${VersionStyle}__row-update gray clickable`,
		},
		{
			title: 'Valuated by',
			key: 'valuatorFullName',
			className: `${VersionStyle}__row-valuator gray clickable`,
		},
		{
			title: 'Actions',
			className: `${VersionStyle}__row-actions`,
		},
	];

	return (
		<ClickAwayListener onClickAway={onHide}>
			<div className={VersionsDropDownStyle}>
				<div className={`${VersionStyle}__row-wrapper header`}>
					<div className={`${VersionStyle}__row header`}>
						{menuItems.map((item) => (
							<div key={item.title} onClick={() => item.key && onSortByHandler(item.key)} className={item.className}>
								{item.title} {sortBy && sortBy === item.key && (isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
							</div>
						))}
					</div>
				</div>
				<div
					className={classNames(`${VersionsDropDownStyle}__projects`, {
						scroll: sortedProjects.length > 4,
					})}
				>
					{sortedProjects.map((proj) => (
						<Version key={proj.waterfallId} project={proj} onDelete={onDelete} onLoad={onLoad} isViewMode={isViewMode} />
					))}
					{!sortedProjects.length && (
						<div className="mt-4 mb-4" style={{ paddingInline: '1.2rem' }}>
							No analysis projects were created yet
						</div>
					)}
				</div>
			</div>
		</ClickAwayListener>
	);
};

export default observer(VersionsDropDown);
