import { css } from "@emotion/css";
import { breakpoints } from "../../../config/style/theme";

export const PricingStyle = css({
	label: "Pricing",
	backgroundColor: "#F4F5FD",
	overflow: "hidden",

	".plansContainer": {
		height: "100%",
		display: 'flex',
		gap: '2rem',
		alignItems: 'center',
		padding: '5rem 10.5rem',

		[`@media screen and (max-width: ${breakpoints.sm})`]: {
			flexDirection: 'column',
			width: '100%',
			paddingInline: "16rem",
			paddingTop: '15px'
		},

		[`@media screen and (max-width: ${breakpoints.xs})`]: {
			paddingInline: "5rem",
		}
	},

	".carouselContainer": {
		display: "flex",
		height: "100%",

		".css-doq0dk": {
			position: "unset",
		},
	},
});
