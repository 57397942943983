import { makeAutoObservable } from 'mobx';
import { DataCollectionService } from '../Services/DataCollectionService';
import { RootStore } from './RootStore';
import { makePersistable } from 'mobx-persist-store';
import { ValuationProject } from '../Models/API/DataCollection/valuation-project';
import { DataCollectionProject } from '../Models/API/DataCollection/data-collection-project';

export default class DataCollectionStore {
	dataCollectionService: DataCollectionService = new DataCollectionService();
	projects: ValuationProject[] | undefined = undefined;

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'DataCollectionStore',
			properties: [],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		rootStore.companyStore.onCompanyChange.subscribe(() => {
			if (!this.rootStore.companyStore.companyId) return;
			// on change
		});
	}

	// Valuation Project

	async createProject() {
		const res = await this.dataCollectionService.createProject();
		if (res.data?.data) {
			this.projects?.push(res.data.data);
		}
		return res;
	}

	async getProjects() {
		const res = await this.dataCollectionService.getProjects();
		this.projects = res.data?.data;
	}

	takeProjectOwnership(valuationProjectId: string) {
		return this.dataCollectionService.takeProjectOwnership(valuationProjectId);
	}

	// Data Collection

	getDataCollection(valuationProjectId: string) {
		return this.dataCollectionService.takeProjectOwnership(valuationProjectId);
	}

	createDataCollection(data: Partial<DataCollectionProject>) {
		return this.dataCollectionService.createDataCollection(data);
	}

	updateDataCollection(data: DataCollectionProject) {
		return this.dataCollectionService.updateDataCollection(data);
	}

	submitDataCollection(dataCollectionId: string, isCompleted: boolean) {
		return this.dataCollectionService.submitDataCollection(dataCollectionId, isCompleted);
	}

	resetStoreToDefaultValues() {
		// to clean all value
	}
}
