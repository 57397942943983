import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import { CapTableImportStep } from '../../index.style';
import { ImportContext } from '../../ImportContext';
import AddEditShareClass from '../../../General/AddEditShareClass/AddEditShareClass';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { css } from '@emotion/css';
import appConfig from '../../../../../../config/config';
import { ShareClassTypeOptions } from '../../../../../../Models/API/enums';
import Image from '../../../../../../Shared/Components/Image';
import { IC_CHECK_PURPLE } from '../../../../../../Assets';
import classNames from 'classnames';
import { isNullOrUndefined } from '../../../../../../Shared/Utilities';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../../Routes';
import OverflowText from '../../../../../../Shared/Components/OverflowText';

const Style = css({
	label: 'AddShareClasses',
	'&__title': {
		fontWeight: 500,
		marginBottom: '1.6rem',
	},
	'&__classes-container': {
		padding: '3rem',
		border: `1px solid ${appConfig.style.colors.table}`,
		borderRadius: 16,
		boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
		marginRight: '2rem',
		flex: '1 0 28rem',
		width: '28rem',
		'.classes-list': {
			display: 'flex',
			flexDirection: 'column',
			gap: '0.8rem',
			'> span': {
				display: 'flex',
				alignItems: 'center',
				gap: '0.8rem',
				cursor: 'pointer',

				'&:not(.completed)': {
					marginLeft: '2.7rem',
					'&:not(.active)': {
						color: appConfig.style.colors.text2,
					},
				},
				'&.completed': {
					color: appConfig.style.colors.color1,
				},
			},
		},
	},
	'&__class-form': {
		padding: '3rem',
		border: `1px solid ${appConfig.style.colors.table}`,
		borderRadius: 16,
		boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
		'.form-title': {
			fontWeight: 500,
			marginBottom: '1.6rem',
		},
	},
	'&__text': {
		marginTop: '0.8rem',
		marginBottom: '2.8rem',
	},
});

type Props = {
	onFinish?: (projectId: number) => void;
};

type Status = Record<number, boolean>;

const AddShareClasses = forwardRef<ForwardedRef, Props>((props, forwardedRef) => {
	const { capTableStore } = useRootStore();
	const { shareClasses, setShareClasses } = useContext(ImportContext);

	const filteredClasses = shareClasses?.filter((sc) => sc.shareClassTypeID === ShareClassTypeOptions.Normal) || [];
	const [selectedClassId, setSelectedClassId] = useState<number>(filteredClasses[0]?.shareClassID);

	const selectedClass = filteredClasses.find((sc) => sc.shareClassID === selectedClassId);
	const history = useHistory();

	const [status, setStatus] = useState<Status>(filteredClasses.reduce((acc, sc) => ({ ...acc, [sc.shareClassID]: false }), {} as Status));

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			return Object.values(status).every((val) => val);
		},
	}));

	useEffect(() => {
		if (shareClasses?.[0].projectID && Object.values(status).every((val) => val)) {
			if (props.onFinish) {
				return props.onFinish?.(shareClasses[0].projectID);
			}
			history.push({
				pathname: Routes.capTable.index,
				search: `?import=true&projectId=${shareClasses[0].projectID}`,
			});
		}
	}, [shareClasses]);

	return (
		<CapTableImportStep size="md" className={Style}>
			<div className="bold">Please specify the relevant parameters for each of the Share Classes.</div>
			<div className={`${Style}__text`}>
				Note: in case AoA is not available now, you can skip this step and specify the share classes details later, directly on the cap table.
			</div>
			<div className="flex">
				<div className={`${Style}__classes-container`}>
					<div className={`${Style}__title`}>Share Classes</div>
					<div className="classes-list">
						{filteredClasses.map((shareClass) => (
							<span
								key={shareClass.shareClassID}
								onClick={() => setSelectedClassId(shareClass.shareClassID)}
								className={classNames({
									completed: status[shareClass.shareClassID],
									active: shareClass.shareClassID === selectedClassId,
								})}
							>
								{status[shareClass.shareClassID] && <Image width="1.9rem" src={IC_CHECK_PURPLE} />}
								<OverflowText>{shareClass.shareClass}</OverflowText>
							</span>
						))}
					</div>
				</div>
				<div className={`${Style}__class-form`}>
					<div className="form-title">Add Share Class details ({selectedClass?.shareClass})</div>
					<AddEditShareClass
						key={selectedClassId}
						shareClassInfo={selectedClass}
						isImport
						onAdd={async (shareClass) => {
							const res = await capTableStore.addShareClassFromImport(shareClass);
							if (res.error) return;
							setStatus((prevState) => ({
								...prevState,
								[shareClass.shareClassID]: true,
							}));

							setShareClasses((prevState) => {
								const idx = prevState?.findIndex((sc) => sc.shareClassID === shareClass.shareClassID);
								if (isNullOrUndefined(idx) || idx === -1 || isNullOrUndefined(prevState)) return prevState;

								return [...prevState.slice(0, idx), shareClass, ...prevState.slice(idx + 1)];
							});

							const nextShareClassId = Object.keys(status).find((id: string) => shareClass.shareClassID !== +id && status[+id] === false);
							nextShareClassId && setSelectedClassId(+nextShareClassId);
						}}
						getAvailableAmounts={(shareClassId, date) => capTableStore.getAvailableAmountByClass(shareClassId, date)}
					/>
				</div>
			</div>
		</CapTableImportStep>
	);
});

export default AddShareClasses;
