import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { colors } from '../../../../../config/style/lib/colors';
import { theme } from '../../../../../config/style/theme';
import { NumberOption } from '../../../../../Models/API/All/NumberOption';
import { ProjectsUserInfo } from '../../../../../Models/API/CapTable';
import { ProductTypeEnum } from '../../../../../Models/API/enums';
import useGeneralModal from '../../../../Hooks/useGeneralModal';
import useRootStore from '../../../../Hooks/useRootStore';
import { formatDate, isNumber } from '../../../../Utilities';
import Button from '../../../Button/Button';
import Heading from '../../../Heading/Heading';
import AutoCompleteInput from '../../../Input/AutoCompleteInput';
import Flex from '../../../Layout/Flex';
import RadioButton from '../../../RadioButton/RadioButton';
import Select from '../../../Select/Select';
import { ModalContent } from './index.style';

type Props = {
	selected?: ProjectsUserInfo;
	onSelect?: (data: ProjectsUserInfo) => void;
	onImport?: VoidFunction;
	publishedCapTable?: ProjectsUserInfo;
	drafts?: {
		capTable: ProjectsUserInfo[];
		funding: ProjectsUserInfo[];
	};
	isViewMode?: boolean;
};

enum DraftSourcesEnum {
	capTable = 10,
	funding = 20,
}

export const draftSources: NumberOption[] = [
	{ value: DraftSourcesEnum.capTable, label: 'nav.capTable' },
	{ value: DraftSourcesEnum.funding, label: 'nav.funding' },
];

type ImportTypes = 'importDraft' | 'importPublishedCaptable';

const setInitialSourceType = (selectedProject: ProjectsUserInfo | undefined) => {
	if (!selectedProject) return undefined;

	if (selectedProject.isPublished === false) {
		return 'importDraft';
	}

	if (selectedProject?.productType === ProductTypeEnum.CapTable && selectedProject.isPublished === true) {
		return 'importPublishedCaptable';
	}
};

const setInitialDraftSourceType = (selectedProject: ProjectsUserInfo | undefined) => {
	if (!selectedProject) return undefined;

	if (selectedProject.productType === ProductTypeEnum.CapTable && selectedProject.isPublished === false) {
		return draftSources[0];
	}

	if (selectedProject.productType === ProductTypeEnum.NextRound && selectedProject.isPublished === false) {
		return draftSources[1];
	}
};

const setInitialDraftSource = (selectedProject: ProjectsUserInfo | undefined) => {
	if (!selectedProject) return undefined;

	if (selectedProject.projectID) {
		return selectedProject.projectID;
	}

	return undefined;
};

const SelectImportSource = ({ selected, onSelect, publishedCapTable, drafts, isViewMode, onImport }: Props) => {
	const { companyStore } = useRootStore();
	const { showErrorModal } = useGeneralModal();

	const resetSelection = () => {
		setSourceType(undefined);
		setDraftSourceType(undefined);
		setSpecificDraft(undefined);
	};

	useEffect(() => {
		if (publishedCapTable?.updatedDate) {
			const formattedDate = formatDate(publishedCapTable.updatedDate);
			formattedDate && setPublishedCapTableDate(formattedDate);
		} else {
			setSourceType('importDraft');
		}
	}, [publishedCapTable]);

	useEffect(() => {
		if (!selected) return;

		// Set the initial source type ('importDraft' or 'importPublishedCaptable')
		const initialSourceType = setInitialSourceType(selected);
		setSourceType(initialSourceType);

		// Set the initial draft source type (based on DraftSourcesEnum)
		const initialDraftSourceType = setInitialDraftSourceType(selected);
		setDraftSourceType(initialDraftSourceType);

		// Set the initial specific draft (projectID)
		const initialDraftSource = setInitialDraftSource(selected);
		setSpecificDraft(initialDraftSource);
	}, [selected]);

	// 1. the user selects whether to import a published cap table or a draft (radio button)
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [sourceType, setSourceType] = useState<ImportTypes | undefined>(undefined);

	// 3. if the user selected "importDraft":
	//  A. the user selects the draft type (captable draft / funding draft )
	const [draftSourceType, setDraftSourceType] = useState<NumberOption | undefined>(undefined);
	//  B. the user selects a specific draft (dropdown)
	const [specificDraft, setSpecificDraft] = useState<number | undefined>(undefined);

	// 3. if user selects "importPublishedCaptable", we need to show the date of the published cap table:
	const [publishedCapTableDate, setPublishedCapTableDate] = useState<string | undefined>(undefined);

	const handleRadioChange = (value: ImportTypes) => {
		setSourceType(value);

		if (value === 'importPublishedCaptable' && publishedCapTable) {
			onSelect?.(publishedCapTable);
		}
	};

	const draftOptions = useMemo(() => {
		if (!draftSourceType || !drafts?.funding) return [];
		if (draftSourceType.value === DraftSourcesEnum.capTable) {
			return drafts?.capTable.map((draft) => ({
				label: draft.projectName,
				value: draft.projectID,
			}));
		} else if (draftSourceType.value === DraftSourcesEnum.funding) {
			return drafts?.funding.map((draft) => ({
				label: draft.projectName,
				value: draft.projectID,
			}));
		}
		return [];
	}, [drafts, draftSourceType]);

	const handleSelectBase = (projectID: number) => {
		const base = drafts?.[draftSourceType?.value === DraftSourcesEnum.funding ? 'funding' : 'capTable'].find((draft) => draft.projectID === projectID);
		if (!base) return;
		setSpecificDraft(base.projectID);
		onSelect?.(base);
	};

	return (
		<ModalContent>
			<Heading
				ap={{
					tag: 'h5',
					spacingBottom: 'md',
					isBold: true,
				}}
			>
				{t('waterfall.tableToImport')}
			</Heading>

			{!drafts && (
				<Heading
					ap={{
						color: 'error',
						tag: 'h3',
						spacingBottom: 'md',
					}}
				>
					{t('waterfall.noCapTableComment')}
				</Heading>
			)}

			<Flex justify="start" align="center" gap={theme.spacings.md} style={{ height: '3.6rem' }}>
				<RadioButton
					autoTranslate
					qaid="Import.CheckBox.PublishedCapTable"
					name="SelectBaseType"
					value="importPublishedCaptable"
					onChange={(value) => handleRadioChange(value)}
					checked={sourceType === 'importPublishedCaptable'}
					disabled={!publishedCapTable}
				>
					{publishedCapTable ? (
						<Trans i18nKey="waterfall.publishedCapTable" values={{ publishedCapTableDate }} components={[<span className="bold ml-2" />]} />
					) : (
						<span style={{ color: colors.error }}>{t('waterfall.noPublishedCapTable')}</span>
					)}
				</RadioButton>

				{sourceType !== 'importPublishedCaptable' ||
					(publishedCapTable && (
						<Button
							disabled={selected?.productType !== ProductTypeEnum['CapTable'] || isViewMode}
							qaid="SelectImportSource.Button.ImportPubslishedCapTable"
							onClick={() => {
								onSelect?.(publishedCapTable);
								onImport?.();
							}}
							label="waterfall.import"
						/>
					))}
			</Flex>

			<Flex justify="start" align="center" gap={theme.spacings.md} style={{ height: 'auto' }}>
				<RadioButton
					autoTranslate
					qaid="Import.CheckBox.ImportDraft"
					name="SelectBaseType"
					value="importDraft"
					onChange={(value) => handleRadioChange(value)}
					checked={sourceType === 'importDraft'}
					disabled={!drafts}
				>
					{drafts ? (
						<div style={{ whiteSpace: 'nowrap' }}>{t('waterfall.importFromDraft')}</div>
					) : (
						<div style={{ color: colors.error }}>{t('waterfall.noSavedDrafts')}</div>
					)}
				</RadioButton>

				{/* select draft type: */}

				<Select
					style={{ marginBottom: 0, width: '22rem' }}
					qaid="SelectImportSource.Select.SelectDraftType"
					value={draftSourceType?.value}
					options={draftSources}
					onChange={(value) => {
						setDraftSourceType(draftSources?.find((source) => source.value === value) || undefined);
						handleRadioChange('importDraft');
					}}
				/>

				{/* select draft: */}

				<AutoCompleteInput
					qaid="SelectImportSource.Input.SelectDraftType"
					options={draftOptions}
					value={specificDraft}
					onChange={(value) => {
						if (!isNumber(value)) return;
						handleRadioChange('importDraft');
						handleSelectBase(value);
					}}
					strict
					containerStyle={{ margin: 0, minWidth: 220 }}
				/>

				<Button
					qaid="SelectImportSource.Button.ImportDraft"
					onClick={onImport}
					isLoading={isLoading}
					label="waterfall.import"
					disabled={!selected || selected.isPublished === true || isViewMode}
				/>
			</Flex>
		</ModalContent>
	);
};

export default SelectImportSource;
