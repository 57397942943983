import { css } from '@emotion/css';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import useRootStore from '../../../../Hooks/useRootStore';
import useModal from '../../../../Hooks/useModal';
import { DataPlanResponse, PlanData, UploadOptionsRequest, WaterfallAwardType } from '../../../../../Models/API/Waterfall/IForm';
import Table, { TableColumn } from '../../../Table/Table';
import appConfig from '../../../../../config/config';
import { Cell } from '../../../Table/Table.Style';
import { formatDate, formatDecimal, formatNumber, isNullOrUndefined, isNumber, sortArrayBy } from '../../../../Utilities';
import Clickable from '../../../Clickable/Clickable';
import Image from '../../../Image';
import { IC_EDIT2, IC_NOTE_PURPLE, IC_TRASH2 } from '../../../../../Assets';
import WaterfallTableHeading from '../../../../../Screens/Waterfall/Components/WaterfallTableHeading/WaterfallTableHeading';
import AddButton from '../../../Button/AddButton';
import Button from '../../../Button/Button';
import Container from '../../../Modal/Modal.Style';
import Flex from '../../../Layout/Flex';
import { WaterfallStep } from '../../../../../Screens/Waterfall/Components/AddEditWaterfall/index.style';
import { Collapse } from '@mui/material';
import AddAward from './AddAward';
import Spinner from '../../../Spinner/Spinner';
import { HigherLevelResponse, HighLevelErrorResponse } from '../../../../../Services/Axios';
import { ForwardedRef } from '../../../../Hooks/useMultiStepForm';

const ModalCss = css({
	'&__title': {
		color: appConfig.style.colors.color1,
		fontFamily: appConfig.style.fonts.alt,
		fontWeight: 'bold',
		marginBottom: 10,
		marginTop: 10,
		fontSize: 18,
	},
	'&__text': {
		fontFamily: appConfig.style.fonts.assistant,
		maxWidth: '70%',
	},
});

const MODAL_BG_COLOR = 'rgba(0,0,0,0.2)';

type Props = {
	waterfallId: number;
	uploadOptions: (payload: UploadOptionsRequest) => Promise<HighLevelErrorResponse | HigherLevelResponse<DataPlanResponse>>;
	getOptions: (waterfallId?: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<DataPlanResponse | null>>;
	updateOptionsMode: (payload: UploadOptionsRequest) => Promise<HighLevelErrorResponse | HigherLevelResponse<DataPlanResponse | null>>;
	getValuationDate: (waterfallId?: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<string>>;
	deleteAwardType: (shareClassId: number, waterfallId?: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<unknown>>;
	reimportOptions: (companyId?: number, waterfallId?: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<DataPlanResponse>>;
	addAwardType: (data: WaterfallAwardType, waterfallId?: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<DataPlanResponse>>;
	updateAwardType: (data: WaterfallAwardType, waterfallId?: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<DataPlanResponse>>;
};

const EquityPlansImport = React.forwardRef<ForwardedRef, Props>(
	(
		{ deleteAwardType, getOptions, getValuationDate, reimportOptions, updateOptionsMode, uploadOptions, waterfallId, addAwardType, updateAwardType },
		forwardedRef
	) => {
		const {
			companyStore: { companyId },
			currency,
		} = useRootStore();
		const [isLoadingYes, setIsLoadingYes] = useState<boolean>(false);
		const [isLoadingNo, setIsLoadingNo] = useState<boolean>(false);
		const [isAllocatedAdded, setIsAllocatedAdded] = useState<boolean | null>(false);
		const [date, setDate] = useState<string>();
		const [error, setError] = useState<string>();
		const [editAwardId, setEditAwardId] = useState<number | undefined>();
		const { showModal } = useModal();
		const [data, setData] = useState<PlanData[] | undefined | null>(undefined);
		const [isChanged, setIsChanged] = useState<boolean>(false);

		useImperativeHandle(forwardedRef, () => ({
			async onValidate() {
				// return (data && data?.length > 0) || data === undefined;
				return true;
			},
		}));

		const tableColumns: TableColumn<PlanData>[] = [
			{
				name: 'name',
				label: 'Equity awards',
				// format(val, obj) {
				//   return obj?.isUnallocated ? val : `OPT (${currency?.symbol}${obj?.optionPrice})`;
				// },
				render(obj, value) {
					return <>{obj?.isUnalocated ? 'Unallocated Options' : obj?.name}</>;
				},
			},
			{
				name: 'amount',
				label: 'Amount',
				// format(val) {
				//   return formatNumber(val);
				// },
				render(obj, value) {
					return <Cell>{obj.isUnalocated ? formatDecimal(value) : formatDecimal(value)}</Cell>;
				},
			},
			{
				name: 'price',
				label: `Exercise price (${currency?.symbol})`,
				format(val) {
					return formatNumber(val);
				},
			},
			{
				name: 'actions',
				label: 'Actions',
				defaultCellSize: '2rem',
				style: { gap: '2.4rem', flex: 0.5 },
				render: (dataPlan) => (
					<>
						<Clickable qaid="" flex={0} onClick={() => setEditAwardId(dataPlan.shareClassId)}>
							<Image src={IC_EDIT2} width="2rem" alt="Edit" />
						</Clickable>
						<Clickable
							qaid=""
							flex={0}
							onClick={() => {
								showModal({
									type: 'warning',
									title: 'Delete award?',
									body: (
										<div>
											Are you sure that you want to delete <span className="bold">{dataPlan.name}</span> award?
										</div>
									),
									onConfirm: async () => {
										if (isNullOrUndefined(dataPlan.shareClassId)) return;
										const res = await deleteAwardType(dataPlan.shareClassId);
										if (!res.isSuccess) return;
										setData((prevData) => prevData?.filter((p) => p.shareClassId !== dataPlan.shareClassId));
									},
								});
							}}
						>
							<Image src={IC_TRASH2} width="2rem" alt="Delete" />
						</Clickable>
					</>
				),
			},
		];

		const getTotalData = (plans: PlanData[]): PlanData => ({
			price: undefined,
			amount: plans.reduce((acc, val) => acc + val.amount, 0),
			isUnalocated: true,
			shareClassId: 0,
		});

		useEffect(() => {
			(async () => {
				const res = await getValuationDate();
				setDate(res.data);
			})();
		}, []);

		useEffect(() => {
			loadOptions();
		}, [waterfallId]);

		const loadOptions = async () => {
			const res = await getOptions(waterfallId);
			if (isNullOrUndefined(res.data) || !res.data?.options) {
				setData(null);
				return;
			}

			if (res.data.options.length === 0) {
				setEditAwardId(-1);
			}

			const sortedOptions = sortArrayBy(res.data.options, 'price');
			setData(sortedOptions.length ? [...sortedOptions, getTotalData(sortedOptions)] : []);
			setIsAllocatedAdded(res.data.withUnalocated);
			setIsChanged(res.data.isUpdatedOption);
		};

		const createOptions = async (isUnallocated: boolean, isUpdate?: boolean) => {
			// Clear previous errors
			setError(undefined);

			const setLoadingState = isUnallocated ? setIsLoadingYes : setIsLoadingNo;
			setLoadingState(true);

			const payload = {
				companyId,
				waterfallId,
				isUnallocated,
			};

			// Upload or update options
			let res;
			if (isUpdate) {
				res = await updateOptionsMode(payload);
			} else {
				await uploadOptions(payload);
				res = await getOptions(waterfallId);
			}

			setLoadingState(false);

			if (!res.isSuccess) {
				return setError(res.errorMessage);
			}

			if (isNullOrUndefined(res.data?.options)) return;
			handleTotal(res.data.options);
			setIsChanged(res.data.isUpdatedOption);
			setIsAllocatedAdded(res.data.withUnalocated);
			// Update state
		};

		const handleTotal = (planData: PlanData[]) => {
			const withTotalData = [...planData, getTotalData(planData)];
			setData(withTotalData);
		};

		const equityPlansStepTitle = `Equity awards (grouped by exercise price) as of ${formatDate(date) || ''}`;

		const content = {
			equityPlansData: (
				<Table
					customHeaderRender={<WaterfallTableHeading text={equityPlansStepTitle} subtitle={isChanged ? ' (edited by the project owner)' : ''} />}
					fullscreen
					columns={tableColumns}
					rows={data}
					showTotal
					border={{ bottom: true }}
					scrollAfterRows={8}
					renderBottom={
						<AddButton
							qaid="EquityAward.Button.AddAward"
							label="Add award"
							onClick={() => setEditAwardId((prevId) => (isNumber(prevId) ? undefined : -1))}
						/>
					}
					customActionsRender={
						isChanged ? (
							<Button
								qaid="EquityAward.Button.Change"
								label="Change"
								onClick={async () => {
									showModal({
										body: (props) => {
											return (
												<>
													<span style={{ fontWeight: 500, fontSize: '2.5rem' }}>Re-import data source</span>
													<span style={{ marginTop: '2rem', marginBottom: '3rem' }}>
														Dp you want to re-import the options data from the Equity plans product / Trustee data?
													</span>
													<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '2.4rem' }}>
														<Button qaid="Reimport.Button.Cancel" label="Cancel" cancel onClick={props.removeModal} />
														<Button
															qaid="Reimport.Button.Import"
															label="Import"
															onClick={async () => {
																const res = await reimportOptions();
																setData(res.data?.options);
																setIsChanged(res.data?.isUpdatedOption ?? false);
																props.removeModal?.();
															}}
														/>
													</div>
												</>
											);
										},
										maxWidth: '81.5rem',
										width: '100%',
									});
								}}
							/>
						) : undefined
					}
				/>
			),
			noEquityPlansData: <div>No Options Data</div>,
			equityPlansPreferencePrompt: (
				<Container.Wrapper isFirst relative zIndex={10} background={MODAL_BG_COLOR} style={{ height: 'calc(100% - 29.5rem', top: '21.9rem' }}>
					<Container.Modal type="confirm">
						<Container.Body>
							<Flex direction="column" className={ModalCss} padding={20}>
								<img src={IC_NOTE_PURPLE} alt="Note" />
								<div className={`${ModalCss}__title`} style={{ color: appConfig.style.colors.color1 }}>
									Unallocated Options
								</div>
								<div className={`${ModalCss}__text`}>Would you like to include the Unallocated Options in the equity plans table?</div>
								<Flex gap={20} className="mt-5">
									<Button
										qaid="OptionsPlans.Button.Yes"
										label="general.yes"
										onClick={() => createOptions(true)}
										isLoading={isLoadingYes}
										disabled={isLoadingNo || isLoadingYes}
									/>
									<Button
										qaid="OptionsPlans.Button.No"
										inverse
										label="general.no"
										onClick={() => createOptions(false)}
										isLoading={isLoadingNo}
										disabled={isLoadingNo || isLoadingYes}
									/>
								</Flex>
							</Flex>
							{error && <div className="text-color-error">{error}</div>}
						</Container.Body>
					</Container.Modal>
				</Container.Wrapper>
			),
		};

		const showNoEquityPlansData = data === null && isAllocatedAdded === undefined;
		const showEquityPlansPreferencePrompt = isAllocatedAdded === null;
		const showEquityPlansData = data !== undefined && !showNoEquityPlansData && !showEquityPlansPreferencePrompt;

		return (
			<WaterfallStep small>
				<Collapse in={isNumber(editAwardId) && !showEquityPlansPreferencePrompt} unmountOnExit>
					<AddAward
						onAddHandler={async (award) => {
							const aw = data?.find((d) => d.shareClassId === editAwardId);
							const res = aw?.shareClassId ? await updateAwardType(award) : await addAwardType(award);
							if (!res.isSuccess) return;
							handleTotal(res.data.options);
							setIsChanged(res.data.isUpdatedOption);
							setIsAllocatedAdded(res.data.withUnalocated);
							setEditAwardId(undefined);
						}}
						data={data?.find((d) => d.shareClassId === editAwardId)}
					/>
				</Collapse>
				<div className="layout__main">
					{data === undefined && (
						<Flex>
							<Spinner incorporated background={MODAL_BG_COLOR} />
						</Flex>
					)}
					{showEquityPlansData && content.equityPlansData}
					{showNoEquityPlansData && content.equityPlansData}
					{showEquityPlansPreferencePrompt && content.equityPlansPreferencePrompt}
				</div>
			</WaterfallStep>
		);
	}
);

export default EquityPlansImport;
