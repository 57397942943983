import Heading from "../../../../Shared/Components/Heading/Heading";

type Props = {
	children: React.ReactNode;
	className?: string;
};

const WaterfallSubtitle = (props: Props) => {
	return (
		<Heading
			ap={{
				tag: 'h5',
				color: 'dark',
				size: 'md',
				spacingBottom: 'md',
				isBold: true,
			}}
			className={props.className}
		>
			{props.children}
		</Heading>
	);
};

export default WaterfallSubtitle;
