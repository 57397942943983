import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLElement> {
	isOpen: boolean;
}

const Style = css({
	label: 'ExpandToggle',

	background: appConfig.style.colors.background1,
	border: `1px solid ${appConfig.style.colors.field}`,
	borderRadius: 4,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '2.4rem',
	minWidth: '2.4rem',
	height: '2.4rem',
	minHeight: '2.4rem',
});

const ExpandToggle = ({ isOpen }: Props) => {
	return <div className={Style}>{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>;
};

export default ExpandToggle;
