import { useRef } from 'react';
import { ForwardedRefType } from '../../../../../Shared/Hooks/useMultiStepForm';
import Question from './Question';
import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import DatePickerInput from '../../../../../Shared/Components/Input/DatePickerInput';
import NumberInput from '../../../../../Shared/Components/Input/NumberInput';
import { TextAreaInput } from '../../../../../Shared/Components/Input/Input.Style';
import { WaterfallStep } from '../../../../Waterfall/Components/AddEditWaterfall/index.style';

const GeneralInfo = () => {
	return (
		<WaterfallStep small>
			<span>General Info</span>
			<Question
				title="For which date does your company need the 409A valuation?"
				info="the fill later"
				renderActions={<DatePickerInput qaid="" value={undefined} onChange={(date) => console.log(date)} />}
			/>

			<Question
				title="Has your company raised money six months prior the valuation date?"
				info="the fill later"
				renderActions={
					<div className="flex gap-2 align-center">
						<RadioButton qaid="" value={true} label="Yes" onChange={(val) => console.log(val)} name={'Something'} />
						<RadioButton qaid="" value={false} label="No" onChange={(val) => console.log(val)} name={'Something'} />
					</div>
				}
			/>

			<Question
				title="Has your company generated revenues in the last 12 months?"
				info="the fill later"
				renderActions={
					<div className="flex gap-2 align-center">
						<RadioButton qaid="" value={true} label="Yes" onChange={(val) => console.log(val)} name={'Something'} />
						<RadioButton qaid="" value={false} label="No" onChange={(val) => console.log(val)} name={'Something'} />
					</div>
				}
			/>
			<Question
				title="Has your company generated operational profit in the last 12 months or projected to generate a profit in the next 1-2 years?"
				info="the fill later"
				renderActions={
					<div className="flex gap-2 align-center">
						<RadioButton qaid="" value={true} label="Yes" onChange={(val) => console.log(val)} name={'Something'} />
						<RadioButton qaid="" value={false} label="No" onChange={(val) => console.log(val)} name={'Something'} />
					</div>
				}
			/>
			<Question
				title="What’s the company estimation regarding the time in years for an exit (M&A, IPO) or other sell of the company assets?"
				info="the fill later"
				renderActions={<NumberInput qaid="" value={undefined} placeholder="Type in years..." />}
			/>
			<Question
				title="What’s the company estimation regarding the time in years for an exit (M&A, IPO) or other sell of the company assets?"
				info="the fill later"
				renderActions={
					<TextAreaInput data-qaid="" style={{ resize: 'none', height: '100%', width: '100%' }} rows={4} maxLength={500} name="" value={undefined} />
				}
			/>
		</WaterfallStep>
	);
};

export default GeneralInfo;
