import { forwardRef, useImperativeHandle } from 'react';
import { ForwardedRef } from '../../../../../Shared/Hooks/useMultiStepForm';

const Summary = forwardRef<ForwardedRef, any>((_, forwardedRef) => {
	useImperativeHandle(forwardedRef, () => ({
		onValidate: () => {
			return true;
		},
	}));

	return (
		<div>
			<div>Summary</div>
		</div>
	);
});

export default Summary;
