import styled from "@emotion/styled";
import appConfig from "../../../../config/config";
import { css } from "@emotion/css";

export const CapTableImportStyle = css({
    label: "CapTableImport",
    display: "flex",
    flexDirection: "column",
    "&__btn-actions": {
        background: appConfig.style.colors.gradientPurpleVertical,
        padding: "0.8rem 1.2rem",
        borderRadius: 8,
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
        cursor: "pointer",
        userSelect: "none",
        border: "1px solid #ffffff",
        transition: "all .1s",
        "&.clicked": {
            border: `1px solid ${appConfig.style.colors.color1}`,
            background: appConfig.style.colors.purple,
            color: appConfig.style.colors.color1,
        },
    },
});

export const ImportCapTableFooter = styled.div({
	// padding: "4rem 9rem 4rem 9.4rem",
	label: 'ImportCapTableFooter',
	display: 'flex',
	borderTopWidth: 1,
	borderTopStyle: 'solid',
	borderTopColor: appConfig.style.colors.table,
	justifyContent: 'space-between',
	padding: '2rem 0rem',
	marginInline: '10rem',
	position: 'fixed',
	bottom: 0,
	width: 'calc(100% - 20rem)',
	background: '#ffffff',
	paddingTop: '2rem',
	'.note': {
		position: 'absolute',
		top: '-2.7rem',
		left: 0,
		color: appConfig.style.colors.text2,
		background: '#ffffff',
		width: '100%',
		paddingTop: '0.4rem',
		zIndex: 10000,
	},
});

export const CapTableImportStep = styled.div(
    {
        label: "CapTableImportStep",
        display: "flex",
        marginBottom: "7.4rem",
        width: "100%",
    },
    ({ size = "lg", scroll, row = false }: { size?: "sm" | "md" | "lg"; scroll?: boolean; row?: boolean }) => ({
        ...(size === "sm"
            ? {
                  maxWidth: "54rem",
                  marginInline: "auto",
                  padding: "4rem 0rem",
              }
            : size === "md"
            ? {
                  maxWidth: "114rem",
                  marginInline: "auto",
                  padding: "4rem 6rem 5rem",
              }
            : {
                  padding: "4rem 10rem",
              }),
        ...(scroll
            ? { height: "calc(100vh - 29.61rem)" }
            : {
                  flex: 1,
              }),
        flexDirection: row ? "row" : "column",
    })
);
