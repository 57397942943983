import { makeAutoObservable, toJS } from 'mobx';
import { clearPersistedStore, isHydrated, makePersistable } from 'mobx-persist-store';
import { CompanyRegistration } from '../Models/API/Auth';
import { UserStatus } from '../Models/API/enums';
import AppUser, { CurrentUserInfo, ILoginPivotal, IPivotalCompany, IUserLogin } from '../Models/App/AppUserModel';
import { ICreateAccount, IUserRegistration, RegUser } from '../Models/App/Auth/RegUser';
import AuthService from '../Services/AuthService';
import { clearInstancesToken, setInstancesToken } from '../Services/Axios';
import { deleteSessionStorage, encryptAes, getSessionStorage, isNumber, setSessionStorage } from '../Shared/Utilities';
import { RootStore } from './RootStore';
import { mapAddressRegistration, mapCompanyRegistration, mapContactInfo } from '../utils/mapper';
import { googleLogout } from '@react-oauth/google';
import { UserPermissions } from '../Models/API/UsersAndPermissions/user-permissions-info';
import Cookies from 'universal-cookie';

export const USER_EMAIL = 'user_email';

export class AuthStore {
	protected authService: AuthService;
	userInfo: AppUser;
	forgotPassSent = false;
	loginPivotalData?: ILoginPivotal;
	loginPivotalGuid?: string;
	recaptchaKey: string = '';
	permissions?: UserPermissions;
	companyProfileData?: CompanyRegistration;
	pivotalCompanies?: IPivotalCompany[];
	pivotalToken: string = '';
	isLoading: boolean = false;
	language: string = 'Eng';
	externalAuthId?: string | null;
	currentUserInfo: CurrentUserInfo = {} as CurrentUserInfo;

	get user(): AppUser {
		return this.userInfo ?? ({} as AppUser);
	}

	get userEmail(): string {
		return getSessionStorage(USER_EMAIL) as string;
	}

	get authToken() {
		return this.userInfo.jwttoken;
	}

	get isLogged() {
		return !!this.authToken;
	}

	get isUserPendingForCompanyRegist(): boolean {
		return this.userInfo.status === UserStatus.PendingForCompanyRegist;
	}

	get isUserPendingForPaymentPlan(): boolean {
		return this.userInfo.status === UserStatus.PendingForPaymentPlan;
	}

	get isUserActive(): boolean {
		return this.userInfo.status === UserStatus.Active;
	}

	get isHydrated() {
		return isHydrated(this);
	}

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'AuthStore',
			properties: ['language', 'permissions', 'userInfo', 'currentUserInfo'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});

		this.authService = new AuthService();
		this.userInfo = {} as AppUser;

		RootStore.subscribeToLoading([this.authService], this.rootStore);
		rootStore.companyStore.onCompanyChange.subscribe(() => {
			if (!rootStore.companyStore.companyId) return;
			this.getCurrentUser();
		});

		// We have token but don't have user info. So try to fetch it
		// if (this.userInfo.jwttoken && this.isUserPendingForCompanyRegist) {
		// 	this.getCurrentUserInfo();
		// }
	}

	updateToken = (token: string) => {
		setInstancesToken(token);
		this.userInfo.jwttoken = token;
	};

	updateUser = (user: Partial<AppUser>) => {
		this.userInfo = { ...this.userInfo, ...user };
		if (user.email) {
			setSessionStorage(USER_EMAIL, user.email);
		}
	};

	async getCurrentUser() {
		const res = await this.authService.getCurrentUserInfo();
		if (res.data?.[0]) {
			this.currentUserInfo = res.data[0];
		}

		return res.data?.[0];
	}

	async handleUserData(status: UserStatus, token?: string) {
		if ((this.isUserPendingForPaymentPlan || status === UserStatus.Active) && this.rootStore.companyStore.companyId) {
			this.setCompanyPermissions(this.rootStore.companyStore.companyId);
		}

		this.userInfo.status = status;

		if (token) {
			this.updateToken(token);
		}
	}

	async resetPassword(reCaptchaToken: string, email: string, application: number) {
		const res = await this.authService.resetPassword(reCaptchaToken, email, application);
		if (res.isSuccess) {
			this.forgotPassSent = true;
		}
		return res;
	}

	async validateResetPasswordOTP(newPass: string, guid: string, application: number) {
		const res = await this.authService.validateResetPasswordOTP(newPass, guid, application);
		if (res.isSuccess) {
			await this.handleUserData(res.data.status, res.data.jwttoken);
		}

		return res;
	}

	async validateUniqueKey(guid: string) {
		const res = await this.authService.validateUniqueKey(guid);
		return res;
	}

	setRecaptchaKey(key: string = '') {
		this.recaptchaKey = key;
	}

	setCompanyPermissions = async (companyId: number) => {
		const res = await this.authService.getUserPermissions(companyId);
		this.permissions = res.data;
	};

	resetStoreToDefaultValues = () => {
		this.permissions = undefined;
		this.companyProfileData = undefined;
		this.isLoading = false;
		this.language = 'Eng';
		this.userInfo = {} as AppUser;
		this.currentUserInfo = {} as CurrentUserInfo;
		clearPersistedStore(this);
	};

	pivotalLogin = async (data: ILoginPivotal) => {
		const res = await this.authService.pivotalLogin(data);
		if (res.isSuccess) {
			this.loginPivotalData = data;
			this.loginPivotalGuid = res.data.guid;
			return true;
		}

		return null;
	};

	pivotalValidateLoginOtp = async (otp: string) => {
		const res = await this.authService.pivotalValidateLoginOtp({
			otpGuid: this.loginPivotalGuid,
			otpNumber: otp,
		});
		if (res.isSuccess) {
			this.pivotalToken = res.data.tempJwt;
			this.pivotalCompanies = res.data.companies;
			//Store the jwt token in session storage
			// setSessionStorage(AUTH_TOKEN_KEY, this.userInfo.jwttoken);
			return res.data.companies;
		}

		return res.error || 'Something went wrong, please try again';
	};

	login = async (userLoginData: IUserLogin) => {
		const res = await this.authService.login(userLoginData);

		if (res.isSuccess) {
			setSessionStorage(USER_EMAIL, userLoginData.email);
		}
		return res;
	};

	loginGoogle = async (userLoginData: IUserLogin) => {
		const res = await this.authService.loginGoogle(userLoginData);
		if (res.isSuccess) {
			if (res.data.status !== UserStatus.PendingForAccountSetupForGoogle) {
				this.updateToken(res.token);

				await this.rootStore.companyStore.getCompanies();
				if (!this.rootStore.companyStore.companies.length) {
					return {
						...res,
						isSuccess: false,
					};
				}
			} else this.externalAuthId = userLoginData.externalAuthId;
			this.updateUser(res.data);
			this.handleUserData(res.data.status);
		}
		return res;
	};

	logOut = async () => {
		this.rootStore.appState.isLoading2 = true;
		await this.authService.logout();
		deleteSessionStorage(USER_EMAIL);
		this.rootStore.resetStores();
		clearInstancesToken();
		googleLogout();
		this.rootStore.appState.lastApiCall = undefined;
		this.rootStore.appState.isLoading2 = false;
	};

	resendOTP = async (guid: string, reCaptchaToken: string) => {
		const res = await this.authService.resendOTP(guid, reCaptchaToken);
		if (res.isSuccess) {
			return res;
		}
	};

	validateOtp = async (otp: string, guid: string) => {
		const res = await this.authService.validateLoginOtp(otp, guid);

		if (res?.data) {
			this.updateToken(res.data.jwttoken);

			if (res.data.isEmployee) {
				this.navToEmployeesWebsite();

				return;
			}

			await this.rootStore.companyStore.getCompanies();
			if (!this.rootStore.companyStore.companies.length) {
				return {
					status: 500,
				};
			}

			await this.handleUserData(res.data.status, res.data.jwttoken);
			this.updateUser(res.data);
		}

		return res;
	};

	registerCompany = async (data: CompanyRegistration) => {
		const companyRegistration = mapCompanyRegistration(data);
		const addressRegistration = mapAddressRegistration(data);
		const companyContactRegistration = mapContactInfo(data.contact);

		companyRegistration.companyContact = companyContactRegistration;

		const res = await this.rootStore.companyStore.createCompany(companyRegistration);

		if (!res?.data) {
			return {
				isSuccess: false,
				error: 'Error with creating company',
			};
		}

		this.handleUserData(this.isUserActive ? UserStatus.Active : UserStatus.PendingForCompanyRegist, res.token);

		const addressRes = await this.rootStore.companyStore.addAddress(res.data.companyId, addressRegistration);

		if (addressRes.isSuccess) {
			!this.isUserActive && this.handleUserData(UserStatus.PendingForPaymentPlan);
			this.rootStore.companyStore.setCompany(res.data);
		}

		return {
			isSuccess: addressRes.isSuccess,
			error: undefined,
		};
	};

	// getCurrentUserInfo = async () => {
	// 	this.isLoading = true;

	// 	const res = await this.authService.getCurrentUserInfo();
	// 	if (res.isSuccess) {
	// 		await this.handleUserData(res.data.status, res.data.jwttoken);
	// 	} else {
	// 		this.logOut();
	// 	}
	// 	this.isLoading = false;
	// };

	getCurrentUserInfoByUniqueKey = async (uniqueKey: string) => {
		this.isLoading = true;
		const res = await this.authService.getUserDataByUniqkey(uniqueKey);

		if (res.isSuccess && res.data) {
			this.updateUser(res.data);
			this.isLoading = false;
			return true;
		}
		this.isLoading = false;
		return false;
	};

	validateEmpFirstReg = async (uniqueKey: string) => {
		const res = await this.authService.validateEmpFirstReg(uniqueKey);
		if (res.isSuccess && res.data) {
			this.updateToken(res.token);
			return true;
		}
		return false;
	};

	// getCompanyProfileData = async () => {
	// 	const companyRes = await this.authService.getCompanyProfileData(this.rootStore.companyStore.companyId);
	// 	this.companyProfileData = companyRes.data;
	// };

	getIsBuildFilesUpdated = async () => {
		const res = await this.authService.getBuildVersion();

		if (res.isSuccess && res.data.version) {
			const serverVersion = res.data.version;
			const localVersion = localStorage.getItem('buildVersion');

			if (serverVersion !== localVersion) {
				localStorage.setItem('buildVersion', serverVersion);
				return true;
			}

			return false;
		}

		localStorage.setItem('buildVersion', '');
		return false;
	};

	registerUser = async (userData: IUserRegistration) => {
		const data = { ...userData };
		data.password = encryptAes(data.password!);
		data.confirmPassword = encryptAes(data.confirmPassword!);

		const res = await this.authService.userRegistration(data);
		if (res.isSuccess && res.data.status < UserStatus.PendingForCompanyRegist) {
			setSessionStorage(USER_EMAIL, userData.email);
		}
		return res;
	};

	userCreateAccountFirstPhase = async (userData: ICreateAccount) => {
		this.externalAuthId = userData.externalAuthId;
		const res = await this.authService.userCreateAccountFirstPhase(userData);

		await this.handleUserData(UserStatus.PendingForAccountSetup, res.token);

		if (isNumber(res.data?.status) && res.data?.status === UserStatus.PendingForAccountSetup) {
			this.userInfo = res.data as AppUser;
		}

		return res;
	};

	reSendVerificationMail = async (reCaptchaToken: string) => {
		const res = await this.authService.reSendVerificationMail(reCaptchaToken);
		return res;
	};
	completeInvitation = async (userData: IUserRegistration) => {
		const res = await this.authService.completeInvitation(userData);
		if (res.isSuccess && res.data) {
			this.updateToken(res.token);
			return res;
		} else {
			return res;
		}
	};

	userCreateAccountSecondPhase = async (userData: IUserRegistration, urlKey?: string) => {
		const data = { ...userData };
		data.companyName = undefined;

		if (urlKey && urlKey === 'gsign') {
			data.password = null;
			data.confirmPassword = null;
			data.firstName = userData.firstName;
			data.lastName = userData.lastName;
			data.externalAuthId = this.externalAuthId;
			data.uniqueKey = null;
		} else {
			data.password = encryptAes(data.password!);
			data.confirmPassword = encryptAes(data.confirmPassword!);
		}

		const res = await this.authService.userCreateAccountSecondPhase(data);

		if (!res?.data) {
			return {
				isSuccess: false,
				error: 'Error user setup',
			};
		}

		this.updateToken(res.token);

		return {
			isSuccess: true,
			data: res?.data,
		};
	};

	addUserRole = async (role?: number) => {
		const res = await this.authService.addUserRole(role);
		return res;
	};

	updateUserStatus = async (status: UserStatus) => {
		const res = await this.authService.updateUserStatus(status);

		return res;
	};

	navToEmployeesWebsite = () => {
		const cookies = new Cookies();
		cookies.set('jwtToken', this.authToken, { path: '/' });
		window.location.href = process.env.REACT_APP_EMPLOYEES_WEBSITE_URL as string;
	};
}
