import { NumberOption } from '../../../Models/API/All/NumberOption';
import { IndustryEnum } from '../../../Models/App/Valuation/industry-enum';
import { SectorEnum } from '../../../Models/App/Valuation/sector-enum';

export const sectorOptions: NumberOption[] = [
	{
		label: 'Software and Computer Services',
		value: SectorEnum['SFTCS'],
	},
	{
		label: 'Technology Hardware and Equipment',
		value: SectorEnum['TECHD'],
	},
	{
		label: 'Telecommunications Equipment',
		value: SectorEnum['TELEQ'],
	},
	{
		label: 'Telecommunications Service Providers ',
		value: SectorEnum['TELSP'],
	},
	{
		label: 'Health Care Providers',
		value: SectorEnum['HLTPR'],
	},
	{
		label: 'Medical Equipment and Services',
		value: SectorEnum['MEDES'],
	},
	{
		label: 'Pharmaceuticals and Biotechnology',
		value: SectorEnum['PHARM'],
	},
	{
		label: 'Banks',
		value: SectorEnum['BANKS'],
	},
	{
		label: 'Finance and Credit Services',
		value: SectorEnum['FINCS'],
	},
	{
		label: 'Investment Banking and Brokerage Services ',
		value: SectorEnum['INVBB'],
	},
	{
		label: 'Mortgage Real Estate Investment Trusts',
		value: SectorEnum['RITMG'],
	},
	{
		label: 'Closed End Investments',
		value: SectorEnum['CEINV'],
	},
	{
		label: 'Open End and Miscellaneous Investment Vehicles',
		value: SectorEnum['OMINV'],
	},
	{
		label: 'Life Insurance',
		value: SectorEnum['LFINS'],
	},
	{
		label: 'Non-life Insurance',
		value: SectorEnum['NLINS'],
	},
	{
		label: 'Real Estate Investment and Services Development',
		value: SectorEnum['RLISV'],
	},
	{
		label: 'Real Estate Investment Trusts',
		value: SectorEnum['REITS'],
	},
	{
		label: 'Automobiles and Parts',
		value: SectorEnum['AUTMB'],
	},
	{
		label: 'Consumer Services ',
		value: SectorEnum['CNSVS'],
	},
	{
		label: 'Household Goods and Home Construction',
		value: SectorEnum['HHOLD'],
	},
	{
		label: 'Leisure Goods',
		value: SectorEnum['LEISG'],
	},
	{
		label: 'Personal Goods',
		value: SectorEnum['PERSG'],
	},
	{
		label: 'Media',
		value: SectorEnum['MEDIA'],
	},
	{
		label: 'Retailers ',
		value: SectorEnum['RTAIL'],
	},
	{
		label: 'Travel and Leisure',
		value: SectorEnum['TRLES'],
	},
	{
		label: 'Beverages',
		value: SectorEnum['BEVES'],
	},
	{
		label: 'Food Producers',
		value: SectorEnum['FOODS'],
	},
	{
		label: 'Tobacco',
		value: SectorEnum['TOBAC'],
	},
	{
		label: 'Personal Care, Drug and Grocery Stores ',
		value: SectorEnum['PCDGS'],
	},
	{
		label: 'Construction and Materials ',
		value: SectorEnum['CNSTM'],
	},
	{
		label: 'Aerospace and Defense',
		value: SectorEnum['AERSP'],
	},
	{
		label: 'Electronic and Electrical Equipment',
		value: SectorEnum['ELTNC'],
	},
	{
		label: 'General Industrials',
		value: SectorEnum['GNIND'],
	},
	{
		label: 'Industrial Engineering',
		value: SectorEnum['INDEN'],
	},
	{
		label: 'Industrial Support Services',
		value: SectorEnum['SUPSV'],
	},
	{
		label: 'Industrial Transportation',
		value: SectorEnum['INDTR'],
	},
	{
		label: 'Industrial Materials',
		value: SectorEnum['INMAT'],
	},
	{
		label: 'Industrial Metals and Mining',
		value: SectorEnum['INDMT'],
	},
	{
		label: 'Precious Metals and Mining',
		value: SectorEnum['PRCMM'],
	},
	{
		label: 'Chemicals',
		value: SectorEnum['CHMCL'],
	},
	{
		label: 'Oil, Gas and Coal',
		value: SectorEnum['OILGC'],
	},
	{
		label: 'Alternative Energy',
		value: SectorEnum['ALTEN'],
	},
	{
		label: 'Electricity',
		value: SectorEnum['ELECT'],
	},
	{
		label: 'Gas, Water and Multi-utilities ',
		value: SectorEnum['GWMUT'],
	},
	{
		label: 'Waste and Disposal Services',
		value: SectorEnum['WASTE'],
	},
];

export const industryOptions: NumberOption[] = [
	{
		label: 'Technology',
		value: IndustryEnum['TECNO'],
	},
	{
		label: 'Telecommunications',
		value: IndustryEnum['TELCM'],
	},
	{
		label: 'Health Care ',
		value: IndustryEnum['HLTHC'],
	},
	{
		label: 'Financials',
		value: IndustryEnum['FINAN'],
	},
	{
		label: 'Real Estate',
		value: IndustryEnum['RLEST'],
	},
	{
		label: 'Consumer Discretionary',
		value: IndustryEnum['CODIS'],
	},
	{
		label: 'Consumer Staples ',
		value: IndustryEnum['COSTP'],
	},
	{
		label: 'Industrials',
		value: IndustryEnum['INDUS'],
	},
	{
		label: 'Basic Materials',
		value: IndustryEnum['BMATR'],
	},
	{
		label: 'Energy',
		value: IndustryEnum['ENEGY'],
	},
	{
		label: 'Utilities',
		value: IndustryEnum['UTILS'],
	},
];
