import { css } from '@emotion/css';
import { IC_PAYMENT_CARD } from '../../../../Assets';
import { PaymentMethod } from '../../../../Models/App/PaymentModels';
import Card from '../Card';
import CardInfoStyle from './index.style';
import RadioButton from '../../../../Shared/Components/RadioButton/RadioButton';
import appConfig from '../../../../config/config';
import classNames from 'classnames';
import { breakpoints } from '../../../../config/style/theme';

type Props = {
	paymentMethod: PaymentMethod;
	selected?: boolean;
	className?: string;
};

const Style = css({
	label: 'SelectableCard',
	padding: '1rem 2rem',
	'&.desktop': {
		width: 'clamp(35rem, 33.33%, 60rem)',
	},
	'&.selected': {
		border: `1px solid ${appConfig.style.colors.color1}`,
	},
	'&__title': {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '4rem',
		span: {
			fontWeight: 700,
		},
		[`@media screen and (max-width: ${breakpoints.s})`]: {
			marginBottom: '2rem',
		},
	},
	'&__info': {
		display: 'flex',
		gap: '2rem',
		alignItems: 'center',
		img: {
			width: '6rem',
			height: '6rem',
		},
		'.credit-card': {
			fontWeight: 700,
		},
	},
});

const SelectableCard = ({ paymentMethod, selected, className = '' }: Props) => {
	return (
		<Card className={classNames(Style, { selected: !!selected, [className]: !!className })}>
			<div className={`${Style}__title`}>
				<span>Card info</span>
				<RadioButton qaid="" checked onClick={() => {}} name="paymentMethod" value={true} />
			</div>
			<div className={`${Style}__info`}>
				<img src={IC_PAYMENT_CARD} alt="" />
				<div className={`${Style}__basic-details`}>
					<div>{paymentMethod.cardHolderName}</div>
					<div className="credit-card">xxxx xxxx xxxx {paymentMethod.cardNumber}</div>
				</div>
			</div>
		</Card>
	);
};

export default SelectableCard;
